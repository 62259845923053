import React, { Component } from 'react';
import './Question.page.scss';
export interface IProps {}

export default class QuestionPage extends Component<IProps> {
    render() {
        return (
            <div>
                <b>Card not supported - Question Page</b>
            </div>
        );
    }
}
