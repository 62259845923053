import React, { Component } from 'react';

export default class NotFound extends Component {
    render() {
        return (
            <div>
                <h1>404</h1>
                <span>page not found</span>
            </div>
        );
    }
}
