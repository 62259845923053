import React, { Component } from 'react';
import './Discount.scss';
import { Api, EventBus } from 'src/helpers/new';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

interface IState {
    discountCode: string;
    discountHeading: string;
    discountText: string;
}
interface IProps {
    checkThoughtMetricSurvey: (value: boolean) => void;
}
export default class Discount extends Component<IProps> {
    state: IState = {
        discountCode: '',
        discountHeading: '',
        discountText: '',
    };

    async componentDidMount() {
        const { success, response } = await Api.call('GET', '/settings/checkout');

        if (success) {
            this.setState({
                ...response,
            });
            this.props.checkThoughtMetricSurvey(response.thoughtMetricSurvey);
        }
    }

    render() {
        const { discountCode, discountHeading, discountText } = this.state;
        return (
            <>
                {discountCode && (
                    <div className='discount'>
                        <div>
                            <h2>{discountHeading}</h2>
                            <span dangerouslySetInnerHTML={{ __html: discountText }} />
                        </div>

                        <div>
                            <h3>DISCOUNT VOUCHER</h3>
                            <div>
                                <p>{discountCode}</p>
                                <Button
                                    variant='info'
                                    className='copy-button'
                                    onClick={() => {
                                        navigator.clipboard.writeText(discountCode);

                                        EventBus.dispatch('toast', {
                                            type: 'info',
                                            message: 'Code copied to clipboard',
                                            duration: 700,
                                        });
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCopy} />
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
