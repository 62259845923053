import React, { Component } from 'react';
import Alert from '../BaseAlert';
import './Alert.scss';

interface IProps {
    alert: IAlert;
    dismiss: (key: string) => void;
}

export interface IAlert {
    id: string;
    type: 'error' | 'success' | 'warning' | 'info';
    title: string;
    message: string;
    duration?: number;
    displayType?: 'normal' | 'announcement';
}

interface IState {
    expanded: boolean;
}

export default class CentralAlert extends Component<IProps, IState> {
    state: IState = {
        expanded: false,
    };

    get alertMessage(): string {
        const { message } = this.props.alert;
        const { expanded } = this.state;

        if (!message) return 'No message provided';

        if (message.length > 200 && !expanded) {
            return `${message.substring(0, 200)} ...`;
        } else {
            return message;
        }
    }

    dismiss = (): void => {
        setTimeout(() => {
            this.props.dismiss(this.props.alert.id);
        }, 500);
    };

    toggleExpanded = (): void => {
        this.setState({ expanded: !this.state.expanded });
    };

    copyAlertToClipboard = (): void => {
        navigator.clipboard.writeText(`${this.props.alert?.type} - ${this.props.alert?.message}`);
    };

    render() {
        const { title, message, type, displayType } = this.props.alert;
        const { expanded } = this.state;

        if (displayType === 'announcement') {
            return (
                <Alert
                    className='announcement-alert'
                    duration={this.props.alert.duration}
                    onClick={this.copyAlertToClipboard}
                    dismiss={this.dismiss}
                >
                    <div className='announcement-alert-outer-wrapper'>
                        <div className='announcement-alert-inner-wrapper'>
                            <div className='announcement-alert-title-outer-wrapper'>
                                <div className='announcement-alert-title-inner-wrapper'>
                                    <div className='icon'>
                                        <i className='fa fa-triangle-exclamation' />
                                    </div>
                                    <div className='announcement-alert-title'>{title}</div>
                                </div>
                                <i className='fas fa-times announcement-alert-close' onClick={this.dismiss} />
                            </div>
                            <div className='announcement-alert-message'>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: message ?? '',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </Alert>
            );
        }

        return (
            <Alert
                className='popup-alert'
                duration={this.props.alert?.duration}
                onClick={this.copyAlertToClipboard}
                dismiss={this.dismiss}
            >
                <div className={`colour-bar ${type}`}></div>
                <div className='alert-content'>
                    <div className='title'>
                        <span>{title}</span>
                        <i className='fas fa-times' onClick={this.dismiss} />
                    </div>
                    <div className='message'>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: this.alertMessage,
                            }}
                        ></span>
                    </div>
                    {message?.length > 150 && (
                        <div className='resize' onClick={this.toggleExpanded}>
                            <i className={`fas fa-chevron-${expanded ? 'up' : 'down'}`} />
                        </div>
                    )}
                </div>
            </Alert>
        );
    }
}
