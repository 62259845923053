import React, { useState, createContext } from 'react';
import { useBlocker } from 'react-router-dom';
import { CheckoutSurveyPrompt } from '../Survey/CheckoutSurveyPrompt';
import { isNil } from 'lodash';

interface UsePromptProps {
    when?: boolean;
    cartDetails: {
        cartId: string;
    };
    onSubmit: () => void;
    onClose: () => void;
    isSurveyModalShown: boolean;
    clearTimer?: () => void;
}

const BlockerContext = createContext(null);
export function SurveyBlockerForm({
    when = true,
    cartDetails,
    onSubmit,
    onClose,
    isSurveyModalShown,
    clearTimer,
}: UsePromptProps) {
    const [isPromptVisible, setIsPromptVisible] = useState(false);

    const blocker: any = useBlocker(({ currentLocation, nextLocation }) => {
        if (when && currentLocation.pathname !== nextLocation.pathname && !nextLocation.pathname.includes('checkout')) {
            if (!isSurveyModalShown) {
                setIsPromptVisible(true);
                clearTimer?.();
            }

            return true;
        }
        return false;
    });

    const onSurveySubmit = () => {
        if (isPromptVisible) {
            setIsPromptVisible(false);
        }

        if (!isNil(blocker.proceed)) {
            blocker?.proceed();
        }
        onSubmit?.();
    };

    const onSurveyClose = () => {
        if (isPromptVisible) {
            setIsPromptVisible(false);
            blocker?.reset();
        }

        onClose?.();
    };

    return isPromptVisible || isSurveyModalShown ? (
        <BlockerContext.Provider value={blocker}>
            {(isPromptVisible || isSurveyModalShown) && (
                <CheckoutSurveyPrompt
                    isShow={isPromptVisible || isSurveyModalShown}
                    onSubmit={onSurveySubmit}
                    onClose={onSurveyClose}
                    cartDetails={cartDetails}
                />
            )}
        </BlockerContext.Provider>
    ) : null;
}
