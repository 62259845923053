import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditialWrapper';
import CourseContext from 'src/pages/Course/CourseContext';
import './StartExam.page.scss';

interface IProps {
    clickHandler: () => void;
    isProgressBlockedTooltip: string;
}
interface IState {}

export default class StartExamPage extends Component<IProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;

    get progressionBlocked(): boolean {
        const { course, isAdmin } = this.context;

        if (this.props.isProgressBlockedTooltip) {
            return true;
        } else if (isAdmin) {
            return false;
        } else if (course.passedAssignments < course.totalAssignments) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { course } = this.context;

        return (
            <ConditionalWrapper
                condition={this.context.isAdmin ? false : this.progressionBlocked}
                wrapper={(children: any) => (
                    <OverlayTrigger
                        overlay={
                            <Tooltip id={`tooltip-next-lesson`} className='abs-tooltip'>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            course.passedAssignments < course.totalAssignments &&
                                            !this.props.isProgressBlockedTooltip
                                                ? 'To access your final exam, you still have to answer correctly a few graded assignments. Please see at the end of each module which assignments you did not pass yet.'
                                                : this.props.isProgressBlockedTooltip,
                                    }}
                                />
                            </Tooltip>
                        }
                    >
                        {children}
                    </OverlayTrigger>
                )}
            >
                <button
                    onClick={() => {
                        if (this.progressionBlocked) return;
                        this.props.clickHandler();
                    }}
                    className={`exam-button ${this.progressionBlocked ? ' exam-button-disabled' : ''}`}
                >
                    {`Go to final exam`}
                </button>
            </ConditionalWrapper>
        );
    }
}
