import React, { Component } from 'react';
import { AlertHandler, ConfirmationHandler, ActionConfirmationHandler } from '.';

export default class GlobalHandlers extends Component<unknown, unknown> {
    render() {
        return (
            <>
                <AlertHandler />
                <ConfirmationHandler />
                <ActionConfirmationHandler />
            </>
        );
    }
}
