import { IAnnouncementAlert } from 'src/GlobalHandlers/AlertHandler/Alerts/AnnouncemntAlerts/Announcement';

export interface IAnnouncements {
    isLoaded: boolean;
    announcements: { [key: string]: IAnnouncementAlert | null };
}

export const announcements = (
    state: IAnnouncements = {
        isLoaded: false,
        announcements: {},
    },
    action: {
        type: string;
        payload: IAnnouncements;
    },
) => {
    switch (action.type) {
        case 'SET_ANNOUNCEMENTS':
            return { ...action.payload };
        default:
            return state;
    }
};
