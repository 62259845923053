import React, { Component } from 'react';
import LessonTimer from 'src/pages/Course/Stages/Lessons/LessonTimer/LessonTimer';
import CourseContext from 'src/pages/Course/CourseContext';
import './Header.scss';

interface IProps {
    lessonName: string;
    lessonType: string;
    courseTitle: string;
    lessonId: string;
    chapterName: string;
    requiredTime: number;
    isAdminPreview?: boolean;
}

export default class Header extends Component<IProps> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    timerRef = React.createRef();

    render() {
        const { courseTitle, lessonName, lessonType, lessonId, chapterName } = this.props;

        return (
            <div className='lesson-slide-header'>
                <header className={lessonType?.toLowerCase() ?? 'standard'}>
                    <div className='course-details'>
                        <h3 className='course-title overflow-elipsis'>{courseTitle}</h3>
                    </div>
                    <h5 className='lesson-name'>
                        {lessonType === 'STATE' && (
                            <span className='lesson-type state'>
                                <i className='fa fa-university' aria-hidden='true' />
                                <span>State</span>
                            </span>
                        )}
                        {lessonType === 'NATIONAL' && (
                            <span className='lesson-type national'>
                                <i className='fa fa-flag-checkered' aria-hidden='true' />
                                <span>National</span>
                            </span>
                        )}
                        <div className='slide-header-chapter overflow-elipsis'>{chapterName}</div>
                        <i className='fas fa-chevron-right' />
                        <div className='slide-header-lesson overflow-elipsis'> {lessonName}</div>
                    </h5>
                    {!this.context.isAdmin && (
                        <span className={`lesson-timer ${this.props.isAdminPreview ? 'admin-preview' : ''}`}>
                            <LessonTimer lessonId={lessonId} requiredTime={this.props.requiredTime} />
                        </span>
                    )}
                </header>
            </div>
        );
    }
}
