export const sendMessageKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    sendMessageCallback: (message: string) => void,
    sendMessageToInstructorCallback: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void,
) => {
    if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();

        const textarea = event.target as HTMLTextAreaElement;
        const currentCursorPosition = textarea.selectionStart;
        const currentValue = textarea.value;

        const updatedMessage =
            currentValue.substring(0, currentCursorPosition) + '\n' + currentValue.substring(currentCursorPosition);

        sendMessageCallback(updatedMessage);
    } else if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        sendMessageToInstructorCallback(event);
    }
};
