import React, { Component, ReactNode } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import FormBuilder from 'src/components/FormBuilder/FormBuilder';
import { Spinner } from 'src/components/Spinner';
import CourseContext from '../../CourseContext';
import CourseBreadcrumbs from 'src/pages/Course/Components/CourseBreadcrumbs';
import './Enrollment.scss';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';

interface IRouteProps {
    courseId: string;
    courseStage?: 'enrollment';
}

interface IProps extends RouteComponentProps<IRouteProps> {}

type IField = any;

type syncAsyncFunction = (() => void) | (() => Promise<void>);

interface IState {
    isLoading: boolean;
    fields: IField[];
    fileFields: any[];
    isSubmitButton: boolean;
}

class Enrollment extends Component<IProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;

    state: IState = {
        isLoading: true,
        fields: [],
        fileFields: [],
        isSubmitButton: true,
    };

    async componentDidMount() {
        const { courseId } = this.props.params;
        const { success, response } = await Api.call('get', `/users/enrollment/${courseId}`);
        EventBus.dispatch('display-chat-icon', { enable: false, course: this.context.course });

        if (success) {
            if (response?.fields?.length > 0) {
                const fileFields = this.state.fileFields;
                response?.fields?.map((field: any) => {
                    if (field.inputType === 'file') {
                        fileFields.push(field.key);
                    }
                });
                this.setState({
                    isLoading: false,
                    fields: response.fields,
                    fileFields,
                    isSubmitButton: response?.isSubmitButton,
                });
            } else {
                this.submit({});
            }
        }
    }

    componentWillUnmount(): void {
        EventBus.dispatch('display-chat-icon', { enable: true, course: this.context.course });
    }

    handleProctoring(callback: syncAsyncFunction = () => {}) {
        if (this.enrollmentNeedsProctoring) {
            EventBus.dispatch('require-auth', { stage: 'enrollment', callback });
        } else {
            callback();
        }
    }

    handleSubmit = async (fields: IField): Promise<void> => {
        this.handleProctoring(() => this.submit(fields));
    };

    submit = async (fields: IField) => {
        this.setState({ isLoading: true });
        const { courseId } = this.props.params;

        if (this.state.fileFields.length > 0) {
            const uploadPromises = this.state.fileFields?.map(async (field) => {
                if (fields[field] instanceof File) {
                    const fileData = new FormData();
                    fileData.append('file', fields[field]);
                    const { success, response } = await Api.call('POST', '/files', fileData);
                    if (success) {
                        fields[field] = response.fileId;
                    }
                }
            });
            await Promise.all(uploadPromises);
        }

        const { success, response } = await Api.call('post', `/users/courses/${courseId}/enrollment`, fields);
        if (success) {
            this.setState({ isLoading: false });
            if (this.context.changeExpiresAt) {
                this.context.changeExpiresAt(response.expiresAt);
            }
            const { course } = this.context;
            this.props.navigate(
                `/courses/${course._id}/chapters/${course.lastChapterId}/lessons/${course.lastLessonId}`,
                {
                    replace: true,
                    state: {
                        isToBypassBiosigOnComponentMount: true,
                    },
                },
            );
        }
    };

    get enrollmentNeedsProctoring(): boolean {
        const { proctoring = {}, proctoringSettings = {} } = this.context.course;

        return (
            proctoringSettings &&
            proctoringSettings.enrollment === 'biosig' &&
            !(proctoring.enrollment && proctoring.enrollment.success === true)
        );
    }

    public render(): ReactNode {
        const { isLoading, fields, isSubmitButton } = this.state;
        const { title } = this.context.course;

        if (isLoading) return <Spinner />;

        return (
            <FormBuilder
                fields={fields}
                onSubmit={this.handleSubmit}
                isSubmitButton={isSubmitButton}
                header={() => <CourseBreadcrumbs centered firstItem={title} secondItem='Enrollment' />}
            />
        );
    }
}
export default withRouter(Enrollment);
