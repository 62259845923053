export default class IdempotencyController {
    keys: Record<string, ReturnType<typeof setTimeout>> = {};
    defaultDuration: number;

    constructor(defaultDuration: number) {
        this.defaultDuration = defaultDuration;
    }

    public verify(key: string, duration?: number): boolean {
        if (this.keys[key] === undefined) {
            this.createToken(key, duration);
            return true;
        } else return false;
    }

    private createToken(key: string, duration?: number): void {
        this.keys[key] = setTimeout(() => this.removeToken(key), duration ?? this.defaultDuration);
    }

    private removeToken(key: string) {
        delete this.keys[key];
    }
}
