import React, { Component } from 'react';
import CalendarLegend, { TCalendarLegend } from './Legend';
import CalendarGroup from './Group';
import './NavigationCalendar.scss';

export type TCalendar = TCalendarData & {
    className?: string;
    legend: TCalendarLegend;
};
type TCalendarData = { basic: TBasicCalendar; advanced?: never } | { basic?: never; advanced: TAdvancedCalendar };

export type TBasicCalendar = Record<string, any>[];
export type TAdvancedCalendar = Record<string, TBasicCalendar>;

interface IState {
    legend: TCalendarLegend;
}

export default class NavigationCalendar extends Component<TCalendar, IState> {
    state: IState = {
        legend: this.props.legend,
    };

    toggleLegendItem = (index: number) => {
        const legend = [...this.state.legend];
        legend[index].active = !legend[index].active;

        this.setState({ legend });
    };

    get calendarGroups(): JSX.Element[] | null {
        const { basic, advanced, legend } = this.props;
        if (basic) return null;

        return Object.entries(advanced).reduce((acc: JSX.Element[], [title, items], index) => {
            return [...acc, <CalendarGroup key={index} title={title} items={items} rules={legend} />];
        }, []);
    }

    get legend(): TCalendarLegend {
        // TODO: make this work for advanced calendars also

        const basic = (this.props.basic ?? []).map((item) => {
            return { ...item, matched: false };
        });
        const legend = this.state.legend.map((rule) => {
            return { ...rule, enabled: false };
        });

        for (const item of basic) {
            legend.forEach((rule, index) => {
                if (!item.matched && rule.matches(item) && !rule.alwaysVisible) {
                    legend[index].enabled = true;
                    item.matched = true;
                    return;
                }
            });
        }

        return legend;
    }

    render() {
        const { className, basic, advanced } = this.props;
        const legend = this.legend;

        return (
            <div className={`navigation-calendar ${className ?? ''}`}>
                <CalendarLegend legend={legend} onLegendToggle={this.toggleLegendItem} />
                {basic && <CalendarGroup items={basic} rules={legend} />}
                {advanced && this.calendarGroups}
            </div>
        );
    }
}
