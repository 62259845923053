import React, { Component } from 'react';
import './Header.scss';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { isEmpty } from 'lodash';
import { Button } from 'react-bootstrap';
import { EventBus } from 'src/helpers/new';

type ILocationState = {
    previousPath: string;
};

type IRouteProps = RouteComponentProps<{ [K in keyof any]?: string }>;
interface IProps extends IRouteProps {
    autoLoginInfo: {
        isShowAccessCourse: boolean;
        loginToken: any;
        pollingFailedMessage: string;
    };
    isRedirectToExamSummary: boolean;
    userCourseId: string;
}

interface IState {
    previousPath: string;
    loginToken: string;
    userExists: boolean;
}
class Header extends Component<IProps, IState> {
    state: IState = {
        previousPath: '',
        loginToken: '',
        userExists: false,
    };

    get isLoginToken() {
        const { autoLoginInfo } = this.props;
        if (autoLoginInfo.isShowAccessCourse) {
            if (autoLoginInfo.loginToken?.loginToken) {
                return autoLoginInfo.loginToken?.loginToken;
            } else {
                return 'ext';
            }
        } else {
            return '';
        }
    }

    get isAdminPreview() {
        const query = new URLSearchParams(this.props.location.pathname);
        return Boolean(query.get('isAdminPreview'));
    }

    getEmailAndOrderId = () => {
        if (this.isAdminPreview) {
            let localStorageUserData: { email: string; _id: string } = { email: '', _id: '' };
            const storeData = localStorage.getItem('user');
            if (storeData && typeof storeData === 'string') {
                localStorageUserData = JSON.parse(storeData);
            }
            return {
                email: localStorageUserData?.email,
                orderId: localStorageUserData?._id,
            };
        } else {
            return {
                orderId: this.props.autoLoginInfo.loginToken?.orderId,
                email: this.props.autoLoginInfo.loginToken?.email,
            };
        }
    };

    onRedirect = () => {
        const { isRedirectToExamSummary, userCourseId } = this.props;

        if (isRedirectToExamSummary && userCourseId) {
            this.props.navigate(`/courses/${userCourseId}/exam-summary`, {
                replace: true,
                state: { isProctorPass: true },
            });
        } else {
            this.props.navigate(
                {
                    pathname: '/',
                    search: `?loginToken=${this.isLoginToken}&email=${this.props.autoLoginInfo.loginToken?.email}`,
                },
                { replace: true },
            );

            EventBus.dispatch('process-after-checkout', {
                loginToken: this.isLoginToken,
                email: this.props.autoLoginInfo.loginToken?.email,
            });
        }
    };

    render() {
        const { email, orderId } = this.getEmailAndOrderId();
        return (
            <div
                className='confirmation__header'
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bg-yourcourseready.jpg)` }}
            >
                <img src={process.env.PUBLIC_URL + '/icon-yourcourseready.svg'} alt={'Course ready logo'} />
                <h1>Your Course is Ready - Thank you for your order!</h1>
                <h3>Your order id is {orderId}</h3>
                <h3>
                    An email has been sent to {email} <i className='fas fa-envelope' />
                </h3>
                <div className='access-course-button'>
                    <Button
                        variant='primary'
                        onClick={this.onRedirect}
                        disabled={isEmpty(this.isLoginToken)}
                        className={isEmpty(this.isLoginToken) ? 'link-disabled mt-2' : 'mt-2'}
                    >
                        Access your course now!
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);
