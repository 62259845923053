import React, { Component } from 'react';
import { Api } from 'src/helpers/new';
import SideBarToggle from '../../../NavigationSideBar/SideBarToggle';
import StudentPreviewControls from '../../../StudentPreviewController/StudentPreviewControls';
import './Header.scss';

interface IProps {
    lessonName: string;
    chapterName: string;
    chapterId: string;
    userCourseId: string;
    isAdminPreview: boolean;
    lessonType: string;
}

interface IState {
    chapterName: string;
}

interface IChapter {
    _id: string;
    title: string;
}

export default class Header extends Component<IProps> {
    state: IState = {
        chapterName: '',
    };

    async componentDidMount() {
        if (!this.props.chapterName) {
            const { success, response } = await Api.call('get', `/users/courses/${this.props.userCourseId}/chapters`);
            if (success) {
                const chapterName = response.find(({ _id }: IChapter) => _id === this.props.chapterId).title;

                this.setState({
                    chapterName,
                });
            }
        }
    }

    render() {
        const { isAdminPreview, lessonType } = this.props;

        const chapterName = this.props.chapterName ? this.props.chapterName : this.state.chapterName;

        return (
            <div className={'lesson-page-header ' + lessonType?.toLowerCase()}>
                <SideBarToggle hideOnOpen={true} />
                {lessonType === 'STATE' && (
                    <span className='lesson-type state'>
                        <i className='fa fa-university' aria-hidden='true' />
                        <span>State</span>
                    </span>
                )}
                {lessonType === 'NATIONAL' && (
                    <span className='lesson-type national'>
                        <i className='fa fa-flag-checkered' aria-hidden='true' />
                        <span>National</span>
                    </span>
                )}
                <div className='page-header-chapter header-text'>{chapterName}</div>
                <span>
                    <i className='fas fa-chevron-right' />
                </span>
                <div className='page-header-lesson header-text'>{this.props.lessonName}</div>
                {isAdminPreview && (
                    <div className='page-preview-settings-container'>
                        <StudentPreviewControls />
                    </div>
                )}
            </div>
        );
    }
}
