import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import LessonContext from '../../LessonContext';
import LessonCard from '../../Cards/LessonCard';
import { Spinner } from 'src/components/Spinner';
import { Api } from 'src/helpers/new';
import Navigation from './Footer/Navigation/Navigation';
import Feedback from './Footer/Feedback';
import withContext from 'src/helpers/withContext';
import { isNil } from 'lodash';
import './SlideContainer.scss';

interface IRouteProps {
    courseId: string;
    lessonId: string;
    cardIndex: string;
}

interface IProps {
    course: any;
    lesson: any;
    quizCardIndex?: any;
    contextValue: any;
    adminEssayCardIndex?: any;
    latestAttemptData?: any;
}

interface IState {
    activeCardIndex: number;
    isComponentReady: boolean;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

class Slide extends Component<TProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    themeRef: any = React.createRef();

    state: IState = {
        activeCardIndex: 0,
        isComponentReady: false,
    };

    componentDidMount() {
        this.setDefaultActiveCardIndex(this.props.quizCardIndex ?? 0);
    }

    async componentDidUpdate(prevProps: TProps) {
        if (this.props.params.lessonId !== prevProps.params.lessonId) {
            if (!this.context.isAdminPreview) {
                await Api.call(
                    'POST',
                    `/users/courses/${this.props.params.courseId ?? this.props.course._id}/latest/card`,
                    {
                        userLessonId: this.props.lesson._id,
                        lastCardIndex: 0,
                    },
                );
            }
            this.setDefaultActiveCardIndex(this.props.quizCardIndex ?? 0);
        }
        if (this.props.contextValue.activeCardIndex !== prevProps.contextValue.activeCardIndex) {
            this.setState({
                activeCardIndex: this.props.contextValue.activeCardIndex,
            });
        }
        if (this.state.activeCardIndex >= this.context.lesson.cards.length) {
            this.setState({
                activeCardIndex: this.context.lesson.cards.length - 1,
            });
        }
    }

    setDefaultActiveCardIndex = async (lastCardIndexOverride?: number) => {
        const { cardIndex } = this.props.params;
        let activeCardIndex = 0;

        if (
            this.props.course?.lastCardIndex &&
            this.props.lesson?.previousLesson?._id !== this.props.course.lastLessonId
        ) {
            activeCardIndex = lastCardIndexOverride ?? this.props.course.lastCardIndex;
        }

        if (!isNil(this.props.quizCardIndex)) {
            activeCardIndex = this.props.quizCardIndex;
        }

        if (!isNil(this.props.adminEssayCardIndex)) {
            activeCardIndex = this.props.adminEssayCardIndex;
        }

        if (cardIndex === 'last') {
            const index = this.props.lesson.cards.reduce((accumulator: number, currentValue: any) => {
                if (currentValue.userLessonCardData?.unlockedAt) {
                    return currentValue.orderIndex;
                }
                return accumulator;
            }, 0);
            activeCardIndex = index;
        }

        this.setState({
            activeCardIndex: activeCardIndex,
        });
    };

    setActiveCardIndex = async (index: number) => {
        if (!this.context.isAdminPreview) {
            await Api.call(
                'POST',
                `/users/courses/${this.props.params.courseId ?? this.props.course._id}/latest/card`,
                {
                    userLessonId: this.props.lesson._id,
                    lastCardIndex: index,
                },
            );
        }

        this.setState({
            activeCardIndex: index,
        });

        if (this.context.setActiveCardIndex) {
            this.context.setActiveCardIndex(index);
        }
    };

    get progress(): number {
        // progress based on the number of cards viewed in the lesson?
        return ((this.state.activeCardIndex + 1) * 100) / this.context.lesson.cards.length;
    }

    render() {
        const { course } = this.props;
        const { isLoading, lesson, isDraft } = this.context;
        const { isComponentReady, activeCardIndex } = this.state;

        if (isLoading) return <Spinner />;
        const card = lesson.cards[activeCardIndex];
        if (!card) return <h1>No cards exist for this lesson</h1>;

        return (
            <>
                <main
                    ref={this.themeRef}
                    className={`lesson-cards__theme-${card.theme || 'default'} ${
                        isComponentReady ? ' lesson-cards__ready' : ''
                    }`}
                >
                    <LessonCard
                        {...card}
                        key={card._id}
                        cardIndex={activeCardIndex}
                        lessonLayout='card'
                        activeCardIndex={activeCardIndex}
                        setActiveCardIndex={this.setActiveCardIndex}
                        courseId={this.props.course?.courseId}
                        latestAttemptData={this.props.latestAttemptData}
                        isDraft={isDraft}
                    />
                </main>

                <footer>
                    <Navigation
                        course={course}
                        activeCardIndex={activeCardIndex}
                        setActiveCardIndex={this.setActiveCardIndex}
                        cardType={card.cardType}
                    />
                    <Feedback
                        course={course}
                        activeCardIndex={activeCardIndex}
                        setActiveCardIndex={this.setActiveCardIndex}
                        cardType={card.cardType}
                    />
                    <div className='slide-container__progress'>
                        <div style={{ width: `${this.progress}%` }}></div>
                    </div>
                </footer>
            </>
        );
    }
}
export default withRouter(withContext(Slide, LessonContext));
