import { IAlert, IToast } from 'src/GlobalHandlers/AlertHandler';
import { IConfirationPopup } from 'src/GlobalHandlers/ConfirmationHandler';
import IdempotencyController from './IdempotencyController';
import * as Sentry from '@sentry/react';

interface Listeners {
    [key: string]: any;
}

interface IIdempotency {
    key: string;
    duration?: number;
}

export default class EventBus {
    static readonly listeners: Listeners = {};
    static readonly Idemptotency = new IdempotencyController(500);

    public static on(event: string, listener: (...args: any) => void | Promise<void>): void {
        this.listeners[event] = listener;
        document.addEventListener(event, listener);
    }

    public static dispatch<T>(
        event: 'alert' | 'toast' | 'confirmation-popup' | T,
        data?: T extends 'alert'
            ? Omit<IAlert, 'id'>
            : T extends 'toast'
              ? Omit<IToast, 'id'>
              : T extends 'confirmation-popup'
                ? IConfirationPopup
                : any,
        timeout?: number,
        idempotency?: IIdempotency,
    ): void {
        try {
            if (!idempotency?.key || this.Idemptotency.verify(idempotency?.key, idempotency.duration)) {
                if (timeout !== undefined && timeout !== null) {
                    setTimeout(() => {
                        try {
                            document.dispatchEvent(new CustomEvent(event as string, { detail: data }));
                        } catch (dispatchError) {
                            Sentry.captureException(dispatchError);
                        }
                    }, timeout);
                } else {
                    document.dispatchEvent(new CustomEvent(event as string, { detail: data }));
                }
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    }

    static remove(event: string, callback?: (...args: any) => void | Promise<void>) {
        if (callback) {
            document.removeEventListener(event, callback);
        } else {
            document.removeEventListener(event, this.listeners[event]);
        }

        delete this.listeners[event];
    }

    static onWindow(event: string, listener: (...args: any) => void | Promise<void>): void {
        this.listeners[event] = listener;
        window.addEventListener(event, listener);
    }

    static removeFromWindow(event: string, callback?: (...args: any) => void | Promise<void>) {
        if (callback) {
            window.removeEventListener(event, callback);
        } else {
            window.removeEventListener(event, this.listeners[event]);
        }

        delete this.listeners[event];
    }
}
