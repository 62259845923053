import { STRIPE_TYPE, STRIPE_CODE, STRIPE_DECLINE_CODE, STRIPE_MESSAGE } from 'src/helpers/GlobalConstants';

export default class CheckoutHelper {
    static getCardError(error: any) {
        const { type, code, decline_code } = error;

        switch (type) {
            case STRIPE_TYPE.VALIDATION_ERROR:
                return STRIPE_MESSAGE.VALIDATION_ERROR_MESSAGE;

            case STRIPE_TYPE.CARD_ERROR:
                switch (code) {
                    case STRIPE_CODE.INCORRECT_CVC:
                        return STRIPE_MESSAGE.INCORRECT_CVC_MESSAGE;

                    case STRIPE_CODE.BANK_ACCOUNT_DECLINED:
                        return STRIPE_MESSAGE.BANK_ACCOUNT_DECLINED_MESSAGE;

                    case STRIPE_CODE.CARD_DECLINED:
                        if (decline_code === STRIPE_DECLINE_CODE.INSUFFICIENT_FUNDS) {
                            return STRIPE_MESSAGE.INSUFFICIENT_FUNDS_MESSAGE;
                        } else {
                            return STRIPE_MESSAGE.CARD_DECLINED_MESSAGE;
                        }

                    default:
                        return error.message;
                }
            default:
                return error.message;
        }
    }
}
