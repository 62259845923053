import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation, Params, useMatch } from 'react-router-dom';

interface WithRouterProps<P = Params> {
    navigate: ReturnType<typeof useNavigate>;
    location: ReturnType<typeof useLocation>;
    params: P;
    match: any;
}

const withRouterAndRedux = (WrappedComponent: ComponentType<any>, mapStateToProps: any, mapDispatchToProps: any) => {
    const ComponentWithRouterAndRedux = (props: any) => {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        const match = useMatch('*');
        return <WrappedComponent {...props} navigate={navigate} location={location} params={params} match={match} />;
    };

    return connect(mapStateToProps, mapDispatchToProps)(ComponentWithRouterAndRedux);
};

export default withRouterAndRedux;
