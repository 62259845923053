import React, { Component } from 'react';
import Lesson, { ILesson } from '../Lesson';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import './Chapter.scss';
import LessonSkeleton from 'src/components/Skeleton/LessonSkeleton/LessonSkeleton';

export interface IChapter {
    _id: string;
    title: string;
    lessons: ILesson[];
    isDraft?: boolean;
    isAdminPreview: boolean;
    loadChaptersNavigationData: (chapterId: string) => void;
}

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

type TProps = IChapter & RouteComponentProps<IRouteProps>;

interface IState {
    showLessons: boolean;
    loadingLessons: boolean;
}

class Chapter extends Component<TProps, IState> {
    constructor(props: TProps) {
        super(props);
        this.state = {
            showLessons: props.params.chapterId === props._id,
            loadingLessons: false,
        };
    }

    componentDidUpdate(prevProps: TProps) {
        if (this.props.params.chapterId !== prevProps.params.chapterId) {
            this.setState({
                showLessons: this.props.params.chapterId === this.props._id,
            });
        }
    }

    toggleLessons = async () => {
        this.setState({ showLessons: !this.state.showLessons });

        if (!this.state.showLessons) {
            this.setState({
                loadingLessons: true,
            });
            //load lessons data
            if (this.props.loadChaptersNavigationData) {
                await this.props.loadChaptersNavigationData(this.props._id);
            }
            this.setState({ showLessons: true, loadingLessons: false });
        } else {
            this.setState({ showLessons: false });
        }
    };

    render() {
        const { title, _id, lessons } = this.props;
        const { showLessons, loadingLessons } = this.state;
        const titleArr = title.split('-');

        return (
            <div className='navigation-chapter'>
                <div onClick={this.toggleLessons} className='chapter'>
                    <div>
                        {titleArr.map((title, index) => {
                            return <p key={index}>{title}</p>;
                        })}
                    </div>
                    <i className={`fa fa-chevron-${showLessons ? 'up' : 'down'}`}></i>
                </div>
                {showLessons && (
                    <div className='lessons'>
                        {lessons && !loadingLessons ? (
                            (lessons ?? []).map((lesson: ILesson) => {
                                return (
                                    <Lesson
                                        key={lesson._id}
                                        {...lesson}
                                        chapterId={_id}
                                        isDraft={this.props.isDraft}
                                        isAdminPreview={this.props.isAdminPreview}
                                    />
                                );
                            })
                        ) : (
                            <div className='lessons-skeleton'>
                                {Array.from({ length: 10 }, (_, index) => (
                                    <LessonSkeleton key={index} />
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(Chapter);
