export const setSecretQuestions = (payload) => {
    return {
        type: 'SET_SECRET_QUESTIONS',
        payload: payload,
    };
};

export const setLoggedIn = (payload) => {
    return {
        type: 'SET_LOGGED_IN',
        payload: payload,
    };
};