import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { IUserCourse } from './CourseButton';
import ReactTooltip from 'react-tooltip';

interface IProps extends RouteComponentProps {
    courseData: IUserCourse;
    index: number;
    checkDisabled: (courseData: IUserCourse, index: number) => boolean | undefined;
    handleChange: (e: any) => void;
    modalFormData: any;
}

class RepurchaseForm extends Component<IProps> {
    render() {
        const { courseData, index, checkDisabled, handleChange, modalFormData } = this.props;

        return parseFloat(courseData.repurchase) === 0 ? (
            <div className='no-option-message'>* This course cannot be extended or repurchased.</div>
        ) : (
            <Form className='model-offer-options' onChange={handleChange}>
                <Form.Group
                    key={`repurchase`}
                    className={`item repurchase-item ${
                        modalFormData?.[courseData.courseId] &&
                        modalFormData?.[courseData.courseId]?.purchaseType === 'repurchase'
                            ? 'checked'
                            : 'unchecked'
                    }`}
                >
                    <Form.Check.Label htmlFor='repurchase'>
                        <ReactTooltip effect='solid' multiline={true} />
                        Repurchase Course&nbsp;
                        <i
                            className='fa fa-circle-info'
                            data-tip={`When repurchasing, you will have to start the course from the beginning`}
                        />
                    </Form.Check.Label>
                    <div className='item-detail'>
                        <div className='item-price'>${parseFloat(courseData.repurchase).toFixed(2)}</div>
                        <Form.Check.Input
                            name={courseData.courseId}
                            type={'checkbox'}
                            value={JSON.stringify({
                                _id: courseData.courseId,
                                userCourseId: courseData._id,
                                purchaseType: 'repurchase',
                                price: parseFloat(courseData.repurchase).toFixed(2),
                                userPackageId: courseData.userPackageId,
                            })}
                            onChange={() => {}}
                            checked={
                                modalFormData?.[courseData.courseId] &&
                                modalFormData?.[courseData.courseId]?.purchaseType === 'repurchase'
                                    ? true
                                    : false
                            }
                            disabled={checkDisabled(courseData, index)}
                        />
                    </div>
                </Form.Group>
            </Form>
        );
    }
}

export default withRouter(RepurchaseForm);
