import React, { Component } from 'react';
import { Button, Col, Popover, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'src/hoc/withRouter';
import './ChatPurchasePopOver.scss';
import { ICourseChat } from 'src/pages/Course/CourseManager';
import { IUserLoggedIn } from '../Menus/ProfilePopOver';
import { setState } from 'src/helpers/localStorage';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IProps extends RouteComponentProps {
    loggedIn: IUserLoggedIn;
    setChatMenu: (value: boolean, event?: any) => void;
    setCourseChat: (payload: any) => void;
    course: any;
    courseChat: ICourseChat;
    isShowChatMenu: boolean;
}
interface IState {}

class ChatPurchasePopOver extends Component<IProps, IState> {
    state: IState = {};

    componentWillUnmount(): void {
        if (this.props.isShowChatMenu) {
            this.props.setChatMenu(false);
        }
    }

    purchaseChat = () => {
        const { course } = this.props;
        const purchaseData: any = {};
        if (course) {
            purchaseData[course.userPackageId] = {
                price: course?.chatSettings?.price,
                purchaseType: 'chat',
                userPackageId: course.userPackageId,
                _id: course.courseId,
                userCourseId: course._id,
            };
            const data = {
                ...Object.values(purchaseData).filter((data: any) => {
                    return true;
                }),
            };
            setState('cartPackageId', course.userPackageId);
            this.props.navigate(`/checkout`, { state: data });
        }
    };

    render() {
        return (
            <Popover placement='bottom' id='user-chat-purchase-menu'>
                <Popover.Body>
                    <Row className='chat-purchase-container'>
                        <Col className='chat-purchase-wrapper'>
                            <Col className='chat-header-container'>
                                <p>Chat</p> <span>with course instructors</span>
                            </Col>
                            <Col className='chat-subheader-container'>
                                {`Gain access to chat with expert course instructors.`}
                            </Col>
                            <Col className='chat-image-container'>
                                <img src={process.env.PUBLIC_URL + '/student-chat.png'} />
                            </Col>
                            <Col className='chat-subheader-container'>
                                {`The chat is super easy to use throughout the course*, helping you move forward—instead of feeling 'stuck' or left on your own.`}
                            </Col>
                            <Col className='button-wrapper'>
                                <p>${this.props.course?.chatSettings?.price ?? 0}</p>
                                <Button className={`bp add-to-cart`} onClick={this.purchaseChat}>
                                    Get chat access
                                </Button>
                            </Col>
                            <Col className='footer-heading-1-container'>
                                <p>
                                    {`*Course chat is available at all points of the course except during exams. Instructors are available at set periods of the day, and may take 24 hours to respond to all messages. Note that access to course chat persists exclusively for the lifetime of the course, and is inaccessible after course expiry.`}
                                </p>
                            </Col>
                        </Col>
                    </Row>
                </Popover.Body>
            </Popover>
        );
    }
}
export default withRouterAndRedux(
    ChatPurchasePopOver,
    (state: any) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setCourseChat: (payload: any) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    },
);
