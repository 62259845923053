import React, { Component } from 'react';
import CardButtons from 'src/components/CardButtons';
import './Heading.slide.scss';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';

export interface IProps {
    heading?: string;
    subHeading?: string;
    content?: string;
}

export default class HeadingSlide extends Component<IProps> {
    render() {
        const { heading, subHeading, content } = this.props;
        return (
            <>
                <SlideHeader heading={heading} subHeading={subHeading} specificClass={'heading_card'} />

                <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            </>
        );
    }
}
