import React from 'react';

interface LessonContextType {
    setViewedNodes?: (cardId: string, viewedNodes: any[]) => unknown;
    setIsBlockedByNodes?: (arg0: boolean) => unknown;
    isBlockedByNodes?: boolean;
    updateIsSynced?: (value: boolean) => unknown;
    updateIsDraft?: (arg0: boolean) => unknown;
    isAdminPreview: boolean;
    loadUpdatedLesson?: () => unknown;
    setFeedback?: (arg0: {
        attemptStatus?: string;
        show?: boolean;
        title?: string;
        content?: string;
        expanded?: boolean;
        correct?: boolean;
        nextCardAvailable?: string;
        isAttempted?: boolean;
    }) => void;
    _id: string;
    userCourseId: string;
    userChapterId: string;
    setUserLessonCardData?: (userLessonCardData: any) => unknown;
    clearMediaPlay: (arg0: boolean) => void;
    isLoading: boolean;
    lesson: any;
    updateGradingReviewState?: (key: string, value: any) => void;
    inactiveTimerCallback: () => void;
    isAdmin: boolean;
    updateUserLessonCards?: (cards: any) => void;
    setTooltip?: (value: any) => void;
    cards: any[];
    course?: any;
    setActiveCardIndex?: (val: number) => void;
    isDraft?: any;
    nextLesson?: any;
    feedback: {
        attemptStatus?: string;
        show?: boolean;
        title?: string;
        content?: string;
        expanded?: boolean;
        correct?: boolean;
        nextCardAvailable?: string;
        isAttempted?: boolean;
    };
    previousLesson?: any;
    isSynced?: boolean;
    chapter: any;
}

export default React.createContext<LessonContextType>({
    isLoading: true,
    lesson: null,
    clearMediaPlay: (value: boolean) => {},
    inactiveTimerCallback: () => {},
    isAdmin: false,
    cards: [],
    userChapterId: '',
    _id: '',
    userCourseId: '',
    course: {},
    isAdminPreview: false,
    feedback: {
        isAttempted: false,
        correct: false,
    },
    chapter: [],
});
