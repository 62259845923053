import React, { Component } from 'react';
import { IQuizAttempt } from 'src/pages/Course/Stages/Lessons/LessonTypes/Slide/Quiz/Quiz';
import { Button } from 'react-bootstrap';
import './Attempt.scss';
import { EventBus } from 'src/helpers/new';
import DownloadButton from '../../Download/DownloadButton';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface IProps {
    attempt: IQuizAttempt;
    cardId?: string;
    cardIndex: number;
}

export default class Attempt extends Component<IProps> {
    reviewAttempt = () => {
        EventBus.dispatch('review-quiz', {
            attemptId: this.props.attempt._id,
            cardId: this.props.cardId,
            cardIndex: this.props.cardIndex,
        });
    };

    render() {
        const { attempt } = this.props;

        return (
            <div className='quiz-attempt'>
                <div className={`colour-bar ${attempt.status?.toLowerCase()}`}></div>
                <h6 className='status'>{attempt.status === 'SUCCESS' ? 'Passed' : 'Fail'}</h6>
                <span className='completed-date'>
                    {DateTimeFormatHelper.format(attempt.completedAt?.toString() as string, 'MM/DD/YYYY, hh:mm:ss')}
                </span>
                <span className='percentage-score'>{attempt.percentageScore?.cleanRound(2)}%</span>
                <Button className='bp' onClick={this.reviewAttempt}>
                    Review
                </Button>
                <DownloadButton attempt={attempt}>Download</DownloadButton>
            </div>
        );
    }
}
