import React, { Component } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Spinner } from 'react-bootstrap';
import uuid from 'react-uuid';
import MenuPopup from 'src/components/MenuPopup';
import mergeRefs from '../../helpers/mergeRefs';
import './Table.scss';
import TableButton from './TableButton';
import WithOverFlowTooltip from 'src/helpers/withOverFlowTooltip';
export default class Table extends Component {
    highlightedRow = React.createRef();

    columnsRefs = this.props.columns.map((column) => React.createRef());

    state = {
        selectedColumnFilterIndex: null,
        maxWidth: 'auto',
    };

    componentDidMount() {
        if (this.highlightedRow.current) {
            this.highlightedRow.current.scrollIntoView({ behavior: 'smooth' });
        }
        this.getMaxWidth();
    }

    setColumnFilterIndex = (i, column) => (e) => {
        if (column?.sortOverlap === true) {
            e.stopPropagation();
        }

        this.setState({
            selectedColumnFilterIndex: i,
        });
    };

    getMaxWidth = () => {
        let noOfColumnsWhoseWidthNotThere = 0;
        let string = `100%`;
        this.props.columns.forEach((item) => {
            if (!item.maxWidth) {
                noOfColumnsWhoseWidthNotThere++;
            } else {
                string = `${string} - ${item.maxWidth}`;
            }
        });

        if (this.props.rowButtons) {
            let rowButtonLength =
                this.props.rowButtons.reduce((width, button) => {
                    if (!button.icon) {
                        width += button.text.length * 0.9;
                    } else {
                        width += 2.06;
                    }
                    return width;
                }, 0) +
                (this.props.rowButtons.length - 1) * 0.75 +
                1.3 +
                'rem';
            string = `${string} - ${rowButtonLength}`;
        }
        string = `calc((${string} )/ ${noOfColumnsWhoseWidthNotThere})`;

        this.setState({
            maxWidth: string,
        });

        return string;
    };

    resetSetColumnFilterIndex = (val) => {
        this.setState({
            selectedColumnFilterIndex: val,
        });
    };

    render() {
        const {
            rows,
            columns,
            emptyMessage,
            onSort,
            onSortReset,
            rowButtons,
            sortData,
            loading,
            minTableWidth,
            maxTableWidth,
            reloadRows,
        } = this.props;

        const { selectedColumnFilterIndex } = this.state;

        const FilterComponent =
            selectedColumnFilterIndex !== null ? columns[selectedColumnFilterIndex].filter : React.Fragment;

        if (!rows || !rows.length) {
            return (
                <div className='table color--light padding--double text--center font--medium'>
                    {emptyMessage ?? 'No records'}
                </div>
            );
        }

        return (
            <div
                className='table'
                style={{
                    overflowX: 'auto',
                }}
            >
                <div
                    className='header'
                    style={{
                        minWidth: minTableWidth || 'auto',
                        maxWidth: maxTableWidth || 'auto',
                    }}
                >
                    {columns.map((column, i) => {
                        let divStyle = {
                            minWidth: column.minWidth || 'auto',
                            maxWidth: column.maxWidth || this.state.maxWidth,
                            visibility: column.visibility || '',
                        };
                        return (
                            <div
                                key={column.field}
                                style={divStyle}
                                onClick={
                                    !!column.sortKey && onSort
                                        ? async (e) => {
                                              await onSort(e, column.sortKey);
                                          }
                                        : undefined
                                }
                                onContextMenu={
                                    !!column.sortKey && onSortReset
                                        ? async (e) => {
                                              return await onSortReset(e, column.sortKey);
                                          }
                                        : undefined
                                }
                                className={`col${column.headClassName ? ' ' + column.headClassName : ''}`}
                            >
                                {!!column.sortKey && (
                                    <div className='col--sort'>
                                        {sortData[column.sortKey] === undefined || sortData[column.sortKey] === -1 ? (
                                            <i className='fa fa-caret-up' />
                                        ) : (
                                            <i className='fa fa-square-caret-up' />
                                        )}
                                        {sortData[column.sortKey] === undefined || sortData[column.sortKey] === 1 ? (
                                            <i className='fa fa-caret-down' />
                                        ) : (
                                            <i className='fa fa-square-caret-down' />
                                        )}
                                    </div>
                                )}

                                {column.text}

                                {!!column.filter && (
                                    <div
                                        className='col--filter'
                                        ref={this.columnsRefs[i]}
                                        onClick={this.setColumnFilterIndex(i, column)}
                                    >
                                        <i className='fa fa-filter fa-sm' />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {rowButtons && rowButtons.length && (
                        <div
                            className='col col--controls'
                            style={{
                                minWidth:
                                    rowButtons.reduce((width, button) => {
                                        if (!button.icon) {
                                            width += button.text.length * 0.9;
                                        } else {
                                            width += 2.06;
                                        }
                                        return width;
                                    }, 0) +
                                    (rowButtons.length - 1) * 0.75 +
                                    1.3 +
                                    'rem',
                            }}
                        />
                    )}
                </div>

                {this.props.draggableId ? (
                    <DragDropContext
                        onDragEnd={(result) => {
                            this.props.handleDragAndDrop(this.props.chapter._id, result);
                        }}
                    >
                        <Droppable droppableId={`droppable-${this.props.droppableId}`}>
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className='main'
                                    style={{
                                        minWidth: minTableWidth || 'auto',
                                        maxWidth: maxTableWidth || 'auto',
                                    }}
                                >
                                    <div className={`table-loading ${!loading ? ' hide' : ''}`}>
                                        <Spinner animation='border' />
                                    </div>
                                    {rows.map((doc, i) => {
                                        const highlightRow = this.props.highlightRow(doc);

                                        return (
                                            <Draggable
                                                key={`draggable-${this.props.draggableId}-${doc._id}`}
                                                draggableId={`draggable-${this.props.draggableId}-${doc._id}`}
                                                index={i}
                                            >
                                                {(provided) => (
                                                    <div
                                                        key={doc._id}
                                                        className={`row${highlightRow ? ' row--highlight' : ''}`}
                                                        ref={
                                                            highlightRow
                                                                ? mergeRefs(provided.innerRef, this.highlightedRow)
                                                                : provided.innerRef
                                                        }
                                                        {...provided.draggableProps}
                                                    >
                                                        {columns.map((column, j) => {
                                                            let divStyle = {
                                                                minWidth: column.minWidth || 'auto',
                                                                maxWidth: column.maxWidth || this.state.maxWidth,
                                                                visibility: column.visibility || '',
                                                            };
                                                            return (
                                                                <div
                                                                    key={`${doc._id}-${j}`}
                                                                    style={divStyle}
                                                                    className={`col${
                                                                        column.className ? ` ${column.className}` : ''
                                                                    }`}
                                                                >
                                                                    {column.noOverFlowToolTip ? (
                                                                        typeof column.field === 'function' ? (
                                                                            column.field(
                                                                                {
                                                                                    ...doc,
                                                                                    provided,
                                                                                },
                                                                                i
                                                                            )
                                                                        ) : (
                                                                            doc[column.field]
                                                                        )
                                                                    ) : (
                                                                        <WithOverFlowTooltip>
                                                                            {typeof column.field === 'function'
                                                                                ? column.field(
                                                                                      {
                                                                                          ...doc,
                                                                                          provided,
                                                                                      },
                                                                                      i
                                                                                  )
                                                                                : doc[column.field]}
                                                                        </WithOverFlowTooltip>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                        {rowButtons?.length && (
                                                            <div
                                                                className='col col--controls'
                                                                style={{
                                                                    minWidth:
                                                                        rowButtons.reduce((width, button) => {
                                                                            if (!button.icon) {
                                                                                width += button.text.length * 0.9;
                                                                            } else {
                                                                                width += 2.06;
                                                                            }

                                                                            return width;
                                                                        }, 0) +
                                                                        (rowButtons.length - 1) * 0.75 +
                                                                        1.3 +
                                                                        'rem',
                                                                }}
                                                            >
                                                                {rowButtons.map((button) => (
                                                                    <TableButton
                                                                        {...button}
                                                                        doc={doc}
                                                                        key={`${doc._id}-${uuid()}`}
                                                                        reloadTable={reloadRows}
                                                                    />
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                ) : (
                    <div
                        className='main'
                        style={{
                            minWidth: minTableWidth || 'auto',
                            maxWidth: maxTableWidth || 'auto',
                        }}
                    >
                        <div className={`table-loading ${!loading ? ' hide' : ''}`}>
                            <Spinner animation='border' />
                        </div>
                        {rows.map((doc, index) => {
                            return (
                                <div key={index} className='row'>
                                    {columns.map((column, i) => {
                                        let divStyle = {
                                            minWidth: column.minWidth || 'auto',
                                            maxWidth: column.maxWidth || this.state.maxWidth,
                                            visibility: column.visibility || '',
                                        };
                                        return (
                                            <div
                                                key={index + '-col-' + i}
                                                style={divStyle}
                                                className={`col${column.className ? ' ' + column.className : ''}`}
                                            >
                                                {column.noOverFlowToolTip ? (
                                                    typeof column.field === 'function' ? (
                                                        column.field(doc, index)
                                                    ) : (
                                                        doc[column.field]
                                                    )
                                                ) : (
                                                    <WithOverFlowTooltip>
                                                        {typeof column.field === 'function'
                                                            ? column.field(doc, index)
                                                            : doc[column.field]}
                                                    </WithOverFlowTooltip>
                                                )}
                                            </div>
                                        );
                                    })}
                                    {rowButtons?.length && (
                                        <div
                                            className='col col--controls'
                                            style={{
                                                minWidth:
                                                    rowButtons.reduce((width, button) => {
                                                        if (!button.icon) {
                                                            width += button.text.length * 0.9;
                                                        } else {
                                                            width += 2.06;
                                                        }

                                                        return width;
                                                    }, 0) +
                                                    (rowButtons.length - 1) * 0.75 +
                                                    1.3 +
                                                    'rem',
                                            }}
                                        >
                                            {rowButtons.map((button) => (
                                                <TableButton
                                                    {...button}
                                                    doc={doc}
                                                    key={`${doc._id}-${uuid()}`}
                                                    reloadTable={reloadRows}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                        {selectedColumnFilterIndex !== null && (
                            <MenuPopup
                                className={`table__filter-popup ${columns[selectedColumnFilterIndex]?.menuClassName}`}
                                parentRef={this.columnsRefs[selectedColumnFilterIndex]}
                                isOpen={!!selectedColumnFilterIndex}
                            >
                                <FilterComponent resetSetColumnFilterIndex={this.resetSetColumnFilterIndex} />
                            </MenuPopup>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
