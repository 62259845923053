import React, { Component } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { isEmpty, round } from 'lodash';
import './FileUpload.scss';
export default class FileUpload extends Component {
    state = {
        fileVal: '',
        fileUrl: '',
        showError: false,
        validationError: '',
    };

    componentDidMount() {
        this.setState({
            fileUrl: this.props.url,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const newState = {};

        if (this.props.url !== prevState.fileUrl && this.props.url) {
            newState.fileUrl = this.props.url;
        }
        if (this.props.deletedAt !== null && this.props.deletedAt !== prevProps.deletedAt) {
            newState.fileVal = '';

            if (this.props.handleFileChange) {
                this.props.handleFileChange(this.props.type, undefined, undefined);
            }

            if (this.props.onChange) {
                this.props.onChange(
                    {
                        target: {
                            name: this.props.name,
                        },
                    },
                    undefined,
                    undefined,
                );
            }
        }

        if (Object.keys(newState).length !== 0) {
            this.setState(newState);
        }
    }

    handleFileChange = (event) => {
        if (event.target.value) {
            const file = event.target.files[0],
                url = URL.createObjectURL(file);

            if (this.props.restrictMaxSize && this.props.maxSize) {
                const fileSizeInKilobytes = round(file.size / 1024);
                const maxSizeInKilobytes = 1024 * this.props.maxSize;
                if (fileSizeInKilobytes > maxSizeInKilobytes) {
                    this.setState({
                        fileVal: '',
                        fileUrl: '',
                        validationError: `The maximum file size allowed is ${this.props.maxSize} mb.`,
                    });
                    if (this.props.handleFileChange) {
                        this.props.handleFileChange(this.props.type, '', null);
                    }
                    return;
                } else {
                    this.setState({
                        validationError: '',
                    });
                }
            }

            if (this.props.isSquareUpload) {
                if (file) {
                    var img = new Image();
                    const self = this;

                    img.onload = function () {
                        if (this.width === this.height) {
                            self.setState({
                                validationError: '',
                            });
                        } else {
                            self.setState({
                                validationError:
                                    'Uploaded image is not the recommended size may get distorted or cropped!',
                            });
                        }
                    };

                    img.onerror = function () {
                        console.log('Not a valid file: ' + file.type);
                    };

                    img.src = url;
                    this.uploadImage(event, file, url);
                }
            } else {
                this.uploadImage(event, file, url);
            }
        }
    };

    uploadImage = (event, file, url) => {
        const extension = '.' + file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length);
        if (
            this.props.accept === undefined ||
            (Array.isArray(this.props.accept) && this.props.accept.length === 0) ||
            (this.props.accept && this.props.accept.includes(extension))
        ) {
            this.setState({
                fileVal: event.target.value ?? '',
                fileUrl: url,
                showError: false,
            });
            if (this.props.handleFileChange) {
                this.props.handleFileChange(this.props.type, url, file);
            }

            if (this.props.onChange) {
                this.props.onChange(event, file, url);
            }

            if (this.props.updatePreviewUrl) {
                this.props.updatePreviewUrl(url);
            }
        } else {
            this.setState({ showError: true });
        }
    };

    getFileNameFromURL = (url) => {
        if (!url) return 'No file chosen';

        const urlObject = new URL(url);
        const pathSegments = decodeURIComponent(urlObject.pathname).split(url.includes('https') ? '/' : /[\\\/]/);
        const fileNameWithParams = pathSegments[pathSegments.length - 1];
        const [fileName] = fileNameWithParams.split('?');

        return fileName;
    };

    render() {
        const uploadedFileName = isEmpty(this.state.fileVal)
            ? isEmpty(this.props.uploadedFileName)
                ? `No file chosen ${this.props.fileChosenText ?? ''}`
                : this.props.uploadedFileName
            : this.getFileNameFromURL(this.state.fileVal);

        return (
            <div className={`Upload-Input ${this.props.validationError ? 'error' : ''}`}>
                <label className={this.props.labelClassName}>
                    {this.props.label ?? this.props.type[0].toUpperCase() + this.props.type.substr(1).toLowerCase()}
                </label>
                <label>
                    {this.props.fileName}
                    {this.props.fileName && this.props.deleteFileHandle && (
                        <div className='bd' onClick={this.props.deleteFileHandle}>
                            <Fa icon={faTrashAlt} />
                        </div>
                    )}
                </label>

                {!!this.props.description && (
                    <>
                        <br />
                        <small>{this.props.description}</small>
                    </>
                )}
                <input
                    type='file'
                    className={`mb-2 ${this.props.className}`}
                    accept={
                        this.props.type === 'image'
                            ? '.png,.jpg,.jpeg,.webp,.svg'
                            : this.props.type === 'audio'
                              ? '.mp3,.wav,.m4a,.mp4,.wma,.aac'
                              : this.props.type === 'video'
                                ? '.mp4,.mov,.wmv,.flv,.avi,.mkv,.webm'
                                : this.props.type === 'document'
                                  ? '.doc,.pdf'
                                  : this.props.accept
                                    ? this.props.accept
                                    : ''
                    }
                    value={this.state.fileVal ?? ''}
                    id={this.props.id ?? 'fileUpload'}
                    name={this.props.name}
                    onChange={this.handleFileChange}
                    disabled={this.props.disabled}
                    style={{
                        display: 'none',
                    }}
                />
                <div className='button-container'>
                    <button
                        className='upload-button '
                        onClick={() => {
                            document.getElementById(this.props.id ?? 'fileUpload').click();
                        }}
                        type='button'
                    >
                        Choose file
                    </button>
                    <span title={uploadedFileName}>{uploadedFileName}</span>
                </div>
                {(this.props.validationError || this.state.validationError) && (
                    <small className={'form-builder__error-color'}>
                        {this.props.validationError ? this.props.validationError : this.state.validationError}
                    </small>
                )}

                {this.state.showError && (
                    <>
                        <div className='text-danger'>
                            Only {this.props.accept.replaceAll('.', '')} files are allowed
                        </div>
                        <br />
                    </>
                )}

                {!isEmpty(this.state.fileUrl) && this.props.type === 'image' && (
                    <div className='Img-Wrap'>
                        <img
                            className='Img-Path'
                            src={Array.isArray(this.state.fileUrl) ? this.state.fileUrl[2] : this.state.fileUrl}
                        />
                    </div>
                )}

                {!!this.state.fileUrl && this.props.type === 'audio' && (
                    <audio controls='controls' src={this.state.fileUrl} />
                )}

                {!!this.state.fileUrl && this.props.type === 'video' && (
                    <video height='200' width='300' controls='controls' src={this.state.fileUrl} />
                )}

                {!!this.state.fileUrl && this.props.type === 'document' && (
                    <div>
                        <iframe
                            style={{
                                width: '100%',
                                height: '800px',
                            }}
                            src={this.state.fileUrl}
                        ></iframe>
                    </div>
                )}
            </div>
        );
    }
}
