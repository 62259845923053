import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import LessonContext from '../LessonContext';
import { Api, EventBus } from 'src/helpers/new';
import Progress from './Progress';
import Chapter, { IChapter } from './Chapter';
import { ILesson } from './Lesson';
import SideBarToggle from './SideBarToggle';
import './NavigationSidebar.scss';
import ChapterSkeleton from 'src/components/Skeleton/ChapterSkeleton/ChapterSkeleton';
import { omit } from 'lodash';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface IProps {
    course: any;
    isDraft?: boolean;
    isSideBarHidden: boolean;
    isAdminPreview: boolean;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

interface IState {
    isLoading: boolean;
    chapters: IChapter[];
    chapterWiseLessons?: { [key: string]: ILesson[] };
}

class NavigationSidebar extends Component<TProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    resizeBreakpoint = 1200;
    previousWidth: number = window.innerWidth;

    state: IState = {
        isLoading: true,
        chapters: [],
        chapterWiseLessons: {},
    };

    componentDidMount() {
        EventBus.on('update-admin-preview-lesson', this.updateAdminPreview);
        EventBus.on('update-lesson-status', this.updateLessonStatus);
        EventBus.onWindow('resize', this.handleresize);

        if (window.innerWidth <= this.resizeBreakpoint) EventBus.dispatch('toggle-nav-sidebar', true, 0);

        this.loadData();
    }

    componentDidUpdate(prevProps: IProps): void {
        if (prevProps.isDraft !== this.props.isDraft) {
            this.loadData();
        }
    }

    componentWillUnmount(): void {
        EventBus.remove('update-admin-preview-lesson', this.updateAdminPreview);
        EventBus.removeFromWindow('resize', this.handleresize);
        EventBus.remove('update-lesson-status', this.updateLessonStatus);
    }

    updateLessonStatus = (event: any) => {
        const { chapterId, lessonId, essayStatus } = event.detail;

        const chapters = [...this.state.chapters];
        const chapterIndex = chapters.findIndex((chapter) => chapter._id === chapterId);
        if (chapterIndex === -1) {
            console.warn(`Chapter with ID ${chapterId} not found`);
            return;
        }

        const lessonIndex = chapters[chapterIndex].lessons.findIndex((lesson) => lesson._id === lessonId);
        if (lessonIndex === -1) {
            console.warn(`Lesson with ID ${lessonId} not found in chapter ${chapterId}`);
            return;
        }

        chapters[chapterIndex].lessons[lessonIndex].essay_status = essayStatus;

        this.setState({ chapters });
    };

    loadData = async () => {
        const { chapterId } = this.props.params;

        const { success, response } = await Api.call(
            'get',
            `/users/course/${this.props.params.courseId}/${chapterId}/navigation`,
            null,
            {
                isDraft: this.props.course.isAdminPreview && this.context.isDraft,
            },
        );
        if (success) {
            this.setState({
                chapters: response.chapters,
                chapterWiseLessons: {
                    ...this.state.chapterWiseLessons,
                    [chapterId.toString()]: response.lessons,
                },
                isLoading: false,
            });
        }
    };

    loadChaptersNavigationData = async (chapterId = '') => {
        chapterId = chapterId ? chapterId : this.props.params.chapterId;
        const { success, response } = await Api.call('get', `/users/chapters/${chapterId}/navigation`);
        if (success) {
            this.setState({
                ...this.state,
                chapterWiseLessons: {
                    ...this.state.chapterWiseLessons,
                    [chapterId.toString()]: response,
                },
            });
        }
    };

    handleresize = () => {
        const width = window.innerWidth;
        const windowNotResizedPassedBreakpoint =
            (width <= this.resizeBreakpoint && this.previousWidth <= this.resizeBreakpoint) ||
            (width > this.resizeBreakpoint && this.previousWidth > this.resizeBreakpoint);
        this.previousWidth = width;

        if (windowNotResizedPassedBreakpoint) return;

        EventBus.dispatch('toggle-nav-sidebar', width <= this.resizeBreakpoint);
    };

    updateAdminPreview = (e: Event) => {
        const { chapterId, lessonId, ...updateData } = (e as CustomEvent).detail;
        const chapters = [...this.state.chapters];
        const chapterIndex = chapters.findIndex((chapter: IChapter) => chapter._id === chapterId);
        const lessonIndex = chapters[chapterIndex].lessons.findIndex((lesson: ILesson) => lesson._id === lessonId);

        chapters[chapterIndex].lessons[lessonIndex].draft = {
            ...chapters[chapterIndex].lessons[lessonIndex].draft,
            ...updateData,
        };

        this.setState({ chapters });
    };

    render() {
        const { chapters, isLoading, chapterWiseLessons } = this.state;
        const { course } = this.props;
        const minimised = this.props.isSideBarHidden;

        if (isLoading) return <ChapterSkeleton />;

        return (
            <div className={`navigation-sidebar-container ${minimised ? 'minimised' : ''}`}>
                <div className={`navigation-sidebar`}>
                    <div className='navigation-sidebar-header'>
                        <span>
                            <SideBarToggle hideOnOpen={false} />
                            <h3>{course.title}</h3>
                        </span>
                        <Progress />
                    </div>
                    {chapters.map((chapter: IChapter) => {
                        if (chapterWiseLessons) {
                            chapter.lessons = chapterWiseLessons[chapter._id.toString()] ?? [];
                        }
                        return (
                            <Chapter
                                key={chapter._id}
                                _id={chapter._id}
                                title={chapter.title}
                                lessons={chapter.lessons}
                                isDraft={this.props.isDraft}
                                isAdminPreview={this.props.isAdminPreview}
                                loadChaptersNavigationData={this.loadChaptersNavigationData}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default withRouter(NavigationSidebar);
