let allTimers = {};

export const commonTimeout = {
    startTimer: (timerName, cb, delay) => {
        allTimers = {
            ...allTimers,
            [timerName]: setTimeout(() => cb(), delay),
        };
    },
    clearTimer: (timerName) => {
        clearTimeout(allTimers?.[timerName]);
        delete allTimers?.[timerName];
    },
};
