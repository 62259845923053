import React, { Component } from 'react';
import { EventBus, IdempotencyController } from 'src/helpers/new';
import Alert, { IAlert } from './Alerts/Alert';
import Toast, { IToast } from './Alerts/Toasts';
import { nanoid } from 'nanoid';
import './AlertHandler.scss';
import * as Sentry from '@sentry/react';

interface IState {
    alerts: Record<string, IAlert>;
    toasts: Record<string, IToast>;
}

export default class AlertHandler extends Component<unknown, IState> {
    Idemptotency = new IdempotencyController(500);

    state: IState = {
        alerts: {},
        toasts: {},
    };

    componentDidMount = () => {
        EventBus.on('api-response', (e: Event) => {
            try {
                const response = (e as CustomEvent).detail;
                if (response.success) {
                    if (response.notification) {
                        const { key, duration } = response.notification?.idempotency ?? {};
                        if (key && !this.Idemptotency.verify(key, duration)) return;

                        const alert: IAlert | IToast = {
                            id: nanoid(),
                            type: response.notification.type,
                            title: response.notification.title,
                            message: response.notification.message,
                            duration: 2000,
                        };
                        if (alert.title) {
                            this.createAlert(alert);
                        } else {
                            this.createToast(alert);
                        }
                    }
                } else {
                    const alert: IAlert = {
                        id: nanoid(),
                        type: 'error',
                        title: 'Error',
                        message: response.message,
                        duration: 5000,
                    };
                    this.createAlert(alert);
                }
            } catch (error) {
                Sentry.captureException(error);
            }
        });
        EventBus.on('alert', (e: Event) => {
            try {
                const alert = { ...(e as CustomEvent).detail };
                alert.id = nanoid();

                this.createAlert(alert);
            } catch (error) {
                Sentry.captureException(error);
            }
        });
        EventBus.on('toast', (e: Event) => {
            try {
                const alert = { ...(e as CustomEvent).detail };
                alert.id = nanoid();

                this.createToast(alert);
            } catch (error) {
                Sentry.captureException(error);
            }
        });
    };

    componentWillUnmount = () => {
        EventBus.remove('api-response');
        EventBus.remove('alert');
    };

    createAlert = (alert: IAlert) => {
        this.setState((prevState) => ({
            alerts: {
                ...prevState.alerts,
                [alert.id]: {
                    displayType: 'normal',
                    ...alert,
                },
            },
        }));
    };

    createToast = (alert: IToast) => {
        this.setState((prevState) => ({
            toasts: {
                ...prevState.toasts,
                [alert.id]: alert,
            },
        }));
    };

    dismissAlert = (key: string) => {
        this.setState((prevState) => {
            const alerts = { ...prevState.alerts };
            delete alerts[key];

            return { alerts };
        });
    };

    dismissToast = (key: string) => {
        this.setState((prevState) => {
            const { toasts } = prevState;
            const newToasts = { ...toasts };

            delete newToasts[key];

            return { toasts: newToasts };
        });
    };

    render() {
        const { alerts, toasts } = this.state;

        return (
            <>
                <div className='alerts-container'>
                    {Object.values(alerts).map((alert: IAlert) => {
                        return <Alert key={alert.id} alert={alert} dismiss={this.dismissAlert} />;
                    })}
                </div>
                <div className='toasts-container'>
                    {Object.values(toasts).map((toast: IToast) => {
                        return <Toast key={toast.id} toast={toast} dismiss={this.dismissToast} />;
                    })}
                </div>
            </>
        );
    }
}
