import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './MaintenancePrompt.scss';
import { RouteComponentProps } from 'src/hoc/withRouter';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
interface IProps extends RouteComponentProps {
    onChangeShowMaintenancePage: () => void;
    isShow: boolean;
    setLoggedIn: (payload: any) => void;
    loggedIn: {
        APP_VERSION: string;
        token: string;
        user?: {
            adminLevel: number;
            firstName: string;
            passwordExpiredAt: string;
            timeZone: string;
            userRole: string;
            _id: string;
            maintenance: {
                startTime: string;
                endTime: string;
                allOperationMsg: string;
                maintenanceScheduleMsg: string;
            };
        };
        maintenance?: {
            startTime: string;
            endTime: string;
            allOperationMsg: string;
            maintenanceScheduleMsg: string;
        };
    };
}

interface IState {}

class MaintenancePrompt extends Component<IProps, IState> {
    state: IState = {};

    handleMaintenanceView = async (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.navigate(`/status`, { replace: true });
        this.props.onChangeShowMaintenancePage();
    };

    render() {
        const endTime = this.props.loggedIn?.user?.maintenance?.endTime ?? this.props.loggedIn?.maintenance?.endTime;
        const displayUntill = DateTimeFormatHelper.utcToUserTimeZone(endTime ?? '', 'hh:mm A');
        return (
            <Modal className='maintenance-prompt-model' show={this.props.isShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Website is under maintenance</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={'maintenance-content'}>
                        We sincerely apologize for the inconvenience. Our site is currently undergoing scheduled
                        maintenance and upgrades, but will return shortly at [{displayUntill}].
                        <br />
                        Thank you for your patience.
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn bp' onClick={this.handleMaintenanceView}>
                        View status
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouterAndRedux(
    MaintenancePrompt,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: any) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    },
);
