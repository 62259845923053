import React, { Component } from 'react';
import { IItemData } from '../Group';
import './CalendarLegend.scss';

export type TLegendItem = {
    legendComponent: React.ComponentType<ICustomCalendarLegendComponent> | null;
    itemComponent: React.ComponentType<ICustomCalendarItemComponent>;
    matches: (data: IItemData) => boolean;
    onClick: (data: IItemData, originalIndex: number) => void | Promise<void>;
    active?: boolean;
    enabled?: boolean;
    legendOrderIndex?: number;
    alwaysVisible?: boolean;
    originalIndex?: number;
};

export interface ICustomCalendarLegendComponent {
    rule: TLegendItem;
    toggleLegend: () => void;
}

export interface ICustomCalendarItemComponent {
    originalIndex: number;
    rule: TLegendItem;
    item: IItemData;
}

export type TCalendarLegend = TLegendItem[];

interface IProps {
    legend: TLegendItem[];
    onLegendToggle: (index: number) => void;
}

export default class CalendarLegend extends Component<IProps> {
    get legend(): TLegendItem[] {
        const legend = this.props.legend.map((item, index) => {
            return { ...item, originalIndex: index };
        });

        return legend.sort((a: TLegendItem, b: TLegendItem) => (a.legendOrderIndex ?? 0) - (b.legendOrderIndex ?? 0));
    }

    render() {
        return (
            <div className='calendar-legend'>
                {this.legend.map((legendItem: TLegendItem) => {
                    if (legendItem.legendComponent === null || !legendItem.enabled) return null;

                    const CustomLegendComponent = legendItem.legendComponent;
                    return (
                        <CustomLegendComponent
                            toggleLegend={() => this.props.onLegendToggle(legendItem.originalIndex as number)}
                            key={legendItem.originalIndex as number}
                            rule={legendItem}
                        />
                    );
                })}
            </div>
        );
    }
}
