import React, { Component } from 'react';
import './CourseBreadcrumbs.scss';
import WithOverFlowTooltip from 'src/helpers/withOverFlowTooltip';

interface IProps {
    firstItem: string;
    secondItem: string;
    centered: boolean;
}

export default class CourseBreadcrumb extends Component<IProps> {
    render() {
        const { firstItem, secondItem, centered } = this.props;

        return (
            <header className='course-breadcrumb' style={{ justifyContent: centered ? 'center' : undefined }}>
                <div>
                    <div className='first-item-wrapper'>
                        <span>
                            <WithOverFlowTooltip placement='bottom'>{firstItem}</WithOverFlowTooltip>
                        </span>
                    </div>

                    <i className='fa fa-chevron-right' />
                    <span>{secondItem}</span>
                </div>
            </header>
        );
    }
}
