import React, { Component } from 'react';
import CheckoutContext from '../CheckoutContext';
import { Contact, Billing, Payment, Upsells } from './Tabs';
import PropTypes from 'prop-types';

export default class TabContent extends Component {
    static contextType = CheckoutContext;

    render() {
        switch (this.context.currentTab) {
            case 0:
                return <Upsells />;
            case 1:
                return <Contact />;
            case 2:
                return <Billing />;
            case 3:
                return (
                    <Payment
                        onSetTermsAndConditions={this.props.onSetTermsAndConditions}
                        pkgTermsConditionsCheckboxToggle={this.props.pkgTermsConditionsCheckboxToggle}
                    />
                );
            default:
                return <></>;
        }
    }
}

TabContent.propTypes = {
    onSetTermsAndConditions: PropTypes.func.isRequired,
    pkgTermsConditionsCheckboxToggle: PropTypes.func.isRequired,
};
