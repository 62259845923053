import React, { Component } from 'react';
import { Api } from 'src/helpers/new';
import { FileUpload } from '.';
import apiFile from '../../helpers/apiFile';
import './AsyncPreviewFileUpload.scss';

export default class AsyncPreviewFileUpload extends Component {
    state = {
        fileUrl: '',
        deletedAt: null,
        fileName: '',
    };

    async componentDidMount() {
        this.loadFile();
    }

    componentDidUpdate(prevProps) {
        if (this.props.reloadOnFileChange && prevProps.file !== this.props.file) {
            this.loadFile();
        }
    }

    loadFile = async () => {
        const { file } = this.props;
        let fileUrl = file;
        let fileName;
        if (file && typeof file === 'string' && file.substr(0, 4) !== 'blob') {
            const response = await apiFile(file);
            fileUrl = response.url;
            fileName = response.fileName;
        }

        this.setState({
            fileUrl,
            fileName,
        });
    };

    updateFileUrl = (fileUrl) => {
        this.setState({
            fileUrl,
        });
    };

    deleteFileHandle = async () => {
        const { file } = this.props;

        if (file && typeof file === 'string' && file.substr(0, 4) !== 'blob') {
            await Api.call('DELETE', `/files/${file}`);
        }

        this.setState({ fileUrl: '', deletedAt: new Date().getTime() }, () => {
            this.setState({
                deletedAt: null,
            });
        });
    };

    render() {
        const {
            props: { file, ...rest },
            state: { fileUrl, deletedAt, fileName },
        } = this;

        return (
            <div className='asyncpreview-fileupload'>
                <FileUpload
                    {...rest}
                    deletedAt={deletedAt}
                    url={fileUrl}
                    updatePreviewUrl={this.updateFileUrl}
                    deleteFileHandle={this.deleteFileHandle}
                    fileName={fileName}
                    uploadedFileName={fileName}
                />
            </div>
        );
    }
}
