import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class WithOverFlowTooltipTags extends Component {
    state = {
        isOverFlow: false,
        text: undefined,
    };

    updateOverflow = (e) => {
        const el = e.target;
        const { text } = this.state;

        if (el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight) {
            this.setState({ isOverFlow: true });
            if (el.textContent !== text) {
                this.setState({ text: el.textContent });
            }
        } else {
            this.setState({ isOverFlow: false });
        }
    };

    render() {
        const { isOverFlow, text } = this.state;
        const { Tag, content } = this.props;

        return isOverFlow && text ? (
            <OverlayTrigger
                key={`tooltip`}
                placement='top'
                overlay={<Tooltip>{isOverFlow && text ? text : ''}</Tooltip>}
            >
                <Tag className={this.props.className} onMouseEnter={this.updateOverflow}>
                    {content}
                </Tag>
            </OverlayTrigger>
        ) : (
            <Tag className={this.props.className} onMouseEnter={this.updateOverflow}>
                {content}
            </Tag>
        );
    }
}
