import React, { Component } from 'react';
import QuestionsModal from '../../Lessons/Cards/Interactive/Quiz/Slide/QuestionsModal';
import { IExamContent } from '../Exam';
import { clone, isNil } from 'lodash';
import { Api, EventBus } from 'src/helpers/new';
import './Exam.Inline.scss';

interface IProps {
    current: IExamContent;
    bioSightOkay: boolean;
}
interface IState {
    current: IExamContent;
    marks?: number;
}

export default class InlineExam extends Component<IProps, IState> {
    state: IState = {
        current: this.props.current,
    };

    componentDidMount(): void {
        this.setState({ current: this.props.current });
    }

    checkAnswer = async (questionIndex: number, option: any) => {
        const currentValue = clone(this.state.current.answers)[questionIndex];
        const answers = this.state.current.answers;
        answers[questionIndex] = option;

        this.setState({
            current: {
                ...this.state.current,
                answers,
            },
        });

        const { success, response, message } = await Api.call(
            'GET',
            `/users/exam/${this.state.current._id}/answer/${questionIndex}/${option}`,
        );

        if (!success) {
            EventBus.dispatch('toast', {
                type: 'error',
                message: message ?? 'Something wrong on answer selection !',
            });

            const answers = clone(this.state.current.answers);
            if (!isNil(currentValue)) {
                answers[questionIndex] = currentValue;
            } else {
                delete answers[questionIndex];
            }

            this.setState({
                current: {
                    ...this.state.current,
                    answers,
                },
            });
        }

        if (success) {
            this.setState({
                marks: this.state.marks + response.marks,
                current: {
                    ...this.state.current,
                    spentTime: response.spentTime,
                },
            });
        }
    };

    handleSubmit = async () => {
        EventBus.dispatch('end-exam-proctoring');
        window.socket.emit('submit exam', {
            examId: this.state.current?._id,
            courseId: this.state.current?.userCourseId,
        });
    };

    render() {
        const { current } = this.state;

        return (
            <div className='exam-info'>
                <div className={`exam-title ${this.props.bioSightOkay ? 'okay' : 'flagged'}`}>
                    <h4>
                        <b>Final Exam</b>
                    </h4>
                </div>
                <QuestionsModal
                    exam={true}
                    show={true}
                    title={current.title}
                    questions={current?.questions ?? []}
                    answers={current?.answers}
                    timeLimit={new Date().getTime() + ((current?.timeLimit ?? 0) - (current?.spentTime ?? 0)) * 60000}
                    allowSkip={current?.allowSkip}
                    onAnswer={this.checkAnswer}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }
}
