import React, { Component } from 'react';
import { IQuiz } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/types';
import { IUserLessonCardData } from '../../../UserLessonCard';
import CardQuiz from './Card/card.quiz';
import InlineQuiz from './Inline/Inline.quiz';

export interface IProps {
    quiz: IQuiz;
    cardId: string;
    userLessonCardData: IUserLessonCardData;
    course: any;
    theme?: string;
    heading?: string;
    subHeading?: string;
}

export default class QuizPage extends Component<IProps> {
    render() {
        if (this.props.quiz?.quizType === 'inline') {
            return (
                <InlineQuiz
                    cardId={this.props.cardId}
                    theme={this.props.theme}
                    heading={this.props.heading}
                    subHeading={this.props.subHeading}
                    quiz={this.props.quiz}
                    userLessonCardData={this.props.userLessonCardData}
                />
            );
        } else {
            return <CardQuiz {...this.props} />;
        }
    }
}
