import { Api, Utility } from 'src/helpers/new';
import { IContact } from './Contact/Contact';
import { IBilling } from './Billing/Billing';

export class CheckoutValidateHelper {
    static readonly validateEmail = (email: string) => {
        return Utility.validateEmail(email);
    };

    static validateContact(error: any, contact: IContact) {
        let proceed = true;
        const messages = { ...error };
        const { firstName, lastName, email, confirmEmail } = contact;

        if (!email) {
            messages.emailMessage = 'Email cannot be empty.';
            proceed = false;
        } else if (email.length < 5) {
            messages.emailMessage = 'Email too short';
            proceed = false;
        } else if (email.length > 128) {
            messages.emailMessage = 'Email too long';
            proceed = false;
        } else if (!this.validateEmail(email)) {
            messages.emailMessage = 'Invalid email.';
            proceed = false;
        } else if (!this.validateEmail(confirmEmail) || email !== confirmEmail) {
            messages.confirmEmailMessage = 'Your email does not match. Please type it correctly.';
            proceed = false;
        }

        if (!firstName) {
            messages.firstNameMessage = 'First Name cannot be empty.';
            proceed = false;
        } else if (firstName.trim().length < 1) {
            messages.firstNameMessage = 'First name too short';
            proceed = false;
        } else if (firstName.length > 128) {
            messages.firstNameMessage = 'First name too long';
            proceed = false;
        }
        if (!lastName) {
            messages.lastNameMessage = 'Last Name cannot be empty.';
            proceed = false;
        } else if (lastName.trim().length < 1) {
            messages.lastNameMessage = 'Last name too short';
            proceed = false;
        } else if (lastName.length > 128) {
            messages.lastNameMessage = 'Last name too long';
            proceed = false;
        }

        return {
            proceed,
            messages,
        };
    }

    static validateBilling(error: any, billing: IBilling) {
        let proceed = true;
        const messages = { ...error };

        const { streetLines, town, zipCode, firstName, lastName } = billing;

        if (!firstName) {
            messages.firstNameMessage = 'First Name cannot be empty.';
            proceed = false;
        }
        if (!lastName) {
            messages.lastNameMessage = 'Last Name cannot be empty.';
            proceed = false;
        }
        if ((streetLines && streetLines[0]?.length === 0) || !streetLines[0]) {
            messages.addressLineMessage = 'Address Line cannot be empty';
            proceed = false;
        }
        if (!town) {
            messages.townMessage = 'City cannot be empty';
            proceed = false;
        }
        if (!zipCode) {
            messages.zipCodeMessage = 'Zip code cannot be empty';
            proceed = false;
        }

        if (zipCode && !Utility.validateZipCode(zipCode)) {
            messages.zipCodeMessage = 'Zip code allowed only for US origin';
            proceed = false;
        }

        return {
            proceed,
            messages,
        };
    }

    static async checkEmail(email: string, userId: string) {
        const { response } = await Api.call('post', '/checkout/cart/createAccount/checkEmail', { email, userId });

        let inUse = false,
            canUse = true;
        if (response.userExists) {
            inUse = true;
            canUse = false;
        }

        if (response.userIsLoggedIn) {
            canUse = true;
        }
        return {
            inUse,
            canUse,
        };
    }
}
