import React, { Component, ReactNode } from 'react';
import { Api } from 'src/helpers/new';
import './Banner.scss';
import { RootState, IAnnouncements } from 'src/store/reducers/rootReducer';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IState {
    showBanner: boolean;
    bannerData: string;
    heading: string;
    isDismissable: boolean;
    type: string;
    id: string;
}

interface AnnouncementData {
    heading?: string;
    announcement?: string;
    isDismissable: boolean;
    type: string;
    _id: string;
}

interface IConnectProps {
    announcements: IAnnouncements;
    setAnnouncements: (payload: IAnnouncements) => void;
}

interface IProps extends IConnectProps {
    channel: string;
}
class Banner extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            showBanner: false,
            bannerData: '',
            heading: '',
            isDismissable: true,
            type: '',
            id: '',
        };
    }

    setAnnouncementBanner = () => {
        const { announcements } = this.props.announcements;

        if (announcements) {
            const announcementData = announcements[this.props.channel];
            if (announcementData) {
                this.setState({
                    bannerData: announcementData.announcement ?? '',
                    showBanner: true,
                    heading: announcementData.heading ?? '',
                    isDismissable: announcementData.isDismissable,
                    type: announcementData.type,
                    id: announcementData._id,
                });
            } else {
                this.setState({
                    bannerData: '',
                    showBanner: false,
                    heading: '',
                    isDismissable: true,
                    type: '',
                    id: '',
                });
            }
        }
    };

    componentDidMount = async () => {
        this.setAnnouncementBanner();
    };

    async componentDidUpdate(prevProps: IProps) {
        if (prevProps.announcements !== this.props.announcements) {
            this.setAnnouncementBanner();
        }
    }

    dismissBanner = async () => {
        const { success } = await Api.call('POST', `/user-announcements/dismiss/${this.state.id}`, {
            channel: this.props.channel,
        });

        if (success) {
            this.setState({
                showBanner: false,
            });

            this.props.setAnnouncements({
                isLoaded: true,
                announcements: {
                    ...this.props.announcements?.announcements,
                    [this.props.channel]: null,
                },
            });
        }
    };

    render(): ReactNode {
        const { bannerData, showBanner, heading, type, isDismissable } = this.state;

        return (
            showBanner && (
                <div className='banner-container'>
                    <div className={`package-banner ${type}`}>
                        <div className='banner-content'>
                            {type === 'critical' ? (
                                <div className={`banner-type-icon banner-type-icon--${type}`}>
                                    <i className='fa fa-triangle-exclamation fa-2xl' />
                                </div>
                            ) : (
                                <div className={`banner-type-icon banner-type-icon--${type}`}>
                                    <i className='fa fa-circle-info fa-2xl' />
                                </div>
                            )}
                            {heading && (
                                <h4
                                    className='banner-heading center-content'
                                    dangerouslySetInnerHTML={{ __html: heading }}
                                ></h4>
                            )}
                            {bannerData && (
                                <span
                                    className={`banner-data ${!heading ? 'center-content' : ''}`}
                                    dangerouslySetInnerHTML={{ __html: bannerData }}
                                ></span>
                            )}
                        </div>
                        {isDismissable && (
                            <div className='banner-close-icon'>
                                <i className='fa fa-xmark fa-2xl' onClick={this.dismissBanner} />
                            </div>
                        )}
                    </div>
                </div>
            )
        );
    }
}

export default withRouterAndRedux(
    Banner,
    (state: RootState) => {
        return {
            announcements: state.announcements,
        };
    },
    {
        setAnnouncements: (payload: IAnnouncements) => ({
            type: 'SET_ANNOUNCEMENTS',
            payload,
        }),
    },
);
