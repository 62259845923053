import React, { Component } from 'react';
import { ICustomCalendarItemComponent } from 'src/components/_New/Navigation/Calendar';
import './ItemComponents.scss';

export default class PassedCard extends Component<ICustomCalendarItemComponent> {
    render() {
        const { originalIndex, rule, item } = this.props;

        return (
            <div className='slide-calendar-item passed-card' onClick={() => rule.onClick(item, originalIndex)}>
                <h3>{originalIndex + 1}</h3>
                <div className={`status ${item.colour}`}>
                    <i className='fa fa-check' />
                </div>
            </div>
        );
    }
}
