import React, { Component } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import './ChatPostPurchaseModal.scss';
import CourseContext from 'src/pages/Course/CourseContext';
import { isNil } from 'lodash';
import { Api } from 'src/helpers/new';

interface IProps extends RouteComponentProps {}
interface IState {
    isShowModal: boolean;
}

class ChatPostPurchasePopOver extends Component<IProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;

    state: IState = {
        isShowModal: false,
    };

    componentDidMount(): void {
        const isShowModal = this.context.course?.justBoughtChat;
        if (!isNil(isShowModal) && isShowModal) {
            this.setState({ isShowModal });
        }
    }

    continueChat = async () => {
        this.setState({ isShowModal: false });

        const { success } = await Api.call('PATCH', `courses/chat/update-chat-flag/${this.context.course?._id}`);
        if (success && this.context.updateCourseInfo) {
            this.context.updateCourseInfo('justBoughtChat', false);
        }
    };

    render() {
        const { isShowModal } = this.state;
        return (
            <Modal className='chat-post-purchase-modal' show={isShowModal} backdrop={false} enforceFocus={false}>
                <Modal.Body>
                    <div id='user-chat-post-purchase-menu'>
                        <Row className='chat-purchase-container'>
                            <Col className='chat-purchase-wrapper'>
                                <Col className='chat-header-container'>
                                    <p>Chat</p> <span>with course instructors</span>
                                </Col>
                                <Col className='chat-subheader-container'>
                                    {`You now have access to chat with our expert course instructors. Whenever you're in the course, simply click the chat icon to speak with someone.`}
                                </Col>
                                <Col className='chat-image-menu-container'>
                                    <img src={process.env.PUBLIC_URL + '/post-chat-menus.png'} />
                                </Col>
                                <Col className='chat-image-container'>
                                    <img src={process.env.PUBLIC_URL + '/post-chat-student.png'} />
                                </Col>

                                <Col className='button-wrapper'>
                                    <Button className={`bp add-to-cart`} onClick={this.continueChat}>
                                        Continue
                                    </Button>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default withRouter(ChatPostPurchasePopOver);
