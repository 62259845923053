import React, { Component } from 'react';
import { IQuestion } from '../../Page/Inline/Inline.quiz';
import RadioAnswers from './RadioAnswers/RadioAnswers';

import './RadioQuestion.scss';

interface IProps {
    questionIndex: number;
    question: IQuestion;
    answer?: number;
    handleAnswer: (answer: number, question: number) => void;
    readOnly: boolean;
    revealAnswers: boolean;
    correct: boolean;
}

export default class RadioQuestion extends Component<IProps> {
    render() {
        const { question, answer, questionIndex, readOnly, revealAnswers } = this.props;

        return (
            <div className='radio-question'>
                <p>
                    {questionIndex + 1}. {question.title}
                </p>
                <div className='options'>
                    {question.options.map((option: any, index: number) => {
                        if (option.length > 0) {
                            return (
                                <RadioAnswers
                                    key={index}
                                    answerIndex={index}
                                    title={question.title}
                                    correctOptionIndex={question.correctOptionIndex}
                                    answer={answer}
                                    option={option}
                                    handleAnswer={this.props.handleAnswer}
                                    correctAnswer={this.props.correct}
                                    questionIndex={questionIndex}
                                    readOnly={readOnly}
                                    revealAnswers={revealAnswers}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}
