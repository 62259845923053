import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class ClarityScriptHelper extends Component {
    componentDidMount() {
        if (window.clarity && process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID) {
            const email = JSON.parse(localStorage.getItem('user')).email;
            window.clarity('set', 'email', email);
        }
    }

    render() {
        const clarityScriptId = process.env.REACT_APP_MICROSOFT_CLARITY_PROJECT_ID;
        if (clarityScriptId) {
            const clarityScript = `
                (function (c, l, a, r, i, t, y) {
                    c[a] =
                        c[a] ||
                        function () {
                            (c[a].q = c[a].q || []).push(arguments);
                        };
                    t = l.createElement(r);
                    t.async = 1;
                    t.src = 'https://www.clarity.ms/tag/' + i;
                    y = l.getElementsByTagName(r)[0];
                    y.parentNode.insertBefore(t, y);
                })(window, document, 'clarity', 'script', '${clarityScriptId}');
              `;

            return (
                <Helmet>
                    <script>{clarityScript}</script>
                </Helmet>
            );
        } else {
            return null;
        }
    }
}

export default ClarityScriptHelper;
