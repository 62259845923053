import React, { Component } from 'react';

interface IProps {
    title?: string;
    option?: string;
    handleAnswer: (answer: number, question: number) => void;
    answerIndex: number;
    answer?: number;
    correctAnswer?: boolean;
    questionIndex: number;
    readOnly: boolean;
    correctOptionIndex?: number;
    revealAnswers: boolean;
}

export default class RadioAnswers extends Component<IProps> {
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!this.props.readOnly) this.props.handleAnswer(parseInt(e.target.value), this.props.questionIndex);
    };

    get checked(): boolean {
        return this.props.answer === this.props.answerIndex;
    }

    get radioClass(): string {
        if (!this.checked || !this.props.readOnly) return '';
        else if (this.props.correctAnswer) return 'radio-correct';
        else return 'radio-incorrect';
    }

    getOption = (userAnswer: number | undefined, optionIndex: number, content: any) => {
        const correctOptionIndex = this.props.correctOptionIndex;
        let classes = '';

        const mainContent = (
            <span>
                <span className='option-content'>{content}</span>
            </span>
        );

        let updatedOption = <div className='option-content-wrapper'>{mainContent}</div>;

        if (this.props.readOnly) {
            if (optionIndex === userAnswer) {
                if (userAnswer === correctOptionIndex) {
                    classes = 'correct-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was correct</span>
                        </div>
                    );
                }

                if (userAnswer !== correctOptionIndex) {
                    classes = 'incorrect-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was incorrect</span>
                        </div>
                    );
                }
            }

            if (optionIndex === correctOptionIndex && optionIndex !== userAnswer && this.props.revealAnswers === true) {
                classes = 'reveal-correct-feedback';
                updatedOption = (
                    <div className='option-content-wrapper'>
                        {mainContent}
                        <span className='feedback-text'>The correct answer</span>
                    </div>
                );
            }
        }

        return { classes, updatedOption };
    };

    render() {
        const { option, title, answerIndex, questionIndex, readOnly, answer } = this.props;

        const opt = this.getOption(answer, answerIndex, option);

        return (
            <div
                className={`option ${opt.classes}`}
                onClick={(e) => {
                    if (!this.props.readOnly) this.props.handleAnswer(answerIndex, this.props.questionIndex);
                }}
            >
                {!readOnly || this.checked ? (
                    <input
                        type='radio'
                        name={`${questionIndex}-${title}`}
                        value={answerIndex}
                        id={`${questionIndex}-${answerIndex}`}
                        checked={this.checked}
                        onChange={this.handleInputChange}
                    />
                ) : (
                    <div className={`option empty-radio ${opt.classes}`}></div>
                )}

                <label htmlFor={`${questionIndex}-${answerIndex}`}>{opt.updatedOption}</label>
            </div>
        );
    }
}
