import React, { Component } from 'react';
import { ICustomCalendarLegendComponent } from 'src/components/_New/Navigation/Calendar';
import './QuizLegendComponents.scss';

export default class AnsweredQuestionLegend extends Component<ICustomCalendarLegendComponent> {
    render() {
        return (
            <div
                className={`quiz-calendar-legend green ${this.props.rule.active ? 'active' : 'inactive'}`}
                onClick={this.props.toggleLegend}
            >
                {!this.props.rule.active && <div className='inactive-overlay' />}
            </div>
        );
    }
}
