import React, { Component } from 'react';
import { ApiTable } from '../../../components/ApiTable';
import { faEye, faDownload } from '@fortawesome/free-solid-svg-icons';
import AccountContext, { IAccountContext } from '../AccountContext';
import withContext from 'src/helpers/withContext';
const fileDownload = require('js-file-download');
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';
import './ProfileCertificates.scss';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
interface IProps {
    contextValue: IAccountContext;
    loggedIn: {
        APP_VERSION: string;
        token: string;
        user: any;
    };
}
class ProfileCertificates extends Component<IProps> {
    static contextType = AccountContext;
    context!: React.ContextType<typeof AccountContext>;

    constructor(props: IProps) {
        super(props);
    }
    downloadCertificate = async (url: string, fileName: string) => {
        this.setState({ isLoading: true });
        axios
            .get(url, {
                responseType: 'blob',
            })
            .then((res: any) => {
                fileDownload(res.data, fileName);
            });
        this.setState({ isLoading: false });
    };

    render() {
        return (
            <section id='user-profile-certificates' className='user-profile-certificates'>
                <Row className='header-wrapper'>
                    <Col>
                        <h3>Your Certificates</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ApiTable
                            minTableWidth='992px'
                            noSearch={true}
                            basePath='/profile/details'
                            emptyMessage={"You don't have any certificates (yet)!"}
                            apiCall={{
                                method: 'GET',
                                path: `/users/${this.props.loggedIn.user._id}/certificateDetails`,
                            }}
                            columns={[
                                {
                                    text: 'Package',
                                    field: (row: any) => <span>{row.packageName}</span>,
                                    minWidth: '100px',
                                },
                                {
                                    text: 'Course',
                                    field: (row: any) => <span>{row.title}</span>,
                                    maxWidth: '30%',
                                    minWidth: '150px',
                                },
                                {
                                    text: 'Expires at',
                                    field: (row: any) => (
                                        <span>
                                            {row.certificateExpiresAt
                                                ? DateTimeFormatHelper.format(row.certificateExpiresAt)
                                                : '-'}
                                        </span>
                                    ),
                                },
                                {
                                    text: 'Passed at',
                                    field: (row: any) => <span>{DateTimeFormatHelper.format(row.passedAt)}</span>,
                                },
                            ]}
                            rowButtons={[
                                {
                                    type: 'button',
                                    text: 'View Certificate',
                                    icon: faEye,
                                    clickCallback: (e: React.MouseEvent<any>, doc: any, reload: () => void) => {
                                        window.open(
                                            Array.isArray(doc.certificateData.url)
                                                ? doc.certificateData.url[1]
                                                : doc.certificateData.url,
                                            '_blank',
                                        );
                                    },
                                },
                                {
                                    type: 'button',
                                    text: 'Download Certificate',
                                    icon: faDownload,
                                    clickCallback: (e: React.MouseEvent<any>, doc: any, reload: () => void) => {
                                        this.downloadCertificate(
                                            Array.isArray(doc.certificateData.url)
                                                ? doc.certificateData.url[1]
                                                : doc.certificateData.url,
                                            doc.certificateData.fileName,
                                        );
                                    },
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </section>
        );
    }
}

export default withRouterAndRedux(
    withContext(ProfileCertificates, AccountContext),
    (state: any) => ({
        loggedIn: state?.loggedIn,
    }),
    null,
);
