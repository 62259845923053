import React, { Component } from 'react';
import './Announcement.scss';
import { Modal, Button } from 'react-bootstrap';

interface IProps {
    announcement: IAnnouncementAlert;
}

export interface IAnnouncementAlert {
    id: string;
    type: 'info' | 'alert' | 'critical';
    heading?: string;
    duration?: number;
    announcement?: string;
    isDismissable: boolean;
    _id: string;
}

export interface IState {
    isShow: boolean;
    active: boolean;
}

export default class AnnouncementAlert extends Component<IProps, IState> {
    timePassed = 0;
    timeRemaining = 0;
    timer: ReturnType<typeof setInterval> | null = null;
    timeout: ReturnType<typeof setTimeout> | null = null;

    constructor(props: IProps) {
        super(props);

        this.timeRemaining = 10000;
        this.state = {
            isShow: false,
            active: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (this.props.announcement._id !== prevProps.announcement?._id) {
            this.timeRemaining = 10000;
            this.timePassed = 0;
            this.timer = null;
            this.timeout = null;
            this.startTimer();
            setTimeout(() => {
                this.setState({ active: true });
            }, 0);
            this.startTimeout();
        }
    }

    toggle = () => {
        this.setState((prevState: IState) => ({
            isShow: !prevState.isShow,
        }));
    };

    async componentDidMount() {
        this.startTimer();
        setTimeout(() => {
            this.setState({ active: true });
        }, 0);
        this.startTimeout();
    }

    startTimer = (): void => {
        this.timer = setInterval(() => {
            this.timePassed = this.timePassed + 100;
        }, 100);
    };

    startTimeout = (): void => {
        this.timeout = setTimeout(() => {
            this.setState({ active: false, isShow: false });
        }, this.timeRemaining);
    };

    alertMessage = (message = ''): string => {
        if (message.length > 100) {
            return `${message.substring(0, 100)} ...`;
        } else {
            return message;
        }
    };

    render() {
        const { type, announcement, heading } = this.props.announcement;

        if (!this.state.active) {
            return null;
        }

        return (
            <>
                <div
                    onClick={this.toggle}
                    className={`popup-toast ${this.state.active ? ' active' : ''} popup-toast--${type}`}
                >
                    <div className={`icon icon--${type}`}>
                        {type === 'critical' ? (
                            <i className='fa fa-triangle-exclamation' />
                        ) : (
                            <i className='fa fa-circle-info' />
                        )}
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: this.alertMessage(heading || announcement) }}></span>
                </div>

                {announcement && (
                    <Modal show={this.state.isShow} onHide={this.toggle}>
                        <Modal.Body>{<span dangerouslySetInnerHTML={{ __html: announcement }}></span>}</Modal.Body>
                        <Modal.Footer>
                            <Button className='bp' onClick={this.toggle}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </>
        );
    }
}
