import React, { Component } from 'react';
import EssayPage, { IEssayPageProps } from './Page/Essay.page';
import CourseContext from 'src/pages/Course/CourseContext';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import './Page/Essay.page.scss';

type TProps = IEssayPageProps & RouteComponentProps;
class Essay extends Component<TProps> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    renderCard() {
        switch (this.context.course.lessonType) {
            case 'page':
                return <EssayPage {...this.props} courseId={this.context?.course?._id} />;
            case 'slide':
                return <b>Card not supported - Essay</b>;
            default:
                return <></>;
        }
    }

    render() {
        if (!this.context.course) return null;
        const { lessonType } = this.context.course;

        return (
            <div className={`essay-${lessonType} theme-${(this.props.theme ?? '').toLowerCase()}`}>
                {this.renderCard()}
            </div>
        );
    }
}

export default withRouter(Essay);
