import React, { Component } from 'react';
import apiFile from '../../helpers/apiFile';
import './FileFrame.scss';
import * as pdfjs from 'pdfjs-dist';
import PdfViewer from './PdfViewer/PdfViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default class FileIFrame extends Component {
    state = {
        fileUrl: '',
    };
    onIframeRef = React.createRef();

    async componentDidMount() {
        const { fileId } = this.props;

        if (fileId instanceof File) {
            this.setState({
                fileUrl: URL.createObjectURL(fileId),
            });
        } else {
            const { url } = await apiFile(fileId);
            this.setState({
                fileUrl: url,
            });
        }
    }

    render() {
        const { fileUrl } = this.state;

        return fileUrl ? (
            <>
                <div
                    onMouseMove={this.props.inactiveTimerCallback}
                    onClick={this.props.inactiveTimerCallback}
                    onKeyPress={this.props.inactiveTimerCallback}
                    onWheel={this.props.inactiveTimerCallback}
                    className='pdf-container'
                >
                    <PdfViewer fileUrl={fileUrl} />
                </div>
            </>
        ) : (
            <></>
        );
    }
}
