import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import CourseManager from 'src/pages/Course/CourseManager';
import { Lessons } from 'src/pages/Course/Stages';
import { Spinner } from 'src/components/Spinner';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';

interface IRouteProps {
    courseId: string;
    chapterId?: string;
    lessonId?: string;

    //TODO Set into the location search
    // location: {
    //     search: {
    //         chapterId: string;
    //         lessonId: string;
    //     };
    // };
}

interface IState {
    chapters: IChapterLesson;
    lessons: IChapterLesson;
    isLoading: boolean;
    chapterId: string;
    lessonId: string;
}

interface IChapterLesson {
    [key: string]: string;
}

interface IUserChapter {
    _id: string;
    courseChapterId: string;
}

interface IUserLesson {
    _id: string;
    courseLessonId: string;
}
class PreviewCourse extends Component<RouteComponentProps<IRouteProps>, IState> {
    state: IState = {
        chapters: {},
        lessons: {},
        isLoading: true,
        chapterId: '',
        lessonId: '',
    };

    async componentDidMount(): Promise<void> {
        this.setState({ isLoading: true });
        EventBus.onWindow('message', this.handleWindowMessage);
        await this.loadIdMap();
    }

    componentWillUnmount(): void {
        EventBus.removeFromWindow('message', this.handleWindowMessage);
    }

    loadIdMap = async () => {
        const { courseId } = this.props.params;
        const chapterId = this.props.params?.chapterId;
        const lessonId = this.props.params?.lessonId;
        const { success, response } = await Api.call('get', `/users/courses/${courseId}/preview/map`);

        if (success) {
            const chapters = response.userChapters.reduce((acc: IChapterLesson, userChapter: IUserChapter) => {
                return { ...acc, [userChapter.courseChapterId]: userChapter._id };
            }, {});
            const lessons = response.userLessons.reduce((acc: IChapterLesson, userLesson: IUserLesson) => {
                return { ...acc, [userLesson.courseLessonId]: userLesson._id };
            }, {});
            if (chapterId && lessonId) {
                this.setState({
                    chapters,
                    lessons,
                    isLoading: false,
                    chapterId: chapters[chapterId],
                    lessonId: lessons[lessonId],
                });
            } else {
                this.setState({
                    chapters,
                    lessons,
                    isLoading: false,
                });
            }
        }
    };

    handleWindowMessage = (event: MessageEvent) => {
        if (event.origin !== process.env.REACT_APP_URL) return;
        if (!event.data.updateData) return;

        const { updateTarget, updateData } = event.data;
        updateData.lessonId = this.state.lessons[updateData.lessonId];

        if (updateTarget === 'card') {
            EventBus.dispatch('update-admin-preview-card', updateData);
        } else if (updateTarget === 'lesson') {
            updateData.chapterId = this.state.chapters[updateData.chapterId];
            EventBus.dispatch('update-admin-preview-lesson', updateData);
        } else if (updateTarget === 'sync') {
            updateData.chapterId = this.state.chapters[updateData.chapterId];
            EventBus.dispatch('admin-preview-change-lesson', updateData, 0);
        }
    };

    handleCourseLoad = (course: any) => {
        const { chapterId, lessonId } = this.state;
        const userLessonId = this.props.params?.lessonId;
        const userChapterId = this.props.params?.chapterId;
        if (chapterId || lessonId) {
            this.props.navigate(`/preview/${course._id}/chapters/${chapterId}/lessons/${lessonId}`, {
                replace: true,
            });
        } else {
            this.props.navigate(`/preview/${course._id}/chapters/${userChapterId}/lessons/${userLessonId}`, {
                replace: true,
            });
        }
    };

    render() {
        const { isLoading } = this.state;
        if (isLoading) return <Spinner />;
        return (
            <CourseManager courseId={this.props.params.courseId} onCourseLoad={this.handleCourseLoad}>
                <Lessons />
            </CourseManager>
        );
    }
}

export default withRouter(PreviewCourse);
