import { Api } from './new';

const apiFile = async (fileId) => {
    if (fileId) {
        const { success, response } = await Api.call('GET', `/files/${fileId}`);

        if (success) {
            let { url, fileName } = response;

            if (Array.isArray(url)) {
                url = url[1];
            }

            return { url, fileName };
        }
    }

    return { url: '', fileName: '' };
};

export default apiFile;

export const apiFileFullResponse = async (fileId) => {
    if (fileId) {
        const { success, response } = await Api.call('GET', `/files/${fileId}`);

        return success ? response : null;
    } else {
        return null;
    }
};
