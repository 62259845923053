import React, { Component } from 'react';
import { RootState } from 'src/store/reducers/rootReducer';
import { PackageList } from './packages';
import { Banner } from './Banner';
import './Home.scss';
import { UpsellsList } from './Upsells';
import ExtensionRepurchase from '../Checkout/ExtensionRepurchase';
import UpsellCartButton from './Upsells/Cart/UpsellCartButton';
import { Api, EventBus } from 'src/helpers/new';
import { getState, setState } from 'src/helpers/localStorage';
import { RouteComponentProps } from 'src/hoc/withRouter';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IConnectProps {
    loggedIn: any;
}

export interface ICartDetail {
    courses: any[];
    itemsCount: number;
    packages: any[];
    pkgIds: string[];
    _id: string;
}

interface IRouteProps {
    userPackageId: string;
}

type TProps = RouteComponentProps<IRouteProps> & IConnectProps;

interface IState {
    packages: any;
    cartDetails: ICartDetail;
    isLoading: boolean;
    isModalOpen: boolean;
}

class Home extends Component<TProps, IState> {
    state: IState = {
        packages: null,
        isLoading: false,
        cartDetails: {
            courses: [],
            itemsCount: 0,
            packages: [],
            pkgIds: [],
            _id: '',
        },
        isModalOpen: !!this.props?.params?.userPackageId,
    };

    componentDidMount() {
        EventBus.dispatch('show-exam-banner', { isResume: null });
        this.loadCarts();
    }

    componentDidUpdate(prevProps: TProps) {
        if (this.props?.params?.userPackageId !== prevProps?.params?.userPackageId) {
            this.setState({
                isModalOpen: !!this.props?.params?.userPackageId,
            });
        }
    }

    loadCarts = async () => {
        this.setState({ isLoading: true });
        const cartId = localStorage.getItem('reuCheckoutCartId');
        const id = getState('user')?._id ? getState('user')?._id : cartId;
        if (id) {
            const { success, response } = await Api.call(
                'GET',
                `checkout/cart/${id}/dashboard?isLoggedIn=${!!getState('user')?._id}`,
            );
            if (success) {
                if (response.isCartFound) {
                    if (response.cartDetails._id) {
                        setState('reuCheckoutCartId', response.cartDetails._id);
                    }
                    this.setState({
                        cartDetails: response.cartDetails,
                        isLoading: false,
                    });
                } else {
                    localStorage.removeItem('reuCheckoutCartId');
                }
            }
        }
    };

    updateCartDetails = (data: any) => {
        this.setState({
            cartDetails: { ...this.state.cartDetails, ...data },
        });
    };

    handleCloseModal = () => {
        this.setState({ isModalOpen: false });
        this.props.navigate('/', { replace: true });
    };

    render() {
        const { loggedIn } = this.props;

        return (
            <div className='home'>
                <Banner channel={'dashboard'} />
                <div className='package-upsells'>
                    <PackageList userId={loggedIn.user._id} />
                    <UpsellsList
                        userId={loggedIn.user._id}
                        cartDetails={this.state.cartDetails}
                        loadCarts={this.loadCarts}
                        updateCartDetails={this.updateCartDetails}
                    />
                </div>

                <div className=''>
                    {this.state.isModalOpen && (
                        <ExtensionRepurchase
                            navigate={this.props.navigate}
                            userPackageId={this.props.params?.userPackageId}
                            onClose={this.handleCloseModal}
                        />
                    )}
                </div>
                {!this.state.isLoading && <UpsellCartButton cartDetails={this.state.cartDetails} />}
            </div>
        );
    }
}

export default withRouterAndRedux(
    Home,
    (state: RootState) => {
        return {
            loggedIn: state.loggedIn,
        };
    },
    null,
);
