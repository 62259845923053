import React, { Component } from 'react';
import { IQuizAttempt } from 'src/pages/Course/Stages/Lessons/LessonTypes/Slide/Quiz/Quiz';
import Attempt from './Attempt';
import './PreviousAttempts.scss';

interface IProps {
    quizAttempts: IQuizAttempt[];
    cardId?: string;
    cardIndex: number;
}

interface IState {
    attempts: IQuizAttempt[];
}
export default class PreviousAttempts extends Component<IProps, IState> {
    state: IState = {
        attempts: [],
    };

    componentDidMount(): void {
        const attempts = this.props.quizAttempts.reverse();
        this.setState({ attempts: attempts });
    }
    render() {
        const { attempts } = this.state;
        return (
            <div className='quiz-previous-attempts'>
                <h5>Previous attempts</h5>
                {attempts?.map((attempt: IQuizAttempt, index: number) => {
                    return (
                        <Attempt
                            attempt={attempt}
                            key={index}
                            cardId={this.props.cardId}
                            cardIndex={this.props.cardIndex}
                        />
                    );
                })}
            </div>
        );
    }
}
