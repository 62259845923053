import React, { Component } from 'react';

interface IProps {
    onClick: () => void;
}

interface IState {}

export type TProps = IProps;

class RetryButton extends Component<TProps, IState> {
    render() {
        return (
            <div className={`lesson-button`} onClick={this.props.onClick}>
                Retry
            </div>
        );
    }
}

export default RetryButton;
