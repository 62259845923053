import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { Spinner } from 'src/components/Spinner';
import { Api, EventBus } from 'src/helpers/new';
import CourseContext from 'src/pages/Course/CourseContext';
import './BioSig.scss';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface IProps {
    stage: string;
    isCheckPoint?: boolean;
    isResuming?: boolean;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

interface IState {
    isLoading: boolean;
    targetUrl: string;
    isIFrameContentLoaded: boolean;
}

class BioSig extends Component<TProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    state: IState = {
        isLoading: true,
        targetUrl: '',
        isIFrameContentLoaded: false,
    };

    componentDidMount() {
        this.initialiseAuthentication();
    }

    getBiosigD3ValueAndDBValue = (stage: string) => {
        let nmlsStag = 'FinalExam';
        let nmlsStagToStoreInDB = '';
        const currentNmlsStage = this.context.course.nmlsStag;
        const bioSight = this.context.course.proctoringSettings.bioSight ?? 'none';

        if (!currentNmlsStage) {
            nmlsStag = 'Begin';
            nmlsStagToStoreInDB = `Begin`;
        } else {
            if (stage === 'enrollment') {
                nmlsStag = 'Begin';
                nmlsStagToStoreInDB = 'Begin';
            } else if (['beforeExam', 'postExam', 'preExam'].includes(stage)) {
                nmlsStag = 'FinalExam';
                nmlsStagToStoreInDB = 'FinalExam';

                if (currentNmlsStage === 'FinalExam') {
                    nmlsStag = 'Resuming-FinalExam';
                    nmlsStagToStoreInDB = 'FinalExam';
                }
            } else if (this.props.isResuming) {
                if (parseInt(currentNmlsStage).toString() != 'NaN') {
                    nmlsStag = `Resuming-Middle#${parseInt(currentNmlsStage)}`;
                    nmlsStagToStoreInDB = `${parseInt(currentNmlsStage)}`;
                } else {
                    nmlsStag = `Resuming-${currentNmlsStage}`;
                    nmlsStagToStoreInDB = `${currentNmlsStage}`;
                }
            } else {
                if (currentNmlsStage === 'Begin') {
                    nmlsStag = 'Middle#1';
                    nmlsStagToStoreInDB = `1`;
                } else {
                    if (parseInt(currentNmlsStage).toString() != 'NaN') {
                        if (parseInt(currentNmlsStage) <= 2 || bioSight !== 'biosig-nmls') {
                            nmlsStag = `Middle#${parseInt(currentNmlsStage) + 1}`;
                            nmlsStagToStoreInDB = `${parseInt(currentNmlsStage) + 1}`;
                        } else {
                            nmlsStag = `Middle#3`;
                            nmlsStagToStoreInDB = `3`;
                        }
                    } else {
                        nmlsStag = `Resuming-${currentNmlsStage}`;
                        nmlsStagToStoreInDB = `${currentNmlsStage}`;
                    }
                }
            }
        }

        return { nmlsStag, nmlsStagToStoreInDB };
    };

    initialiseAuthentication = async () => {
        const socket = window.socket as any;

        const proctoringSettings = this.context.course.proctoringSettings;
        let endpoint = 'proctoring/verify/init';
        const apiData: any = {
            courseId: this.context.course._id,
            id: this.props.stage,
            isExam: this.props.stage === 'exam' ? true : undefined,
            socketId: socket.id,
            courseTitle: this.context.course?.title,
            isResuming: this.props.isResuming,
            mainCourseId: this.context.course.courseId,
        };

        const { nmlsStag, nmlsStagToStoreInDB } = this.getBiosigD3ValueAndDBValue(this.props.stage);

        apiData.nmlsCourseDuration = proctoringSettings.nmlsCourseDuration ?? 1;
        apiData.nmlsStag = encodeURIComponent(nmlsStag);
        apiData.nmlsStagToStoreInDB = nmlsStagToStoreInDB;

        if (proctoringSettings.bioSight === 'biosig-nmls') {
            console.log('initialise nmls - biosig authentication');
            endpoint = 'proctoring/verify/init/nmls';
            apiData.nmlsCourseId = proctoringSettings.nmlsCourseId;
        }

        if (proctoringSettings.bioSight === 'biosig-adre') {
            console.log('initialise adre - biosig authentication');
            endpoint = 'proctoring/verify/init/adre';
        }

        const { response, raw } = await Api.call('get', endpoint, null, {
            ...apiData,
        });

        if (response.status === 'Success') {
            this.setState({ isLoading: false, targetUrl: response.redirect });
        } else {
            console.log('init failed - ', response, raw);
            this.props.navigate('/', { replace: true });
            if (response?.customisedMessage?.code) {
                EventBus.dispatch('alert', {
                    type: 'error',
                    title: `${response?.customisedMessage?.title} (${response?.customisedMessage?.code})`,
                    message: `${response?.customisedMessage?.description}`,
                    duration: 15000,
                    displayType: 'announcement',
                });
            }
        }
    };

    render() {
        const { isLoading, targetUrl, isIFrameContentLoaded } = this.state;

        if (isLoading) return <Spinner />;

        return (
            <div className={`bio-sig-auth`}>
                {!isIFrameContentLoaded && <Spinner />}
                <iframe
                    className={!isIFrameContentLoaded ? 'hidden' : 'visible'}
                    src={targetUrl}
                    onLoad={() => {
                        this.setState({
                            isIFrameContentLoaded: true,
                        });
                    }}
                />
            </div>
        );
    }
}

export default withRouter(BioSig);
