export const thoughtMetricScript = () => {
    if (process.env.REACT_APP_THOUGHT_METRIC_TRACKING_ID) {
        !(function (t, e, i) {
            if (!t.thoughtmetric) {
                t.thoughtmetricQueue = t.thoughtmetricQueue || [];
                t.thoughtmetric =
                    t.thoughtmetric ||
                    function () {
                        t.thoughtmetricQueue.push(arguments);
                    };
                const u = e.createElement('script');
                u.async = !0;
                u.src = 'https://pixel.thoughtmetric.io/tmpixel.min.js';
                const h = e.getElementsByTagName('script')[0];
                h.parentNode.insertBefore(u, h);
            }
        })(window, document);
        window.thoughtmetric('init', process.env.REACT_APP_THOUGHT_METRIC_TRACKING_ID);
        if (process.env.REACT_APP_THOUGHT_METRIC_PROXY_URL) {
            window.thoughtmetric('proxy', process.env.REACT_APP_THOUGHT_METRIC_PROXY_URL);
        }
    }
};

export const facebookPixelScript = () => {
    if (process.env.REACT_APP_FACEBOOK_TRACKING_ID) {
        !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ? n.callMethod(...arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', process.env.REACT_APP_FACEBOOK_TRACKING_ID);
    }
};

export const googleAdsScript = () => {
    if (process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID}`;
        script.async = true;
        script.onload = function () {
            window.dataLayer = window.dataLayer || [];
            function googleAdsTag() {
                dataLayer.push(arguments);
            }
            window.googleAdsTag = googleAdsTag;
            googleAdsTag('js', new Date());
            googleAdsTag('config', process.env.REACT_APP_GOOGLE_ADS_TRACKING_ID, { allow_enhanced_conversions: true });
        };
        document.head.appendChild(script);
    }
};

export const googleAnalyticsScript = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
        script.async = true;
        script.onload = function () {
            window.dataLayer = window.dataLayer || [];
            function googleAnalyticsTag() {
                dataLayer.push(arguments);
            }
            window.googleAnalyticsTag = googleAnalyticsTag;
            googleAnalyticsTag('js', new Date());
            googleAnalyticsTag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
        };
        document.head.appendChild(script);
    }
};

export const bingScript = () => {
    if (process.env.REACT_APP_BING_TRACKING_ID) {
        (function (w, d, t, r, u) {
            w[u] = w[u] || [];
            const f = function () {
                const o = { ti: process.env.REACT_APP_BING_TRACKING_ID, enableAutoSpaTracking: true };
                o.q = w[u];
                w[u] = new UET(o);
                w[u].push('pageLoad');
            };
            const n = d.createElement(t);
            n.src = r;
            n.async = 1;
            n.onload = n.onreadystatechange = function () {
                const s = this.readyState;
                if (!s || s === 'loaded' || s === 'complete') {
                    f();
                    n.onload = n.onreadystatechange = null;
                }
            };
            const i = d.getElementsByTagName(t)[0];
            i.parentNode.insertBefore(n, i);
        })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
    }
};

export const loadBiosightScript = () => {
    const scriptUrl = process.env.REACT_APP_BIOSIGHT_SCRIPT_URL
        ? process.env.REACT_APP_BIOSIGHT_SCRIPT_URL
        : 'https://staging-sandbox.verifyexpress.com/js/biosightclient.js';

    if (scriptUrl) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;
        script.onload = function () {
            console.log('------Biosight Script Loaded-----');
        };
        document.head.appendChild(script);
    }
};
