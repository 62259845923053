import React, { Component } from 'react';
import './FillGapQuestion.scss';

interface IProps {
    question?: any;
    questionIndex: number;
    handleAnswer: (answer: number, questionIndex: number) => void;
    answer?: number;
    readOnly: boolean;
    revealAnswers: boolean;
    correct: boolean;
}

export default class FillGapQuestion extends Component<IProps> {
    handleAnswer = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.handleAnswer(parseInt(e.target.value), this.props.questionIndex);
    };

    getOption = (userAnswer: number | undefined, index: number, content: any, optionIndex: number) => {
        const correctOptionIndex = this.props.question.correctOptionIndex;
        let classes = '';

        const mainContent = (
            <span>
                <span className='option-index'>
                    <b>{optionIndex}. </b>
                </span>
                <span className='option-content'>{content}</span>
            </span>
        );

        let updatedOption = <div className='option-content-wrapper'>{mainContent}</div>;

        if (this.props.readOnly) {
            if (index === userAnswer) {
                if (userAnswer === correctOptionIndex) {
                    classes = 'correct-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was correct</span>
                        </div>
                    );
                }

                if (userAnswer !== correctOptionIndex) {
                    classes = 'incorrect-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            {mainContent}
                            <span className='feedback-text'>Your answer was incorrect</span>
                        </div>
                    );
                }
            }

            if (index === correctOptionIndex && index !== userAnswer && this.props.revealAnswers === true) {
                classes = 'reveal-correct-feedback';
                updatedOption = (
                    <div className='option-content-wrapper'>
                        {mainContent}
                        <span className='feedback-text'>The correct answer</span>
                    </div>
                );
            }
        }

        return { classes, updatedOption };
    };

    render() {
        const { question, questionIndex, answer, readOnly } = this.props;
        let components = [],
            optionIndex = 1;

        if (readOnly) {
            components = [
                <React.Fragment key={`question`}>
                    {questionIndex + 1}. {question.title}
                </React.Fragment>,
            ];
        } else {
            components = question.title.split('___').reduce((acc: [any], questionSection: any, index: number) => {
                if (index === 0) acc.push(`${questionIndex + 1}. `);

                acc.push(<React.Fragment key={`question-part-${index}`}>{questionSection}</React.Fragment>);

                if (index !== 0) return acc;

                acc.push(
                    <select key={'question-select'} onChange={this.handleAnswer} value={answer}>
                        <option value={-1}></option>
                        {question.options.map((option: any, index: number) => {
                            if (option.length > 0) {
                                return (
                                    <option key={index} value={index}>
                                        {option}
                                    </option>
                                );
                            }
                        })}
                    </select>,
                );

                return acc;
            }, []);
        }

        return (
            <div className={`fill-gap-question`}>
                <div className='question'>{components}</div>

                {this.props.readOnly && (
                    <>
                        <div className='options'>
                            {this.props.question.options.map((option: any, index: number) => {
                                if (option.length > 0) {
                                    const opt = this.getOption(this.props.answer, index, option, optionIndex);
                                    optionIndex++;
                                    return (
                                        <div key={index} className={`option ${opt.classes}`}>
                                            {opt.updatedOption}
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </>
                )}
            </div>
        );
    }
}
