import React, { Component } from 'react';
import { ICustomCalendarLegendComponent } from 'src/components/_New/Navigation/Calendar';
import './LegendComponents.scss';

export default class PendingReviewLegend extends Component<ICustomCalendarLegendComponent> {
    render() {
        return (
            <div
                className={`slide-calendar-legend ${this.props.rule.active ? 'active' : 'inactive'}`}
                onClick={this.props.toggleLegend}
            >
                {!this.props.rule.active && <div className='inactive-overlay' />}
                <i className='fa fa-graduation-cap' />
            </div>
        );
    }
}
