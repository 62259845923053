import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import './profileCommissions.scss';

export default class ProfileCommissions extends Component {
    render() {
        return (
            <section id='user-profile-commissions' className='user-profile-commissions'>
                <Row className='header-wrapper'>
                    <Col>
                        <h3>Your Commissions</h3>
                    </Col>
                </Row>
            </section>
        );
    }
}
