import React, { Component } from 'react';
import Header from './Header';
import Discount from './Discount/Discount';
import UpsellCard, { IUpsell } from 'src/pages/Home/Upsells/UpsellCard';
import './FrequentlyBoughtTogether/FBT.scss';
import { Api, Utility } from 'src/helpers/new';
import { isEmpty } from 'lodash';
import StudentPreviewControls from 'src/pages/Course/Stages/Lessons/StudentPreviewController/StudentPreviewControls';
import {
    facebookPixelScript,
    thoughtMetricScript,
    googleAdsScript,
    googleAnalyticsScript,
    bingScript,
} from 'src/helpers/externalScript';
import './Confirmation.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import PaymentFail from './PaymentFail';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
interface IRouteProps {
    match: {
        params: { orderId: string };
    };
}
interface IProps extends IRouteProps {
    userId: string;
}

interface ICourse {
    purchaseType: string;
    userCourseId: string;
}
interface IState {
    isTransactionFailed?: boolean;
    errorCode?: string;
    errorMessage?: string;

    upsells: IUpsell[];
    isShowAccessCourse: boolean;
    loginToken: any;
    pollingFailedMessage: string;
    packages: any[];
    purchasedPackage: string;
    isLoading: boolean;
    thoughtMetricSurvey: boolean;
    processProgress: number;
    courses: ICourse[];
}
class Confirmation extends Component<IProps & RouteComponentProps, IState> {
    pollInterval: any = null;

    state: IState = {
        upsells: [],
        isShowAccessCourse: false,
        loginToken: {},
        pollingFailedMessage: '',
        packages: [],
        purchasedPackage: '',
        isLoading: false,
        courses: [],
        thoughtMetricSurvey: true,
        processProgress: 10 * Utility.getRandomVal(),
    };

    get isAdminPreview() {
        const query = new URLSearchParams(this.props.location.pathname);
        return Boolean(query.get('isAdminPreview') ?? false);
    }

    async componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);
        const adminPreviewQuery = new URLSearchParams(this.props.location.pathname);

        if (this.isAdminPreview) {
            this.handleAdminPreview(adminPreviewQuery);
        } else {
            this.loadExternalScripts();
            const isTotalZero = query.get('isTotalZero');
            if (isTotalZero) {
                await this.handleTotalZero(query);
            } else {
                await this.handleRegularFlow(query);
            }
        }
    }

    async componentWillUnmount() {
        if (this.pollInterval) {
            clearInterval(this.pollInterval);
            this.pollInterval = null;
        }
    }

    handleAdminPreview = (query: any) => {
        const packages = query.get('packages');
        const title = query.get('title') ?? '';
        this.setState({ purchasedPackage: title });
        this.loadUpsells(packages);
    };

    loadExternalScripts = () => {
        if (!window.thoughtmetric) {
            thoughtMetricScript();
        }
        if (!window.fbq) {
            facebookPixelScript();
        }
        if (!window.googleAdsTag) {
            googleAdsScript();
        }
        if (!window.googleAnalyticsTag) {
            googleAnalyticsScript();
        }
        if (!window.uetq) {
            bingScript();
        }
    };

    handleTotalZero = async (query: any) => {
        const packages = query.get('packages');
        const response = await this.getOrderDetails();
        this.removeLocalStorage();
        this.handleOrderEvent(response, packages);

        const userOrdersData = await Api.call('GET', `/users/orders/${response?.userId}`);
        if (userOrdersData) {
            this.identifyEvent(userOrdersData);
        }

        this.setState({
            isShowAccessCourse: true,
            loginToken: this.props.location.state,
        });
    };

    handleRegularFlow = async (query: any) => {
        this.setState({ isLoading: true });
        const packages = query.get('packages');
        const response = await this.getOrderDetails();

        if (response.status === 'Failed') {
            this.setState({
                isLoading: false,
                isTransactionFailed: true,
                errorMessage: response?.payments[0] ? response?.payments[0].errorMessage : 'Unknown',
                errorCode: response?.payments[0] ? response?.payments[0].erroCode : 'Unknown',
            });
        } else {
            this.pollLoginTokenApi();
            this.removeLocalStorage();
            this.handleOrderEvent(response, packages);
        }
    };

    getOrderDetails = async () => {
        const { orderId } = this.props.params;
        const { response } = await Api.call('get', `orders/${orderId}`);

        return response;
    };

    removeLocalStorage = () => {
        localStorage.removeItem('reuCheckoutCartId');
        localStorage.removeItem('__paypal_storage__');
    };

    handleOrderEvent = async (response: any, packages: any) => {
        if (response) {
            this.orderEvent(response);
        }
        if (response && response.packages?.length > 0 && response.packages[0]._id) {
            await this.loadUpsells(packages);
            this.setState({
                packages: response.packages,
                courses: response.courses,
                purchasedPackage: response.packages[0].title,
            });
        } else if (response && response.courses?.length > 0) {
            this.setState({
                courses: response.courses,
            });
        }
    };

    checkThoughtMetricSurvey = (value: boolean) => {
        this.setState({ thoughtMetricSurvey: value });
    };

    loadUpsells = async (packages: any): Promise<void> => {
        const { success, response } = await Api.call('GET', `users/upsells`, null, {
            location: 'thankYou',
            packages: packages,
        });
        if (success) {
            this.setState({
                upsells: response,
            });
        }
    };

    pollLoginTokenApi = () => {
        let isSuccess = false;
        if (this.state.processProgress <= 90) {
            this.setState((prevState) => ({
                processProgress: prevState.processProgress + 10 * Utility.getRandomVal(),
            }));
        }

        this.pollInterval = setInterval(async () => {
            const { success, response } = await Api.call(
                'GET',
                `/users/orders/loginToken/${this.props.params.orderId}`,
            );

            if (
                !success &&
                !isEmpty(response) &&
                response.status === 'Failed' &&
                response.erroCode &&
                response.errorMessage
            ) {
                this.setState({
                    isShowAccessCourse: false,
                    isTransactionFailed: true,
                    errorCode: response.errorCode,
                    errorMessage: response.errorMessage,
                });
                clearInterval(this.pollInterval);
                this.pollInterval = null;
                return;
            }

            if (success && !isEmpty(response)) {
                isSuccess = success;
                this.setState({ processProgress: 100 });
                const userOrdersData = await Api.call('GET', `/users/orders/${response?.userId}`);
                if (userOrdersData) {
                    this.identifyEvent(userOrdersData);
                }
                this.setState({ isShowAccessCourse: isSuccess, loginToken: response, isLoading: false });
                clearInterval(this.pollInterval);
                this.pollInterval = null;
                return false;
            } else {
                clearInterval(this.pollInterval);
                this.pollInterval = null;
                this.pollLoginTokenApi();
            }
        }, 3000);
    };

    orderEvent = (response: any) => {
        const { _id, totalValue, totalTaxValue, couponCode, packages, courseDetails } = response;
        const { email, firstName, lastName } = response?.contact || {};
        const { streetLines, town, state, zipCode } = response?.billingAddress || {};

        const totalDiscount = packages.reduce((value: any, pkg: any) => {
            return value + pkg.discount;
        }, 0);
        const thoughtMetricPackageList = packages
            .filter((pkg: any) => pkg.hasOwnProperty('title'))
            .map((pkg: any) => {
                const { title, price, discount } = pkg;

                return { product_name: title, quantity: 1, unit_price: price - discount };
            });

        courseDetails?.coursesDetailsForThougthmetric?.map((course: any) => {
            const { product_name, quantity, unit_price } = course;

            thoughtMetricPackageList.push({
                product_name: product_name,
                quantity: quantity,
                unit_price: unit_price,
            });
        });

        const googlePackageList = packages
            .filter((pkg: any) => pkg.hasOwnProperty('title'))
            .map((pkg: any) => {
                const { packageId, title, discount, state, division, price } = pkg;

                return {
                    item_id: packageId,
                    item_name: title,
                    discount: discount,
                    item_brand: 'RealEstateU',
                    item_category: state,
                    item_category2: division,
                    price: price - discount,
                    quantity: 1,
                };
            });

        courseDetails?.coursesDetailsForGoogle?.map((course: any) => {
            const { packageId, title, purchaseType, state, division, price, extensionDetail } = course;

            googlePackageList.push({
                item_id: packageId,
                item_name:
                    purchaseType === 'extension'
                        ? `${title} (${extensionDetail?.time} days ${purchaseType})`
                        : `${title} (${purchaseType})`,
                discount: null,
                item_brand: 'RealEstateU',
                item_category: state,
                item_category2: division,
                price: price,
                quantity: 1,
            });
        });

        if (window.thoughtmetric && typeof window.thoughtmetric === 'function') {
            window.thoughtmetric('event', 'order', {
                transaction_id: _id,
                total_price: totalValue,
                currency: 'USD',
                orderCurrency: 'USD',
                subtotal_price: totalValue - totalTaxValue,
                total_tax: totalTaxValue,
                total_discounts: totalDiscount,
                discount_codes: [couponCode],
                items: thoughtMetricPackageList,
            });
        }

        if (window.fbq && typeof window.fbq === 'function') {
            window.fbq('track', 'Purchase', { value: totalValue, currency: 'USD' });
        }

        if (window.googleAdsTag) {
            window.googleAdsTag('set', 'user_data', {
                email: email?.toLowerCase()?.trim() ?? '',
                address: {
                    first_name: firstName?.toLowerCase()?.trim() ?? '',
                    last_name: lastName?.toLowerCase()?.trim() ?? '',
                    street: streetLines[0]?.toLowerCase()?.trim() ?? '',
                    city: town?.toLowerCase()?.trim() ?? '',
                    region: state?.toLowerCase()?.trim() ?? '',
                    postal_code: zipCode?.toLowerCase()?.trim() ?? '',
                    country: 'us',
                },
            });

            window.googleAdsTag('event', 'conversion', {
                send_to: process.env.REACT_APP_GOOGLE_SEND_TO_ID,
                value: totalValue,
                currency: 'USD',
                transaction_id: _id,
            });
        }

        if (window.googleAnalyticsTag) {
            window.googleAnalyticsTag('event', 'purchase', {
                transaction_id: _id,
                value: totalValue,
                tax: totalTaxValue,
                currency: 'USD',
                coupon: couponCode,
                items: googlePackageList,
            });
        }

        if (window.uetq) {
            window.uetq = window.uetq || [];
            window.uetq.push('event', 'purchase', { revenue_value: totalValue, currency: 'USD' });
        }
    };

    identifyEvent = (userOrdersData: any) => {
        const { _id, email, orders_count, total_spent, created_at, first_name, last_name, phone } =
            userOrdersData.response;
        if (window.thoughtmetric && typeof window.thoughtmetric === 'function') {
            window.thoughtmetric('identify', _id, {
                email: email,
                created_at: created_at,
                total_spent: total_spent,
                orders_count: orders_count,
                first_name: first_name,
                last_name: last_name,
                phone: phone,
            });
        }
    };

    render() {
        const {
            upsells,
            isShowAccessCourse,
            loginToken,
            pollingFailedMessage,
            purchasedPackage,
            isLoading,
            thoughtMetricSurvey,
            processProgress,
            packages,
            courses,
            isTransactionFailed = false,
        } = this.state;

        const isDisplaySurvey = !(
            courses.every((value: ICourse) => value.purchaseType === 'chat') && isEmpty(packages)
        );

        const isRedirectToExamSummary = !!(
            courses.every((value: ICourse) => value.purchaseType === 'proctorPass') &&
            isEmpty(packages) &&
            courses.length === 1
        );
        const userCourseId = isRedirectToExamSummary ? courses[0].userCourseId : '';
        if (isLoading) {
            return (
                <div className='confirmation-payment'>
                    <img src={process.env.PUBLIC_URL + '/icon-yourcourseready.svg'} alt={'Course Ready logo'} />
                    <h2>We are now processing your payment, please do not refresh or close the window!</h2>
                    <div className='process-progress'>
                        <CircularProgressbar
                            value={processProgress}
                            strokeWidth={8}
                            text={`${Math.ceil(processProgress)}%`}
                        />
                    </div>
                    <h5>This would take less than a minute!</h5>
                </div>
            );
        }

        if (!isLoading && isTransactionFailed) {
            return <PaymentFail />;
        }

        return (
            <div className='checkout-container'>
                {this.isAdminPreview && (
                    <div className='checkout-page-preview-settings-confirmation'>
                        <StudentPreviewControls isCheckout={true} />
                    </div>
                )}
                <Header
                    autoLoginInfo={{ isShowAccessCourse, loginToken, pollingFailedMessage }}
                    isRedirectToExamSummary={isRedirectToExamSummary}
                    userCourseId={userCourseId}
                />
                <Discount checkThoughtMetricSurvey={this.checkThoughtMetricSurvey} />
                {thoughtMetricSurvey && isDisplaySurvey && <div id='thought_survey'></div>}
                {upsells.length !== 0 && (
                    <>
                        <div className='frequentlyBoughtTogether'>
                            <h2>Frequently Bought Together</h2>
                            <p>
                                For a <span>limited time</span> only we offer supportive material to the {''}
                                {purchasedPackage} at a discounted price
                            </p>
                        </div>

                        <div className='upsells-list confirmation'>
                            {upsells.map((upsell: IUpsell) => {
                                return (
                                    <UpsellCard
                                        upsell={upsell}
                                        key={upsell._id}
                                        confirmation={true}
                                        isAdminPreview={this.isAdminPreview}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default withRouter(Confirmation);
