import React, { Component, ReactNode } from 'react';
import Course from './Course';
import { IUserCourse } from '../CourseButton';
import './Courses.scss';
import { IPackage } from './PackageCard';
import { Api } from 'src/helpers/new';
import { isNil } from 'lodash';

interface IProps {
    packageId: string;
    courses: IUserCourse[];
    userPackage: IPackage;
}

interface IState {
    showCourses: boolean;
    courses: IUserCourse[];
    loaded: boolean;
}

export default class Courses extends Component<IProps, IState> {
    state: IState = {
        showCourses: true,
        courses: [],
        loaded: false,
    };

    componentDidMount() {
        const { userPackage } = this.props;

        this.setState({
            showCourses: isNil(userPackage.showCourses) ? true : userPackage.showCourses,
        });
    }

    toggleCourses = async () => {
        this.setState({ showCourses: !this.state.showCourses });

        const { success } = await Api.call('put', `/users/packages/${this.props.userPackage._id}/toggleShowCourses`, {
            showCourses: !this.state.showCourses,
        });

        if (!success) {
            this.setState({ showCourses: this.state.showCourses });
        }
    };

    get isLongText(): boolean {
        const { userPackage } = this.props;
        if (userPackage.data.title.length > 48 || userPackage.data.description.length > 78) return true;
        return false;
    }

    isSingleCourse = (): boolean => {
        return this.props.courses.length === 1;
    };

    displayCourses = () => {
        const { showCourses } = this.state;
        const { courses, packageId } = this.props;

        const userPackageSuspendedAt = this.props.userPackage?.suspendedAt;

        if (this.isSingleCourse()) {
            return (
                <div className='course-list single-course'>
                    <Course
                        course={courses[0]}
                        key={courses[0]._id}
                        allCourses={courses}
                        packageId={packageId}
                        userPackageSuspendedAt={userPackageSuspendedAt}
                    />
                </div>
            );
        } else {
            return (
                showCourses && (
                    <div className='course-list'>
                        {courses.map((course: IUserCourse) => {
                            return (
                                <Course
                                    course={course}
                                    key={course._id}
                                    allCourses={courses}
                                    packageId={packageId}
                                    userPackageSuspendedAt={userPackageSuspendedAt}
                                />
                            );
                        })}
                    </div>
                )
            );
        }
    };

    render(): ReactNode {
        return (
            <div className={`package-courses ${!this.isLongText ? 'long-text-spacing' : 'show-course-spacing'}`}>
                {!this.isSingleCourse() && (
                    <button className='show-courses-button' onClick={this.toggleCourses}>
                        <span>{this.state.showCourses ? 'Hide' : 'Show'} courses</span>
                        <i className={`fas fa-chevron-${this.state.showCourses ? 'up' : 'down'}`} />
                    </button>
                )}
                {this.displayCourses()}
            </div>
        );
    }
}
