import React, { Component } from 'react';
import { Api } from 'src/helpers/new';
import UserLayout from '../../layouts/User';
import './Policy.scss';

export default class Policy extends Component {
    state = {
        content: '',
    };

    async componentDidMount() {
        const { response, success } = await Api.call('GET', '/settings/' + this.props.keyword);

        this.setState({
            content: success ? response : 'Failed to load',
        });
    }

    render() {
        return (
            <UserLayout>
                <div className='policy-wrapper'>
                    <h1>{this.props.title}</h1>
                    <br />
                    <div id={this.props.keyword} dangerouslySetInnerHTML={{ __html: this.state.content }} />
                </div>
            </UserLayout>
        );
    }
}
