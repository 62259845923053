import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class WithOverFlowTooltip extends Component {
    state = {
        isOverFlow: false,
        text: undefined,
    };

    updateOverflow = (e) => {
        const el = e.target;
        const { text } = this.state;

        if (el.scrollWidth > el.clientWidth) {
            this.setState({ isOverFlow: true });
            if (el.textContent !== text) {
                this.setState({ text: el.textContent });
            }
        } else {
            this.setState({ isOverFlow: false });
        }
    };

    render() {
        const { isOverFlow, text } = this.state;

        return isOverFlow && text ? (
            <OverlayTrigger
                key={`tooltip`}
                placement={this.props?.placement ?? 'top'}
                overlay={<Tooltip>{isOverFlow && text ? text : ''}</Tooltip>}
            >
                <div
                    className={this.props.className}
                    style={{
                        overflow: 'hidden',
                        overflowWrap: 'break-word',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        wordBreak: 'break-all',
                        display: 'block',
                    }}
                    onMouseEnter={this.updateOverflow}
                >
                    {this.props.children}
                </div>
            </OverlayTrigger>
        ) : (
            <div
                className={this.props.className}
                style={{
                    overflow: 'hidden',
                    overflowWrap: 'break-word',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    wordBreak: 'break-all',
                    display: 'block',
                }}
                onMouseEnter={this.updateOverflow}
            >
                {this.props.children}
            </div>
        );
    }
}
