import { each, first, filter, isNil, isEmpty, get } from 'lodash';
import DateTimeFormatHelper from '../DateTimePickerHelperWrapper';
import validator from 'validator';
import { IUserCourse } from 'src/pages/Home/packages/CourseButton';

interface IUserPermission {
    module: string;
    permissionsList: IPermissionList[];
}

interface IPermissionList {
    displayName: string;
    permission: string;
    severity: string;
    _id: string;
}

export default class Utility {
    static readonly zipCodeFormat: any = {
        'en-US': '^\\d{5}(-\\d{4})?$',
    };

    static validateZipCode(val: string) {
        const userLocale = navigator?.languages?.length ? navigator.languages[0] : navigator.language;
        const regex = new RegExp(this.zipCodeFormat[userLocale] || this.zipCodeFormat['en-US']);

        return regex.test(val);
    }

    static validateFields(fields: any, validatedFields: string[], customMessage?: any) {
        const errors: { [key: string]: string } = {};
        each(fields, (v: any, k: string) => {
            const value = first(filter(validatedFields, (val: string) => val === k));
            if ((value && isNil(v)) || (value && v === '')) {
                errors[k] = this.getLabel(value, customMessage);
            }
        });

        return errors;
    }

    static isNoEmptyFields(requiredFieldKeys: string[], lookupData: any) {
        if (requiredFieldKeys.filter((field: string) => isEmpty(lookupData[field])).length > 0) return false;
        return true;
    }

    static getLabel(val: string, customMessage?: any): string {
        if (isEmpty(customMessage)) {
            switch (val) {
                case 'image':
                case 'imageFile':
                    return `Please select the ${val}`;
                default:
                    return `Please enter the ${val}`;
            }
        } else {
            return get(customMessage, val, `Please enter the ${val}`);
        }
    }

    static isMobileView() {
        return /(iPhone|iPad|iPod|Android)/.test(navigator.userAgent);
    }

    static getRandomVal() {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return array[0] / (0xffffffff + 1);
    }

    static getRandomValWithMaxValue(maxValue: number) {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        const value = Math.floor((array[0] / (0xffffffff + 1)) * maxValue) + 1;
        return value;
    }

    static getMaintenanceDuration(startTime?: string, userRestrictedTime?: number) {
        const diff = DateTimeFormatHelper.diff(
            DateTimeFormatHelper.currentDate(),
            DateTimeFormatHelper.getDate(startTime),
            'second',
        );

        const hoursDifference = Math.floor(diff / 3600);
        const minutesDifference = Math.floor((diff % 3600) / 60);
        const secondsDifference = diff % 60;
        const restrictDuration = userRestrictedTime ?? 2;

        if (
            (hoursDifference >= 0 && hoursDifference < restrictDuration) ||
            (hoursDifference === restrictDuration && minutesDifference <= 0 && secondsDifference <= 0)
        ) {
            return true;
        } else {
            return false;
        }
    }

    static validateValueForRegex(regex: RegExp, value?: string) {
        let isValid = false;

        if (!value || regex.test(value)) {
            isValid = true;
        }
        return isValid;
    }

    static getNumberSuffix(number: number) {
        const lastDigit = number % 10;
        let suffix;

        if (lastDigit === 1) {
            suffix = 'st';
        } else if (lastDigit === 2) {
            suffix = 'nd';
        } else if (lastDigit === 3) {
            suffix = 'rd';
        } else {
            suffix = 'th';
        }

        return `${number}${suffix}`;
    }

    static readonly validateEmail = (email: string) => {
        return email && validator.isEmail(email);
    };

    static readonly validateNmlsUserId = (value?: string) => {
        let isValid = false;

        if (!value || this.validateEmail(value) || Utility.validateValueForRegex(/^\d{4,9}$/, value)) {
            isValid = true;
        }

        return isValid;
    };

    static readonly validateNmlsCourseId = (value?: string) => {
        return Utility.validateValueForRegex(/^\d{4,7}$/, value);
    };

    static readonly validateNmlsCourseDuration = (value?: string) => {
        return Utility.validateValueForRegex(/^[1-9]$|^1[0-2]$/, value);
    };

    static readonly getUsersPermissions = (moduleName: string) => {
        const user = localStorage.getItem('user');
        const parsedUser =
            !isNil(user) && !isEmpty(user)
                ? (JSON.parse(user)
                      ?.userGroupPermissions?.allPermissions.find(
                          (userPermission: IUserPermission) => userPermission.module === moduleName,
                      )
                      ?.permissionsList.map((list: IPermissionList) => list.permission) ?? [])
                : [];

        return parsedUser;
    };

    static readonly calculateRemainingAccessibleDays = (userCourse: IUserCourse) => {
        let remainingAccessibleDays = 0;

        const daysFromCreatedAt = DateTimeFormatHelper.diff(
            DateTimeFormatHelper.getDate(userCourse.createdAt),
            DateTimeFormatHelper.currentDate(),
            'days',
        );

        const daysFromExpiredAt = DateTimeFormatHelper.diff(
            DateTimeFormatHelper.getDate(userCourse.createdAt),
            DateTimeFormatHelper.getDate(userCourse.expiresAt),
            'days',
        );

        const comparingDays =
            daysFromExpiredAt > daysFromCreatedAt && daysFromExpiredAt >= 0 ? daysFromExpiredAt : daysFromCreatedAt + 1;

        if (comparingDays < userCourse.maximumCourseTimeFrame) {
            remainingAccessibleDays = userCourse.maximumCourseTimeFrame - comparingDays;
        }

        return remainingAccessibleDays;
    };
}
