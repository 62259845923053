import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import { ICard } from '../Cards/LessonCard';
import LessonContext from '../LessonContext';

interface IProps {
    lesson: any;
    updateLesson: (lesson: any) => void;
}

interface IUpdateData extends ICard {
    cardId?: string;
    lessonId: string;
    chapterId?: string;
    deletedAt?: Date;
}

export default class StudentPreviewController extends Component<IProps> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    async componentDidMount() {
        EventBus.on('update-admin-preview-lesson', this.updateAdminPreviewLesson);
        EventBus.on('update-admin-preview-card', this.updateAdminPreviewCard);
        EventBus.on('admin-preview-change-lesson', this.handleEditorLessonChange);
    }

    componentWillUnmount(): void {
        EventBus.remove('update-admin-preview-lesson', this.updateAdminPreviewLesson);
        EventBus.remove('update-admin-preview-card', this.updateAdminPreviewCard);
        EventBus.remove('admin-preview-change-lesson', this.handleEditorLessonChange);
    }

    updateAdminPreviewLesson = (e: Event) => {
        if (!this.context.isDraft) return;

        const { chapterId, lessonId, ...updateData } = (e as CustomEvent).detail as IUpdateData;
        const lesson = { ...this.props.lesson, ...updateData };

        this.props.updateLesson(lesson);
    };

    updateAdminPreviewCard = async (e: Event) => {
        if (!this.context.isDraft) return;

        const { cardId, lessonId, ...updateData } = (e as CustomEvent).detail as IUpdateData;
        const lesson = { ...this.props.lesson };
        if (lessonId !== lesson._id) return;
        const cardIndex = lesson.cards.findIndex((card: ICard) => cardId === card._id);

        if (updateData.deletedAt) {
            lesson.cards.splice(cardIndex, 1);
        } else if (updateData._id) {
            lesson.cards.push(updateData);
        } else {
            lesson.cards[cardIndex] = { ...lesson.cards[cardIndex], ...updateData };
        }
        for (let i = 0; i < lesson.cards.length; i++) {
            if (!lesson.cards[i].userLessonCardData) {
                const createCardObj = {
                    card: lesson.cards[i],
                    userLessonId: lessonId,
                    userCourseId: lesson.userCourseId,
                    userChapterId: lesson.userChapterId,
                    courseId: lesson.course._id,
                };
                const { success, response } = await Api.call('POST', `/courses/userLessonCard/create`, createCardObj);
                if (success && lesson && lesson?.cards[i]) {
                    lesson.cards[i].userLessonCardData = response;
                }
            }
        }

        lesson.cards = lesson.cards.sort((a: any, b: any) => a.orderIndex - b.orderIndex);
        this.props.updateLesson(lesson);
    };

    handleEditorLessonChange = (e: Event) => {
        const updateData = (e as CustomEvent).detail as IUpdateData;

        if (this.context.isSynced) EventBus.dispatch('change-lesson', updateData);
    };

    render() {
        return null;
    }
}
