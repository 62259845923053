import { startCase } from 'lodash';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import PackageHelper from 'src/helpers/PackageHelper';
import { Api } from 'src/helpers/new';
import CheckoutContext from 'src/pages/Checkout/CheckoutContext';
import './PaymentSummary.scss';
import { ConfirmationModal } from 'src/components/ConfirmationModal';

interface IProps extends RouteComponentProps {
    isSinglePageCheckout: boolean;
}
interface IState {
    imageArr: any[];
    deleteModalShow: boolean;
    deletePkgId: string;
    imageClasses: any[];
}

class PaymentSummary extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;

    state: IState = {
        imageArr: [],
        deleteModalShow: false,
        deletePkgId: '',
        imageClasses: [],
    };

    async componentDidMount() {
        if (!this.context.packages[0]?.packagePurchaseType && !Array.isArray(this.context.packages[0]?.image)) {
            await this.context.packages.map(async (data: any) => {
                const imgUrl = await this.getImageUrl(data.course[0].image);
                this.setState((prevState) => ({
                    imageArr: [...prevState.imageArr, imgUrl],
                }));
            });
        }

        const resolvedImgClasses = await Promise.all(PackageHelper.getImagePromise(this.context.packages));
        if (resolvedImgClasses.length > 0) {
            this.setState({
                imageClasses: resolvedImgClasses,
            });
        }
    }

    getImageUrl = async (id: string) => {
        const { response } = await Api.call('get', `files/${id}`);
        return response.url[2];
    };

    renderImage = (pkg: any, imgClassObj: any) => {
        let imgPath = pkg?.image?.[2];

        if (pkg?.purchaseType === 'chat') {
            imgPath = process.env.PUBLIC_URL + '/checkout-chat-icon.png';
        } else if (pkg?.purchaseType === 'proctorPass') {
            imgPath = process.env.PUBLIC_URL + '/checkout-proctor-pass.png';
        } else if (pkg?.purchaseType === 'extension') {
            imgPath = process.env.PUBLIC_URL + '/checkout-extension.png';
        }
        return (
            <div className='imgWrap'>
                <img src={imgPath} alt={pkg.title} className={imgClassObj?.className} />
            </div>
        );
    };

    openDeleteModal = (id: string) => {
        this.setState({
            deleteModalShow: true,
            deletePkgId: id,
        });
    };

    deleteModalAction = () => {
        this.removePackageFromCart();
        this.setState({
            deleteModalShow: false,
            deletePkgId: '',
        });
    };

    removePackageFromCart = async () => {
        const id = this.state.deletePkgId;

        const query = new URLSearchParams(this.props.location.search);
        const packageIdInUrl = query.get('packageId');

        const filteredPackages = this.context.packages.filter(
            (pkg: any) => pkg?._id !== id && pkg.hasOwnProperty('packagePurchaseType'),
        );
        const filteredCourses = this.context.packages.filter(
            (course: any) => course?._id !== id && course.hasOwnProperty('purchaseType'),
        );

        if (id === packageIdInUrl) {
            this.props.navigate('/checkout', { replace: true });
        }

        this.context.updateCart({
            packages: filteredPackages,
            courses: filteredCourses,
            isPriceUpdate: this.context.tax > 0,
        });

        if ([...filteredPackages, ...filteredCourses].length <= 0) {
            if (this.context.isAdminPreview) {
                localStorage.removeItem('reuPreviewCheckoutCartId');
            } else {
                localStorage.removeItem('reuCheckoutCartId');
            }

            localStorage.removeItem('__paypal_storage__');
        }
    };

    renderPackages = () => {
        const { packages } = this.context;

        return packages.map((pkg: any, index: number) => {
            const imgClassObj = this.state.imageClasses.find((v) => v._id === pkg?._id);
            const packageKey = pkg.packageId ?? pkg._id + (pkg.type ?? 'PACKAGE');
            return (
                <div key={packageKey} className='payment-package-card '>
                    {this.renderImage(pkg, imgClassObj)}
                    <div className={`contentWrap`}>
                        <div>
                            {pkg.purchaseType === 'proctorPass' ? (
                                <div>
                                    <h3 className='name'>{`${startCase(pkg.purchaseType)} for "${
                                        pkg.packageTitle
                                    }"`}</h3>
                                </div>
                            ) : (
                                <div>
                                    <h3 className='name'>{pkg.title}</h3>
                                    {pkg.purchaseType && <p>({startCase(pkg.purchaseType)})</p>}
                                </div>
                            )}
                            <div className='detailsWrap'>
                                <div className='details'>
                                    <div className='details-row'>
                                        <span className='price'>
                                            {(pkg.discountedPrice || pkg.discountedPrice === 0) &&
                                            pkg.discountedPrice !== pkg.price ? (
                                                <>
                                                    <span className='old'>{global.USCurrency.format(pkg.price)}</span>
                                                    {global.USCurrency.format(pkg.discountedPrice)}
                                                </>
                                            ) : (
                                                <>{global.USCurrency.format(pkg.price)}</>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='order-package-controls'>
                            <div
                                className='delete'
                                onClick={() => {
                                    this.openDeleteModal(pkg._id);
                                }}
                            >
                                <i className='fa fa-trash fa-sm' />
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };

    render() {
        const { isSinglePageCheckout } = this.props;
        return (
            <>
                <Row className='list-wrapper'>
                    {!isSinglePageCheckout && (
                        <Col className='heading-wrapper'>
                            <h2>Order Summary</h2>
                        </Col>
                    )}

                    <Col className='order-summary-package-wrapper'>
                        <div className='order-summary-container'>{this.renderPackages()}</div>
                    </Col>
                </Row>
                <ConfirmationModal
                    show={this.state.deleteModalShow}
                    size={'lg'}
                    hideModal={() => {
                        this.setState({ deleteModalShow: false });
                    }}
                    confirmAction={this.deleteModalAction}
                    titleText={'Are you sure?'}
                    bodyText={['Are you sure you would like to remove this package from the cart ?']}
                />
            </>
        );
    }
}

export default withRouter(PaymentSummary);
