import React, { Component } from 'react';
import TextareaInput from 'src/components/_New/Form/TextareaInput';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import './EssayAdmin.slide.scss';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import LessonContext from '../../../../LessonContext';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';
import { IUserLessonCardData } from '../../../UserLessonCard';
import { IEssay } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/types';
import { IEssayAttempt } from '../Slide/Essay.slide';
import { isNil } from 'lodash';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface IProps {
    userLessonCardData: IUserLessonCardData;
    activeCardIndex?: number;
    setActiveCardIndex?: (e: number) => void;
    essay: IEssay;
    detailedQuestion?: string;
    theme?: string;
    heading?: string;
    subHeading?: string;
    latestAttemptData?: IEssayAttempt;
}

interface IState {
    allEssayIndex: number[];
}
export default class EssayAdminSlide extends Component<IProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            allEssayIndex: [],
        };
    }

    componentDidMount = () => {
        const allEssayIndex: number[] = this.context.cards
            .map((card: any, index: number) => (card.cardType === 'ESSAY' ? index : undefined))
            .filter((data: number | undefined): data is number => typeof data === 'number');

        this.setState({ allEssayIndex });
        if (this.context.setUserLessonCardData) this.context.setUserLessonCardData(this.props.userLessonCardData);
    };

    componentWillUnmount() {
        if (this.context.setUserLessonCardData) this.context.setUserLessonCardData([]);
    }

    nextEssaySlide = () => {
        const { allEssayIndex } = this.state;

        if (this.props.activeCardIndex === allEssayIndex[allEssayIndex.length - 1]) return;

        const currentIndex = allEssayIndex.findIndex((data: number) => data === this.props.activeCardIndex);
        if (!isNil(this.props.setActiveCardIndex)) {
            this.props.setActiveCardIndex(allEssayIndex[currentIndex + 1]);
        }
    };

    previousEssaySlide = () => {
        const { allEssayIndex } = this.state;

        if (this.props.activeCardIndex === allEssayIndex[0]) return;

        const currentIndex = allEssayIndex.findIndex((data: number) => data === this.props.activeCardIndex);
        if (!isNil(this.props.setActiveCardIndex)) {
            this.props.setActiveCardIndex(allEssayIndex[currentIndex - 1]);
        }
    };

    render() {
        const { allEssayIndex } = this.state;
        const { latestAttemptData } = this.props;
        const instruction = latestAttemptData?.instruction ?? this.props.essay.instruction;

        return (
            <div className={`essay-admin-card theme-${(this.props.theme ?? '').toLowerCase()}`}>
                <div className='show-header-content'>
                    {!this.props.detailedQuestion && (
                        <SlideHeader heading={this.props.heading} subHeading={this.props.subHeading} />
                    )}
                    <div
                        className={`essay-slide-instruction`}
                        dangerouslySetInnerHTML={{
                            __html: instruction,
                        }}
                    />
                </div>
                <div className={'essay-card-body-wrapper'}>
                    <Fa
                        icon={faChevronCircleLeft}
                        size={'2x'}
                        onClick={this.previousEssaySlide}
                        style={{
                            opacity: this.props.activeCardIndex === allEssayIndex[0] ? 0.5 : 1,
                        }}
                    />
                    <div className='body-container'>
                        {latestAttemptData?.attemptDate && (
                            <div className={'attempt-heading-container'}>
                                <h3> Attempt {latestAttemptData?.showAttemptIndex} </h3> |
                                <h3>{DateTimeFormatHelper.format(latestAttemptData?.attemptDate, 'MM/DD/YYYY')}</h3>
                            </div>
                        )}
                        <TextareaInput
                            isReadOnly={true}
                            className='slide-card-textarea'
                            value={latestAttemptData?.essayAnswer ?? ''}
                        />
                    </div>
                    <Fa
                        icon={faChevronCircleRight}
                        size={'2x'}
                        onClick={this.nextEssaySlide}
                        style={{
                            opacity: this.props.activeCardIndex === allEssayIndex[allEssayIndex.length - 1] ? 0.5 : 1,
                        }}
                    />
                </div>
            </div>
        );
    }
}
