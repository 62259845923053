import React, { Component } from 'react';
import CourseContext from 'src/pages/Course/CourseContext';
import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditialWrapper';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface IProps {
    clickHandler: () => void;
}
interface IState {}

export default class StartExamSlide extends Component<IProps, IState> {
    render() {
        return (
            <div
                className={`lesson-button take-final-exam-btn `}
                onClick={() => {
                    this.props.clickHandler();
                }}
            >
                <>{`Go to final exam`}</>
            </div>
        );
    }
}
