import React, { Component } from 'react';
import VimeoPlay from '@vimeo/player';
import LessonContext from '../../pages/Course/Stages/Lessons/LessonContext';
import { commonTimeout } from '../../helpers/commonTimeout';
import { EventBus } from 'src/helpers/new';

export default class VimeoPlayer extends Component {
    static contextType = LessonContext;

    constructor(props) {
        super(props);
        this.state = {
            userInactive: false,
            isVideoEnded: false,
        };
        this.vimeoIframe = React.createRef();
    }

    componentDidMount() {
        if (this.vimeoIframe.current && !this.context.isAdmin) {
            const player = new VimeoPlay(this.vimeoIframe.current);
            const self = this;

            player.on('play', function () {
                EventBus.dispatch('resume-on-media-stop');
                self.clearInactiveTimer(true);
            });

            player.on('pause', function (data) {
                if (!self.state.isVideoEnded && data.duration !== data.seconds) {
                    EventBus.dispatch('pause-on-media-stop');
                }
                self.startInactiveTimer();
            });

            player.on('ended', function () {
                self.setState({
                    isVideoEnded: true,
                });
                EventBus.dispatch('resume-on-media-stop');
            });
        }
    }

    componentWillUnmount() {
        this.setState({ isVideoEnded: false });
        EventBus.dispatch('resume-on-media-stop');
    }

    startInactiveTimer = () => {
        this.context.clearMediaPlay(false);
        this.context.inactiveTimerCallback();
    };

    clearInactiveTimer = (state) => {
        commonTimeout.clearTimer('inactive');
        commonTimeout.clearTimer('logout');
        this.context.clearMediaPlay(state);
    };

    render() {
        const { videoId, width, height } = this.props;

        let newHeight;
        if (window.innerWidth < 800) {
            newHeight = window.innerWidth * 0.56375;
        } else {
            newHeight = height;
        }

        return (
            <iframe
                ref={this.vimeoIframe}
                src={`https://player.vimeo.com/video/${videoId}?player_id=vimeo${videoId}`}
                width={width}
                height={newHeight}
                frameBorder='0'
                allowFullScreen
                referrerPolicy='strict-origin-when-cross-origin'
            />
        );
    }
}
