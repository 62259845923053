import React, { Component } from 'react';
import PropTypes from 'prop-types';
export default class Next extends Component {
    render() {
        return (
            <button className={this.props.className} onClick={this.props.onClick}>
                Next
                <div style={{ marginLeft: '10px' }}>
                    <i className='fa fa-chevron-right fa-xs' />
                </div>
            </button>
        );
    }
}

Next.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
