import React, { Component } from 'react';
import { FileImage } from 'src/components/ApiFile';
import './Text.slide.scss';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';

export interface IProps {
    heading?: string;
    subHeading?: string;
    content?: string;
    sourceImage?: string;
    theme?: string;
    info?: string;
}
export interface IState {}

export default class TextSlide extends Component<IProps> {
    render() {
        const { heading, subHeading, content, sourceImage, info } = this.props;
        return (
            <>
                <SlideHeader heading={heading} subHeading={subHeading} />
                {sourceImage && <FileImage fileId={sourceImage} />}
                <div className='text-slide-content' dangerouslySetInnerHTML={{ __html: content ?? '' }} />
            </>
        );
    }
}
