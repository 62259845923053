import React, { Component } from 'react';
import { ICustomCalendarItemComponent } from 'src/components/_New/Navigation/Calendar';
import './QuizItemComponents.scss';

export default class UnansweredQuestion extends Component<ICustomCalendarItemComponent> {
    render() {
        const { originalIndex, rule, item } = this.props;

        return (
            <div className='quiz-calendar-item unanswered-question' onClick={() => rule.onClick(item, originalIndex)}>
                <h3>{originalIndex + 1}</h3>
            </div>
        );
    }
}
