import humanizeDuration from 'humanize-duration';

export default humanizeDuration.humanizer({
    language: 'shortEn',
    languages: {
        shortEn: {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms',
        },
    },
    spacer: '',
    delimiter: ' ',
    round: true,
    maxDecimalPoints: 2,
});
