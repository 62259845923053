import { isNil } from 'lodash';
import { IDisplayTooltip } from 'src/pages/Home/packages/PackageCard/Course';

export default class ExamHelper {
    static getCourseExamReview(course: any) {
        if (course.status === 'EXAM_PASSED' || course.status === 'FINISHED') {
            if (!isNil(course?.examReviewStatus) && ['APPROVED', 'NOT_REQUIRED'].includes(course?.examReviewStatus)) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    static processExamStatus(course: any): IDisplayTooltip {
        let displayContent = { isShowTooltip: false, Msg: '', icon: '' };
        const attemptCount =
            isNil(course.attemptLimit) || course.attemptLimit == 0
                ? 'multiple'
                : course.attemptLimit - course.examAttempts;

        if (course.status === 'NEW' || course.status === 'IN_EXAM') {
            displayContent = {
                isShowTooltip: true,
                Msg: `You have not completed this course's exam yet. You have ${attemptCount} exam attempt${
                    attemptCount === 'multiple' ? 's' : ''
                } remaining.`,
                icon: 'disabled-exam-icon.svg',
            };
        } else if (course.status === 'EXAM_PASSED' || course.status === 'FINISHED') {
            if (!isNil(course?.examReviewStatus) && ['APPROVED', 'NOT_REQUIRED'].includes(course?.examReviewStatus)) {
                displayContent = {
                    isShowTooltip: false,
                    Msg: ``,
                    icon: 'complete-exam.svg',
                };
            } else {
                displayContent = {
                    isShowTooltip: true,
                    Msg: `This exam is being reviewed. Your results will be available within 24 hours of submission`,
                    icon: 'review-exam.svg',
                };
            }
        } else if (course.status === 'FAILED') {
            const displayStr =
                isNil(course.attemptLimit) || course.attemptLimit == 0
                    ? `You'll need to attempt the exam again.`
                    : course.attemptLimit <= course.examAttempts
                      ? "You'll need to purchase a new package to try again."
                      : `You have ${attemptCount} exam attempt remaining.`;
            displayContent = {
                isShowTooltip: true,
                Msg: `Your exam attempt was unsuccessful. ${displayStr}`,
                icon: 'failed-exam.svg',
            };
        }

        return displayContent;
    }
}
