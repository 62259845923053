import React, { Component } from 'react';
import LessonContext from '../../LessonContext';
import { Spinner } from 'src/components/Spinner';
import LessonCard, { ICard } from '../../Cards/LessonCard';
import Header from './Header';
import Footer from './Footer';
import './page.scss';
import { Banner } from 'src/pages/Home/Banner';

export default class Page extends Component {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    render() {
        const { isLoading, lesson } = this.context;
        if (isLoading) return <Spinner />;

        const { cards, isAdminPreview } = lesson;

        const chapterName = this.context.lesson?.chapter[0]?.doc?.title ?? '';

        return (
            <div className='page-lesson-container'>
                <Header
                    lessonName={lesson.title}
                    chapterName={chapterName}
                    chapterId={lesson.userChapterId}
                    userCourseId={lesson.userCourseId}
                    isAdminPreview={isAdminPreview}
                    lessonType={lesson.lessonType}
                />
                {!isAdminPreview && <Banner channel={'lessons'} />}
                <div className='card-list'>
                    {cards.map((card: ICard) => {
                        return <LessonCard key={card._id} {...card} />;
                    })}
                </div>
                <Footer />
            </div>
        );
    }
}
