import React, { Component } from 'react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

interface IProps {
    onPageChange: (page: number) => void;
    totalPages: number;
    activePage: number;
    className: string;
}
interface IState {}

export default class StatePagination extends Component<IProps, IState> {
    onPageChange = (page: number) => () => {
        this.props.onPageChange(page);
    };

    render() {
        if (this.props.totalPages <= 1) {
            return null;
        }

        const { totalPages, activePage } = this.props;
        return (
            <div className={this.props.className}>
                {activePage !== 1 && (
                    <button onClick={this.onPageChange(1)} className='btn btn--small bd'>
                        1
                    </button>
                )}
                {activePage - 1 > 1 && (
                    <button onClick={this.onPageChange(activePage - 1)} className='btn btn--small bd'>
                        <Fa icon={faAngleLeft} />
                    </button>
                )}
                <button onClick={this.onPageChange(activePage + 1)} className='btn btn--small bp'>
                    {activePage}
                </button>

                {activePage + 1 < totalPages && (
                    <button onClick={this.onPageChange(activePage + 1)} className='btn btn--small bd'>
                        {activePage + 1 === totalPages - 1 ? totalPages - 1 : <Fa icon={faAngleRight} />}
                    </button>
                )}
                {activePage !== totalPages && totalPages > 1 && (
                    <button onClick={this.onPageChange(totalPages)} className='btn btn--small bd'>
                        {totalPages}
                    </button>
                )}
            </div>
        );
    }
}
