import React, { Component } from 'react';
import { IUserLessonCardData } from '../../../UserLessonCard';
import { Header } from '../../../Components';
import { Button, Col, Row } from 'react-bootstrap';
import ResizableTextArea from 'src/components/inputs/ResizableTextArea';
import { cloneDeep, get, isEmpty, last } from 'lodash';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { Api, EventBus } from 'src/helpers/new';
import LessonContext from '../../../../LessonContext';
import Spinner from 'src/components/Spinner/Spinner';
import EssayAttemptListItem from './EssayAttemptListItem';

interface IEssay {
    instruction: string;
}
export interface IEssayAttempt {
    _id: string;
    attemptStatus?: string;
    attemptDate: string;
    essayAnswer: string;
    feedback?: string;
    instruction: string;
    instructorReviewDate?: string;
    instructorId?: string;
    showAttemptIndex?: number;
    currentAttemptStatus: string;
    instructorData?: {
        file: { fileName: string; url: string[] };
        firstName: string;
        image: string;
        lastName: string;
    };
}
export interface IEssayPageProps {
    essay: IEssay;
    cardId: string;
    cardIndex: number;
    userLessonCardData: IUserLessonCardData;
    theme?: string;
    heading?: string;
    subHeading?: string;
    courseId?: string;
    detailedQuestion?: string;
}
interface IState {
    essayAnswer: string;
    essayAttempts: IEssayAttempt[];
    isLoading: boolean;
    activeAttemptsIndex: number;
    isBottomCancelScroll: boolean;
    isSendButtonLoading: boolean;
}
export default class EssayPage extends Component<IEssayPageProps, IState> {
    static readonly contextType = LessonContext;
    private containerRef: React.RefObject<any> = React.createRef();
    context!: React.ContextType<typeof LessonContext>;

    state: IState = {
        essayAnswer: '',
        essayAttempts: [],
        isLoading: false,
        activeAttemptsIndex: 0,
        isBottomCancelScroll: false,
        isSendButtonLoading: false,
    };

    getUserLessonCard = () => {
        return get(this.props.userLessonCardData, '_id', '');
    };

    async componentDidMount() {
        const userLessonCard = cloneDeep(this.props.userLessonCardData);
        if (this.props.essay?.instruction) {
            this.loadData(userLessonCard?.essay?.essayAttempts || []);
        }
    }

    async componentDidUpdate(prevProps: Readonly<IEssayPageProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (
            this.containerRef?.current &&
            prevProps.userLessonCardData?._id &&
            this.props.userLessonCardData?._id === prevProps.userLessonCardData?._id &&
            this.state.isBottomCancelScroll
        ) {
            this.containerRef.current.scrollTop = this.containerRef.current.scrollHeight;
            this.containerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    componentWillUnmount(): void {
        this.setState({ isBottomCancelScroll: false });
    }

    loadData(essayAttempts: IEssayAttempt[]) {
        this.setState((prevState: IState) => ({
            ...prevState,
            essayAttempts: essayAttempts.reverse(),
            activeAttemptsIndex: essayAttempts.length - 1,
            isLoading: false,
            isBottomCancelScroll: true,
            isSendButtonLoading: false,
        }));
    }

    onChangeAnswer = (event: any) => {
        this.setState({ essayAnswer: event.target.value, isBottomCancelScroll: false });
    };

    submitEssayAnswer = async () => {
        EventBus.dispatch('action-confirmation-popup', {
            title: 'Submit',
            body: `Did you complete your answer? If you’re ready to submit, click the “Send” button.`,
            doAsyncInBackground: true,
            confirm: {
                text: 'Send',
                action: async () => {
                    await this.onSubmit();
                },
                clz: 'bp',
            },
            cancel: {
                text: 'Cancel',
                action: () => {},
                clz: 'btn',
            },
        });
    };

    onSubmit = async () => {
        this.setState({ isSendButtonLoading: true });

        const { isAdminPreview } = this.context.lesson;
        const { essayAnswer } = this.state;
        if (this.props.courseId && !isEmpty(essayAnswer?.trim())) {
            const payload: any = {
                lessonCardId: this.props.cardId,
                userChapterId: this.context.userChapterId,
                userCourseId: this.context.userCourseId,
                userLessonId: this.context._id,
                courseId: this.props.courseId,
                attemptStatus: 'PENDING',
                attemptDate: DateTimeFormatHelper.currentDate(),
                essayAnswer: essayAnswer,
                instruction: this.props.essay.instruction,
                isAdminPreview: isAdminPreview,
                lockedInBy: this.props.userLessonCardData.essay?.lockedInBy ?? '',
            };

            const { success, message, response } = await Api.call(
                'patch',
                `users/courses/essayAnswerAttempt/${this.getUserLessonCard()}`,
                payload,
            );

            if (success) {
                if (response && !isEmpty(response.updatedCards) && this.context.updateUserLessonCards) {
                    this.context.updateUserLessonCards(response.updatedCards);
                }

                this.context.setTooltip && this.context.setTooltip(response.tooltip);
                this.setState({ essayAnswer: '' });

                await this.loadEssayAttempts();
                EventBus.dispatch('toast', {
                    type: 'success',
                    message: message ?? `Answer submitted`,
                });
                EventBus.dispatch('update-lesson-status', {
                    lessonId: this.context._id,
                    chapterId: this.context.userChapterId,
                    essayStatus: 'REVIEW_PENDING',
                });
            } else {
                this.setState({ isSendButtonLoading: false });
            }
        }
    };

    async loadEssayAttempts() {
        this.setState((prevState) => ({
            ...prevState,
            isLoading: true,
        }));

        let essayAttempts: IEssayAttempt[] = [];
        if (this.getUserLessonCard()) {
            const { success, response } = await Api.call(
                'GET',
                `/users/courses/essayAnswerAttempt/${this.getUserLessonCard()}`,
            );
            if (success) {
                essayAttempts = response.essay?.essayAttempts;
            }
        }

        this.loadData(essayAttempts);
    }

    displayInputBlock = (attempt: any) => {
        if (attempt?.attemptStatus === 'PENDING') {
            return (
                <>
                    <h2> An instructor is reviewing your submission.</h2>
                    <span>
                        {
                            "You'll receive your results and any feedback within 24 hours. Once you receive your results,\nyou'll be able to proceed further in the course."
                        }
                    </span>
                </>
            );
        } else if (attempt?.attemptStatus === 'APPROVED') {
            return null;
        }
        return (
            <Row className='essay-field-wrapper'>
                <Col className='input-wrapper'>
                    <ResizableTextArea
                        value={this.state.essayAnswer}
                        onChange={this.onChangeAnswer}
                        id={`essay-answer`}
                        name={`essay-answer`}
                        classsName='essay-answer'
                        placeholder='Type answer'
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        isBottomScroll={this.isLastElement()}
                    />
                </Col>
                <Col className='button-wrapper'>
                    <Button
                        disabled={this.state.isSendButtonLoading || this.state.isLoading}
                        className={`bp submit-button ${
                            this.state.isLoading ||
                            isEmpty(this.state.essayAnswer?.trim()) ||
                            this.state.isSendButtonLoading
                                ? 'btn--disabled'
                                : ''
                        }`}
                        onClick={this.submitEssayAnswer}
                    >
                        <span>Send</span>
                    </Button>
                </Col>
            </Row>
        );
    };

    displayAttemptList = () => {
        const { essayAttempts } = this.state;
        if (essayAttempts.length < 1) {
            return (
                <div className='no-answers-list'>{`Please submit your complete answer below for review. Make sure you’ve filled in your full response before clicking “Send.”`}</div>
            );
        } else {
            return (
                <div ref={this.containerRef as any} className='attempt-list-wrapper'>
                    {essayAttempts.map((attempt, idx) => {
                        return <EssayAttemptListItem key={idx} item={attempt} isAdmin={false} />;
                    })}
                </div>
            );
        }
    };

    isLastElement = () => {
        const lastElement = last(this.context.cards);
        return lastElement && lastElement._id === this.props.cardId;
    };

    render() {
        const { essayAttempts, activeAttemptsIndex } = this.state;
        const { heading, subHeading, essay } = this.props;
        const displayAttempt = essayAttempts[activeAttemptsIndex];

        return (
            <>
                {!this.props.detailedQuestion && <Header heading={heading} subHeading={subHeading} />}
                {essay?.instruction && (
                    <Row className='content' id='user-essay-content-wrapper'>
                        <Col className='content-data' dangerouslySetInnerHTML={{ __html: essay?.instruction ?? '' }} />
                        {this.state.isLoading ? (
                            <Spinner />
                        ) : (
                            <>
                                <Col className='display-attempt-list'>{this.displayAttemptList()}</Col>
                                <Col className='essay-field'>{this.displayInputBlock(displayAttempt)}</Col>
                            </>
                        )}
                    </Row>
                )}
            </>
        );
    }
}
