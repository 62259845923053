import React, { Component } from 'react';
import CourseContext from 'src/pages/Course/CourseContext';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { EventBus } from 'src/helpers/new';
import './Lesson.scss';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface ILesson {
    _id: string;
    draft: {
        title: string;
        lessonType: string;
    };
    chapterId: string;
    title: string;
    lessonType: string;
    isDraft?: boolean;
    isAdminPreview: boolean;
    unlocked?: string;
    essay_status?: string;
}

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

type TProps = ILesson & RouteComponentProps<IRouteProps>;

class Lesson extends Component<TProps> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    componentDidMount() {
        if (this.isCurrentLesson) {
            document.querySelector('.active')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }

    componentDidUpdate(prevProps: TProps) {
        if (this.props.params.lessonId !== prevProps.params.lessonId && this.isCurrentLesson) {
            document.querySelector('.active')?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }

    get isCurrentLesson(): boolean {
        return this.props._id === this.props.params.lessonId;
    }

    get isLessonCompleted(): boolean {
        return this.context.completedLessons?.includes(this.props._id) ?? false;
    }

    get isLessonUnlocked(): boolean {
        return this.context.unlockedLessons?.includes(this.props._id) ?? false;
    }

    get icon() {
        if (this.isCurrentLesson) {
            return <i className='fa fa-eye' />;
        } else if (this.isLessonCompleted) {
            return <i className='fa fa-check' />;
        } else if (this.isLessonUnlocked) {
            return <i className='fa fa-lock-open' />;
        } else {
            return <i className='fa fa-lock' />;
        }
    }

    get isLessonActive(): boolean {
        return this.isCurrentLesson || this.isLessonCompleted;
    }

    get isLessonAvailable(): boolean {
        return this.isCurrentLesson || this.isLessonCompleted || this.isLessonUnlocked;
    }

    handleLessonChange = () => {
        if (this.isCurrentLesson || !this.isLessonAvailable) return;
        const { chapterId, _id } = this.props;
        EventBus.dispatch('change-lesson', { lessonId: _id, chapterId });
    };

    renderBadgeColor = (): string => {
        const { essay_status } = this.props;
        switch (essay_status) {
            case 'NOT_ATTEMPTED':
                return 'grey';
            case 'REVIEW_PENDING':
                return 'orange';
            case 'RE_ATTEMPT_PENDING':
                return 'red';
            case 'REVIEW_COMPLETE':
                return 'green';
            default:
                return '';
        }
    };

    renderTooltipContent = (): string => {
        const { essay_status } = this.props;
        switch (essay_status) {
            case 'NOT_ATTEMPTED':
                return 'Essay attempt is required!';
            case 'REVIEW_PENDING':
                return 'Essay attempt is under review!';
            case 'RE_ATTEMPT_PENDING':
                return 'Essay attempt is marked incorrect & requires re-attempt!';
            case 'REVIEW_COMPLETE':
                return 'Essay attempt is marked correct!';
            default:
                return '';
        }
    };

    render() {
        const { title, lessonType, draft, isAdminPreview, isDraft, unlocked, essay_status } = this.props;

        return (
            <div
                onClick={this.handleLessonChange}
                className={`navigation-lesson ${this.isLessonAvailable ? 'available' : ''}`}
            >
                <div
                    className={`icon-container ${
                        this.isCurrentLesson ? 'active' : this.isLessonCompleted ? 'completed' : ''
                    }`}
                >
                    {this.icon}
                </div>
                <div className='lesson-title'>
                    {isAdminPreview && isDraft ? draft.title : title}
                    <sup>
                        {unlocked && essay_status && (
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`tooltip-${essay_status}`} className={`abs-tooltip`}>
                                        {this.renderTooltipContent()}
                                    </Tooltip>
                                }
                            >
                                <Fa
                                    style={{
                                        paddingLeft: '2px',
                                        color: this.renderBadgeColor(),
                                    }}
                                    icon={faGraduationCap}
                                    size='lg'
                                />
                            </OverlayTrigger>
                        )}
                    </sup>
                </div>

                {isAdminPreview && isDraft
                    ? draft.lessonType === 'STATE' && (
                          <div className='lesson-type-icon state'>
                              <i className='fa fa-university' />
                          </div>
                      )
                    : lessonType === 'STATE' && (
                          <div className='lesson-type-icon state'>
                              <i className='fa fa-university' />
                          </div>
                      )}
                {isAdminPreview && isDraft
                    ? draft.lessonType === 'NATIONAL' && (
                          <div className='lesson-type-icon national'>
                              <i className='fa fa-flag-checkered' />
                          </div>
                      )
                    : lessonType === 'NATIONAL' && (
                          <div className='lesson-type-icon national'>
                              <i className='fa fa-flag-checkered' />
                          </div>
                      )}
            </div>
        );
    }
}

export default withRouter(Lesson);
