import React from 'react';
import { useNavigate, useLocation, useParams, useMatch, Params } from 'react-router-dom';

export interface RouteComponentProps<P = Params> {
    navigate: ReturnType<typeof useNavigate>;
    location: ReturnType<typeof useLocation>;
    params: P;
    match: ReturnType<typeof useMatch>;
}

export function withRouter(Component: React.ComponentType<any>) {
    return function ComponentWithRouterProps(props: any) {
        const navigate = useNavigate();
        const location = useLocation();
        const params: Params = useParams();
        const match = useMatch('*');
        return <Component {...props} navigate={navigate} location={location} params={params} match={match} />;
    };
}
