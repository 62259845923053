import React, { Component } from 'react';
import FillGapQuestion from './FillGapQuestion/FillGapQuestion';
import RadioQuestion from './RadioQuestion/RadioQuestion';
import { IQuestion } from '../Page/Inline/Inline.quiz';
import './Question.scss';

interface IProps {
    questionIndex: number;
    question: IQuestion;
    answer?: number;
    handleAnswer: (answer: number, questionIndex: number) => void;
    revealAnswers: boolean;
    isQuizInReview: boolean;
}

export default class Question extends Component<IProps> {
    get results(): { correct: boolean; messageClass: string; feedback: string; answerMessage: string } {
        const { question, answer, revealAnswers } = this.props;
        const results = {
            correct: false,
            messageClass: '',
            feedback: '',
            answerMessage: '',
        };

        if (answer === undefined) {
            return {
                correct: false,
                messageClass: 'question-message--wrong',
                feedback: question.incorrectFeedback ?? '',
                answerMessage: '',
            };
        }

        const correct = question.correctOptionIndex === answer;
        results.correct = correct;

        if (correct) {
            results.messageClass = 'question-message--correct';
            results.feedback = question.correctFeedback ?? '';
        } else {
            results.messageClass = 'question-message--wrong';
            results.feedback = question.incorrectFeedback ?? '';
        }
        return results;
    }

    render() {
        const { question, answer, questionIndex, revealAnswers, isQuizInReview } = this.props;
        const { correct, messageClass, feedback, answerMessage } = this.results;

        return (
            <>
                <div className='question'>
                    {question.title.includes('___') ? (
                        <FillGapQuestion
                            questionIndex={questionIndex}
                            question={question}
                            answer={answer}
                            handleAnswer={this.props.handleAnswer}
                            readOnly={!!isQuizInReview}
                            revealAnswers={revealAnswers}
                            correct={correct}
                        />
                    ) : (
                        <RadioQuestion
                            questionIndex={questionIndex}
                            question={question}
                            answer={answer}
                            handleAnswer={this.props.handleAnswer}
                            readOnly={!!isQuizInReview}
                            revealAnswers={revealAnswers}
                            correct={correct}
                        />
                    )}
                </div>
                {isQuizInReview && feedback && (
                    <div className={`question-message ${messageClass}`}>
                        <p className='feedback-container'>
                            {feedback} {answerMessage}
                        </p>
                    </div>
                )}
            </>
        );
    }
}
