import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';
import './NameAvatar.scss';

interface IProps {
    className?: string;
    firstName: string;
    lastName: string;
    id?: string;
}
interface IState {}

class NameAvatar extends Component<IProps, IState> {
    state: IState = {};

    //TODO: Keep it for further uses
    /* getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }; */

    getName = () => {
        const { firstName, lastName } = this.props;
        if (firstName && lastName) {
            return firstName.charAt(0) + lastName.charAt(0);
        } else if (firstName) {
            return firstName.slice(0, 2);
        } else if (lastName) {
            return lastName.slice(0, 2);
        }

        return '';
    };

    render() {
        return (
            <Badge id={this.props.id ?? ''} className={`profile-avatar ${this.props.className ?? ''}`}>
                {this.getName().toUpperCase()}
            </Badge>
        );
    }
}

export default NameAvatar;
