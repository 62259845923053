import React, { Component } from 'react';
import './PreviousAttempts.scss';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import { IExamAttempt } from '../Exam.slide';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { IExamContent } from '../../Exam';
const fileDownload = require('js-file-download');
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import ReactTooltip from 'react-tooltip';
import { EventBus } from 'src/helpers/new';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import Countdown from 'react-countdown';

interface IProps {
    course: any;
    examAttempts: IExamAttempt[];
    current: IExamContent;
    loadExamData: () => void;
    certificateData: {
        url: string;
        fileName: string;
    };
}

interface IState {
    attempts: IExamAttempt[];
    endTime: number;
}
export default class PreviousAttempts extends Component<IProps, IState> {
    state: IState = {
        attempts: [],
        endTime: Date.now() + this.timeRemaining,
    };

    get timeRemaining(): number {
        const time =
            this.props.current?.remainingTime && this.props.current?.remainingTime > 0
                ? this.props.current?.remainingTime
                : 0;
        return time;
    }

    getExamStatus = (attempt: IExamAttempt) => {
        const { course } = this.props;
        if (attempt.status === 'SUCCESS' && (course.status === 'EXAM_PASSED' || course.status === 'FINISHED')) {
            if (!isNil(course?.examReviewStatus) && ['APPROVED', 'NOT_REQUIRED'].includes(course?.examReviewStatus)) {
                return 'Passed';
            } else {
                return 'In review';
            }
        } else if (attempt.status === '' && attempt.spentTime <= attempt.timeLimit) {
            return 'In progress';
        } else if (attempt.status === 'NULLIFIED') {
            return 'Nullified';
        } else {
            return 'Failed';
        }
    };

    componentDidMount(): void {
        this.setState({ attempts: this.props.examAttempts });
    }

    downloadCertificate = () => {
        const { certificateData } = this.props;
        if (certificateData.url) {
            axios
                .get(certificateData.url, {
                    responseType: 'blob',
                })
                .then((res: any) => {
                    fileDownload(res.data, certificateData.fileName);
                });
        }
    };

    renderReviewStatus = (attempt: IExamAttempt) => {
        if (this.getExamStatus(attempt) === 'In review') {
            return (
                <div className='review-status-container'>
                    <h6 className='status'>
                        <ReactTooltip effect='solid' multiline={true} />
                        <span>{this.getExamStatus(attempt)}</span>

                        <i
                            className='fa fa-circle-question'
                            data-tip='This exam is being reviewed.Your results will be available within 24 hours of submission'
                        />
                    </h6>
                </div>
            );
        }
        return <h6 className='status'>{this.getExamStatus(attempt)}</h6>;
    };

    enterInExam = () => {
        EventBus.dispatch('enter-exam-screen');
    };

    handleTimeExpired = () => {
        this.props.loadExamData();
    };

    getRemainingTime = () => {
        return (
            <>
                <Countdown
                    date={this.state.endTime}
                    onComplete={this.handleTimeExpired}
                    renderer={({ hours, minutes, seconds }) => {
                        return (
                            <span>
                                {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:
                                {seconds.toString().padStart(2, '0')}
                            </span>
                        );
                    }}
                />
                <span>{` time remaining`}</span>
            </>
        );
    };

    renderAttempt = (attempt: IExamAttempt, idx: number) => {
        const { certificateData } = this.props;
        return (
            <div className='quiz-attempt' key={idx}>
                <div
                    className={`colour-bar ${attempt.status?.toLowerCase()} ${this.getExamStatus(attempt)
                        .replace(/\s+/g, '-')
                        .toLowerCase()}`}
                />
                {this.renderReviewStatus(attempt)}
                <span className='percentage-score'>
                    {attempt.status ? attempt.percentageScore?.cleanRound(2) + `%` : '---'}
                </span>
                <span className='completed-date'>
                    {attempt.status
                        ? DateTimeFormatHelper.format(attempt.completedAt?.toString() as string, 'MM/DD/YYYY, hh:mm:ss')
                        : this.getRemainingTime()}
                </span>
                <div className='button-width'>
                    {this.getExamStatus(attempt) === 'In progress' && (
                        <Button className='bp' onClick={this.enterInExam}>
                            Resume Exam
                        </Button>
                    )}
                    {attempt.status === 'SUCCESS' && this.getExamStatus(attempt) === 'Passed' && (
                        <ConditionalWrapper
                            condition={isNil(certificateData.url)}
                            wrapper={(children: any) => (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`tooltip-next-lesson`}>
                                            {'Certificate not available for this course!'}
                                        </Tooltip>
                                    }
                                >
                                    {children}
                                </OverlayTrigger>
                            )}
                        >
                            <Button
                                className={`bp ${isNil(certificateData.url) ? 'exam-certificate-btn--disabled' : ''}`}
                                onClick={this.downloadCertificate}
                            >
                                Certificate <Fa icon={faDownload} />
                            </Button>
                        </ConditionalWrapper>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const { attempts } = this.state;
        return <div className='quiz-previous-attempts'>{attempts?.map(this.renderAttempt)}</div>;
    }
}
