export default class PackageHelper {
    static checkImageSquare = async (url: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;

            img.onload = function () {
                if (img.width === img.height) {
                    resolve('square-image');
                } else {
                    resolve('non-square-image');
                }
            };
        });
    };

    static getImagePromise(data: any[]) {
        return data.map(async (val) => {
            try {
                const result = await this.checkImageSquare(val?.image?.[2]);
                if (typeof result === 'string') {
                    return {
                        _id: val._id,
                        className: result ?? '',
                    };
                } else {
                    return {
                        _id: '',
                        className: 'not-vaild-image',
                    };
                }
            } catch (error) {
                return {
                    _id: '',
                    className: 'image-error',
                };
            }
        });
    }
}
