import React, { Component } from 'react';
import { RouteComponentProps } from 'src/hoc/withRouter';
import CourseContext from '../../CourseContext';
import { Api, EventBus } from 'src/helpers/new';
import InlineExam from './Inline/Exam.Inline';
import './Exam.scss';
import SlideExam, { IExamAttempt } from './Card/Exam.slide';
import { Spinner } from 'src/components/Spinner';
import { ILoggedIn, IUserLoggedIn } from 'src/layouts/Main/UserBar/Menus/ProfilePopOver';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import * as Sentry from '@sentry/react';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

export interface ExamQuestion {
    msgIfCorrect: string;
    msgIfWrong: string;
    options: string[];
    title: string;
}
export interface IExamContent {
    pausedAt: string[];
    startedAt: string[];
    _id: string;
    answers?: any;
    spentTime: number;
    userCourseId?: string;
    title: string;
    questions: ExamQuestion[];
    timeLimit: number;
    allowSkip?: boolean;
    examType: string;
    passPct?: number;
    percentageScore?: number;
    revealAnswers?: string;
    review?: string;
    score?: number;
    status?: string;
    timeoutAction?: string;
    totalMarks?: number;
    questionSubsetCount: number;
    userId?: string;
    previousAttempts?: any[];
    examPassed?: boolean;
    createdAt: string;
    remainingTime: number;
}

interface IProps {}
interface IState {
    current: IExamContent;
    bioSightOkay: boolean;
    lastAttempts: IExamAttempt[];
    isExamLoading: boolean;
    certificateData: {
        url: string;
        fileName: string;
    };
}

type TProps = ILoggedIn & RouteComponentProps<IRouteProps>;

class Exam extends Component<TProps, IState> {
    static readonly contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;

    state: IState = {
        current: {
            pausedAt: [],
            title: '',
            startedAt: [],
            questionSubsetCount: 0,
            questions: [],
            timeLimit: 0,
            _id: '',
            createdAt: '',
            examType: 'slide',
            remainingTime: 0,
            spentTime: 0,
        },
        lastAttempts: [],
        bioSightOkay: true,
        isExamLoading: true,
        certificateData: {
            url: '',
            fileName: '',
        },
    };

    async componentDidMount() {
        const { bioSight } = this.context.course.proctoringSettings;
        this.initSocketsEvents();
        EventBus.on('exit-exam', this.exitExamWithoutPopup);
        if (bioSight !== 'none') {
            this.intialiseBeforeExamBiosig();
            EventBus.on('biosight-okay', this.bioSightOkay);
            EventBus.on('re-intialise-proctoring', this.initialiseProctoring);
            EventBus.on('biosight-flag', this.bioSightFlag);
        } else {
            this.loadExamData();
        }
    }

    componentWillUnmount(): void {
        EventBus.remove('biosight-okay', this.bioSightOkay);
        EventBus.remove('biosight-flag', this.bioSightFlag);
        EventBus.remove('re-intialise-proctoring', this.initialiseProctoring);
        EventBus.remove('exit-exam', this.exitExamWithoutPopup);
        window.socket.off('exam submited');
        this.unsetLeaveWarning();
    }

    initialiseProctoring = () => {
        try {
            const { bioSight } = this.context.course.proctoringSettings;

            if (bioSight === 'biosight') {
                setTimeout(() => {
                    if (this.context.updateCourseInfo) {
                        this.context.updateCourseInfo('biosightInfo', null);
                    }
                    EventBus.dispatch('start-exam-proctoring', { callback: this.loadExamData });
                }, 0);
            } else {
                this.loadExamData();
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    exitExamWithoutPopup = (e?: any) => {
        this.unsetLeaveWarning();
        this.setState({ isExamLoading: true }, () => {
            if (e?.detail?.pathname) {
                this.props.navigate(`${e?.detail?.pathname}`);
            } else {
                this.props.navigate(`/courses/${this.props.params.courseId}/exam-summary`);
            }
        });
    };

    intialiseBeforeExamBiosig = () => {
        const { bioSight } = this.context.course.proctoringSettings;

        const enrolledBioSigTypes = this.props.loggedIn?.user?.enrolledBioSigTypes ?? [];

        if (bioSight === 'biosight' && enrolledBioSigTypes.includes('biosig')) {
            this.initialiseProctoring();
        } else {
            setTimeout(() => {
                EventBus.dispatch('require-auth', { stage: 'beforeExam', callback: this.initialiseProctoring });
            }, 0);
        }
    };

    loadExamData = async (sessionId?: string, examId?: string) => {
        this.setState({ isExamLoading: true });
        const { success, response } = await Api.call(
            'GET',
            `/users/exam/${this.props.params.courseId}?isNewExamRequired=${true}&sessionId=${
                sessionId ?? ''
            }&examId=${examId ?? ''}`,
        );

        if (success) {
            response.current = { ...response.current };
            response.current.examType = response.mainCourse.examType ?? 'slide';

            const noPassedExam = !(response?.current?.completedAt || response?.completedAt);
            if (noPassedExam) {
                const noCurrentExam = this.state.current?.startedAt?.length === 0;
                if (noCurrentExam) {
                    window.socket.emit('start exam', response.current?._id);
                }
            }

            this.setState({
                ...response,
                isExamLoading: false,
            });
        } else {
            this.exitExamWithoutPopup();
        }
    };

    initSocketsEvents = () => {
        window.socket.on('exam started', (startedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    startedAt,
                },
            });
            this.setLeaveWarning();
        });

        window.socket.on('exam submited', (response) => {
            if (response.success) {
                const status = response.data?.lastAttempts?.[0]?.status ?? response.data.status;
                const review = response.data?.lastAttempts?.[0]?.review ?? response.data.review;
                EventBus.dispatch('toast', {
                    type: status === 'FAIL' ? 'error' : 'success',
                    message:
                        status === 'FAIL'
                            ? 'You have failed your exam'
                            : review === 'NOT_REQUIRED'
                              ? 'You have passed your exam'
                              : 'Your exam is being reviewed. Your results will be available within 24 hours of submission',
                });
                this.redirectToPostExam();
            } else {
                EventBus.dispatch('toast', { type: 'error', message: 'response.message' });
            }
        });
    };

    redirectToPostExam = () => {
        this.unsetLeaveWarning();
        EventBus.dispatch('enter-post-exam');
    };

    setLeaveWarning = () => {
        window.addEventListener('beforeunload', this.leaveWarningCallback);
    };

    unsetLeaveWarning = () => {
        window.removeEventListener('beforeunload', this.leaveWarningCallback);
    };

    leaveWarningCallback(event: any) {
        const e = event || window.event;
        e.returnValue = '';
        e.preventDefault();
    }

    bioSightOkay = () => {
        this.setState({ bioSightOkay: true });
    };

    bioSightFlag = () => {
        this.setState({ bioSightOkay: false });
    };

    renderExamContent() {
        switch (this.state.current.examType) {
            case 'inline':
                //NOTE: No Longer being used and maintain as from the admin only slide option is there for Exam Type
                return <InlineExam current={this.state.current} bioSightOkay={this.state.bioSightOkay} />;
            case 'slide':
                return (
                    <SlideExam
                        current={this.state.current}
                        bioSightOkay={this.state.bioSightOkay}
                        loadExamData={this.loadExamData}
                        lastAttempts={this.state.lastAttempts}
                    />
                );
            default:
                return <></>;
        }
    }

    render() {
        if (this.state.isExamLoading) return <Spinner />;
        return this.renderExamContent();
    }
}

export default withRouterAndRedux(
    Exam,
    (state: any) => {
        return { loggedIn: state.loggedIn };
    },
    {
        setLoggedIn: (payload: IUserLoggedIn) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
    },
);
