import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import TaxCalculation from './TaxCalculation';
import CheckoutContext from '../CheckoutContext';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import './OrderSummary.scss';

interface IProps extends RouteComponentProps {
    getCartId: any;
    getIsLoggedInFlag: any;
    isSinglePageCheckout: boolean;
}
interface IState {}

class OrderSummary extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;
    state: IState = {};

    render() {
        const { couponDiscount, promoDiscount, subTotal, totalPrice } = this.context.getOrderDetails();
        return (
            <Row
                className={`calculation-wrapper ${
                    this.props.isSinglePageCheckout ? 'single-page-calculation-wrapper' : ''
                }`}
            >
                <Col className='col-wrapper'>
                    <span className='bold-text'>Sub Total</span>
                    <span> {global.USCurrency.format(subTotal)}</span>
                </Col>
                <hr />
                {couponDiscount ? (
                    <Col className='col-wrapper'>
                        <span>Discount Code</span>
                        <span className='discount-code'>{global.USCurrency.format(couponDiscount)}</span>
                    </Col>
                ) : null}
                {promoDiscount ? (
                    <Col className='col-wrapper'>
                        <span>Promo discount</span>
                        <span className='discount-code'>{global.USCurrency.format(promoDiscount)}</span>
                    </Col>
                ) : null}
                <TaxCalculation
                    couponDiscount={couponDiscount}
                    promoDiscount={promoDiscount}
                    totalPrice={totalPrice}
                    getCartId={this.props.getCartId}
                    getIsLoggedInFlag={this.props.getIsLoggedInFlag}
                    isSinglePageCheckout={this.props.isSinglePageCheckout}
                />
            </Row>
        );
    }
}

export default withRouter(OrderSummary);
