import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './NmlsUserIdModal.scss';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { Utility } from 'src/helpers/new';

interface IProps extends RouteComponentProps {
    showNmlsUserIdPrompt: boolean;
    submitModal: (value: string) => void;
}

interface IState {
    nmlsUserId: string;
    nmlsIdDescriptionVisible: boolean;
    whereNmlsIdFoundDescriptionVisible: boolean;
}

class NmlsUserIdModal extends Component<IProps, IState> {
    state: IState = {
        nmlsUserId: '',
        nmlsIdDescriptionVisible: false,
        whereNmlsIdFoundDescriptionVisible: false,
    };

    toggleWhyNmlsIdDescription = () => {
        this.setState((prevState) => ({
            ...prevState,
            nmlsIdDescriptionVisible: !prevState.nmlsIdDescriptionVisible,
        }));
    };

    toggleWhereNmlsIdFoundDescription = () => {
        this.setState((prevState) => ({
            ...prevState,
            whereNmlsIdFoundDescriptionVisible: !prevState.whereNmlsIdFoundDescriptionVisible,
        }));
    };

    validateNmlsField = (value?: string) => {
        if (!value) {
            value = this.state.nmlsUserId?.trim();
        }

        return Utility.validateNmlsUserId(value);
    };

    render() {
        const { nmlsIdDescriptionVisible, nmlsUserId, whereNmlsIdFoundDescriptionVisible } = this.state;
        const { showNmlsUserIdPrompt, submitModal } = this.props;

        return (
            <Modal size={'lg'} show={showNmlsUserIdPrompt} className={`nmls-user-id-modal`}>
                <Modal.Body>
                    <h4>
                        <b>{`NMLS ID required`}</b>
                    </h4>
                    <hr />
                    <p>{`To earn NMLS credit for this course, please enter your NMLS ID:`}</p>
                    <Form.Control
                        type='text'
                        id='nmlsUserId'
                        name='nmlsUserId'
                        value={nmlsUserId ?? ''}
                        className={!this.validateNmlsField() ? 'form-builder__error' : ''}
                        onChange={(e) => {
                            this.setState({
                                nmlsUserId: e.target.value,
                            });
                        }}
                    />
                    {!this.validateNmlsField() && (
                        <small className={'mt-3 form-builder__error-color'}>
                            {`Please provide a number between 4 and 9 digits.`}
                        </small>
                    )}
                    <hr />
                    <div>
                        <p>
                            <b>{`Why do I need an NMLS ID?`}</b>
                            {'  '}
                            <i
                                onClick={this.toggleWhyNmlsIdDescription}
                                className={`fa fa-chevron-${nmlsIdDescriptionVisible ? 'right' : 'down'}`}
                            />
                        </p>
                        <p className={nmlsIdDescriptionVisible ? 'visible' : 'hidden'}>
                            {`Many state agencies require an educational standard to become a Mortgage Loan Originator
                            within that state. All licensing requirements are processed via the Nationwide Mortgage
                            Licensing System (NMLS). By providing your NMLS ID, you will earn educational credit as an
                            MLO Agent.`}
                        </p>
                    </div>
                    <div className='mt-4'>
                        <p>
                            <b>{`Where do I find my NMLS ID?`}</b>
                            {'  '}
                            <i
                                onClick={this.toggleWhereNmlsIdFoundDescription}
                                className={`fa fa-chevron-${whereNmlsIdFoundDescriptionVisible ? 'right' : 'down'}`}
                            />
                        </p>
                        <div className={whereNmlsIdFoundDescriptionVisible ? 'visible' : 'hidden'}>
                            <p>
                                {`If you already have an NMLS ID, you can search the NMLS' records by Name, City, State,
                                and/or Zip Code here: `}
                                <a href='https://nmlsconsumeraccess.org/' target='_blank' rel='noreferrer'>
                                    {`https://nmlsconsumeraccess.org/`}
                                </a>
                            </p>
                            <p>
                                {`If you don't already have an NMLS ID, you can request an individual account here: `}
                                <a
                                    href='https://www.statemortgageregistry.com/Public/RequestAccount.aspx'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    {`https://www.statemortgageregistry.com/Public/RequestAccount.aspx
                                `}
                                </a>
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='primary'
                        onClick={() => {
                            submitModal(nmlsUserId?.trim());
                        }}
                        disabled={nmlsUserId.trim() && this.validateNmlsField() ? false : true}
                        className='bp'
                    >
                        {'Continue'}
                    </Button>
                    <Button
                        variant='primary'
                        onClick={() => {
                            this.props.navigate('/', { replace: true });
                        }}
                        className=''
                    >
                        {'Go Back'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withRouter(NmlsUserIdModal);
