import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import ErrorPage from '../components/CustomError/ErrorPage';
interface IProps {
    children?: ReactNode;
}
interface IState {
    error: any;
    errorInfo: any;
}

export default class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: any, errorInfo: any) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key: any) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.error) return <ErrorPage />;
        return this.props.children;
    }
}
