import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import './LessonSkeleton.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export default class LessonsSkeleton extends Component {
    render() {
        return (
            <div className='lesson-skeleton'>
                <Skeleton duration={1} circle className='circle-skeleton' />
                <Skeleton duration={1} className='normal-skeleton' />
            </div>
        );
    }
}
