import { startCase } from 'lodash';
import React, { Component } from 'react';
import { Api, EventBus } from 'src/helpers/new';
import CouponArea from '../OrderSummary/CouponArea';
import CheckoutContext from '../../CheckoutContext';
import './OrderSummary.scss';
import { withRouter } from 'src/hoc/withRouter';
import { ConfirmationModal } from 'src/components/ConfirmationModal';
import PackageHelper from 'src/helpers/PackageHelper';
import PropTypes from 'prop-types';
class OrderSummary extends Component {
    static contextType = CheckoutContext;

    state = {
        taxes: 0,
        imageSetCount: 0,
        imageArr: [],
        deleteModalShow: false,
        deletePkgId: '',
        imageClasses: [],
    };

    async componentDidMount() {
        EventBus.on('set-tax-value', this.updateTaxString);

        if (!this.context.packages[0]?.packagePurchaseType && !Array.isArray(this.context.packages[0]?.image)) {
            await this.context.packages.map(async (data) => {
                const imgUrl = await this.getImageUrl(data.course[0].image);
                this.setState((prevState) => ({
                    imageArr: [...prevState.imageArr, imgUrl],
                }));
            });
        }

        const resolvedImgClasses = await Promise.all(PackageHelper.getImagePromise(this.context.packages));
        if (resolvedImgClasses.length > 0) {
            this.setState({
                imageClasses: resolvedImgClasses,
            });
        }
    }

    componentWillUnmount() {
        EventBus.remove('set-tax-value', this.updateTaxString);
    }

    updateTaxString = (event) => {
        const taxes = event.detail;
        this.setState({
            taxes,
        });
    };

    removePackageFromCart = async () => {
        const id = this.state.deletePkgId;

        const query = new URLSearchParams(this.props.location.search);
        const packageIdInUrl = query.get('packageId');

        const filteredPackages = this.context.packages.filter(
            (pkg) => pkg?._id !== id && pkg.hasOwnProperty('packagePurchaseType'),
        );
        const filteredCourses = this.context.packages.filter(
            (course) => course?._id !== id && course.hasOwnProperty('purchaseType'),
        );

        if (id === packageIdInUrl) {
            this.props.navigate('/checkout', { replace: true });
        }

        this.context.updateCart({ packages: filteredPackages, courses: filteredCourses });
        if ([...filteredPackages, ...filteredCourses].length <= 0) {
            if (this.context.isAdminPreview) {
                localStorage.removeItem('reuPreviewCheckoutCartId');
            } else {
                localStorage.removeItem('reuCheckoutCartId');
            }

            localStorage.removeItem('__paypal_storage__');
        }
    };

    getImageUrl = async (id) => {
        const { response } = await Api.call('get', `files/${id}`);
        return response.url[2];
    };

    openDeleteModal = (id) => {
        this.setState({
            deleteModalShow: true,
            deletePkgId: id,
        });
    };

    deleteModalAction = () => {
        this.removePackageFromCart();
        this.setState({
            deleteModalShow: false,
            deletePkgId: '',
        });
    };

    renderImage = (pkg, imgClassObj) => {
        let imgPath = pkg?.image?.[2];

        if (pkg?.purchaseType === 'chat') {
            imgPath = process.env.PUBLIC_URL + '/checkout-chat-icon.png';
        } else if (pkg?.purchaseType === 'proctorPass') {
            imgPath = process.env.PUBLIC_URL + '/checkout-proctor-pass.png';
        } else if (pkg?.purchaseType === 'extension') {
            imgPath = process.env.PUBLIC_URL + '/checkout-extension.png';
        }
        return (
            <div className='imgWrap'>
                <img src={imgPath} alt={pkg.title} className={imgClassObj?.className} />
            </div>
        );
    };

    render() {
        const { currentTab, packages } = this.context;
        if (![0].includes(currentTab)) {
            return <></>;
        }

        let totalPrice = 0;
        let couponDiscount = 0;
        let promoDiscount = 0;
        let subTotal = 0;

        return (
            <>
                <div className='checkout-summary'>
                    <h2 className='heading'>Order Summary</h2>
                    <div className='products'>
                        {packages.map((pkg, index) => {
                            subTotal += pkg.price;
                            totalPrice += pkg.discountedPrice ?? pkg.price;
                            couponDiscount += pkg.couponDiscount ?? 0;
                            promoDiscount += pkg.promoDiscount ?? 0;
                            const imgClassObj = this.state.imageClasses.find((v) => v._id === pkg?._id);
                            const packageKey = pkg.packageId ?? pkg._id + (pkg.type ?? 'PACKAGE');

                            return (
                                <div key={packageKey} className='product'>
                                    {this.renderImage(pkg, imgClassObj)}
                                    <div className={`contentWrap`}>
                                        <div>
                                            {pkg.purchaseType === 'proctorPass' ? (
                                                <div>
                                                    <h3 className='name'>{`${startCase(pkg.purchaseType)} for "${
                                                        pkg.packageTitle
                                                    }"`}</h3>
                                                </div>
                                            ) : (
                                                <div>
                                                    <h3 className='name'>{pkg.title}</h3>
                                                    {pkg.purchaseType && <p>({startCase(pkg.purchaseType)})</p>}
                                                </div>
                                            )}
                                            <div className='detailsWrap'>
                                                <div className='details'>
                                                    <div className='details-row'>
                                                        <span className='price'>
                                                            {(pkg.discountedPrice || pkg.discountedPrice === 0) &&
                                                            pkg.discountedPrice !== pkg.price ? (
                                                                <>
                                                                    <span className='old'>
                                                                        {global.USCurrency.format(pkg.price)}
                                                                    </span>
                                                                    {global.USCurrency.format(pkg.discountedPrice)}
                                                                </>
                                                            ) : (
                                                                <>{global.USCurrency.format(pkg.price)}</>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='order-package-controls'>
                                            <button
                                                className='delete'
                                                onClick={() => {
                                                    this.openDeleteModal(pkg._id);
                                                }}
                                            >
                                                <i className='fa fa-trash fa-sm' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                        <div className={`total ${currentTab === 4 && 'total-top-margin'}`}>
                            {packages[0]?.packagePurchaseType && <CouponArea />}
                            <div className='total-summary'>
                                <div className='total-summary-item'>
                                    <span>
                                        <strong>SubTotal</strong>
                                    </span>
                                    <span>{global.USCurrency.format(subTotal)}</span>
                                </div>
                                {couponDiscount ? (
                                    <div className='total-summary-item'>
                                        <span>Discount Code</span>
                                        <span className='red-font'>-{global.USCurrency.format(couponDiscount)}</span>
                                    </div>
                                ) : null}
                                {promoDiscount ? (
                                    <div className='total-summary-item'>
                                        <span>Promo discount</span>
                                        <span className='red-font'>-{global.USCurrency.format(promoDiscount)}</span>
                                    </div>
                                ) : null}
                                {this.state.taxes && currentTab !== 0 ? (
                                    <div className='total-summary-item'>
                                        <span>Tax</span>
                                        <span>{global.USCurrency.format(this.state.taxes)}</span>
                                    </div>
                                ) : null}
                                <div className='total-summary-item total-item'>
                                    <span>
                                        <strong>Total</strong>
                                    </span>
                                    <span>
                                        <strong>{global.USCurrency.format(totalPrice + this.state.taxes)}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ConfirmationModal
                    show={this.state.deleteModalShow}
                    size={'lg'}
                    hideModal={() => {
                        this.setState({ deleteModalShow: false });
                    }}
                    confirmAction={this.deleteModalAction}
                    titleText={'Are you sure?'}
                    bodyText={['Are you sure you would like to remove this package from the cart ?']}
                />
            </>
        );
    }
}

OrderSummary.propTypes = {
    location: PropTypes.shape({
        search: PropTypes.string.isRequired,
        pathname: PropTypes.string,
        state: PropTypes.object,
    }).isRequired,
};

export default withRouter(OrderSummary);
