import React, { Component } from 'react';
import { Spinner } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { EventBus } from 'src/helpers/new';
import CheckoutContext from 'src/pages/Checkout/CheckoutContext';

type IProps = RouteComponentProps & {
    isPayment?: boolean;
};

interface IState {
    isInputActive: boolean;
    couponCode?: string;
    userCode?: string;
    isLoading: boolean;
}

class CouponArea extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;

    prevContext: any;

    state: IState = {
        isInputActive: !this.context?.couponCode,
        couponCode: this.context?.couponCode ?? '',
        userCode: this.context?.couponCode ?? '',
        isLoading: false,
    };

    async componentDidMount() {
        this.prevContext = this.context;
    }

    componentDidUpdate() {
        if (this.context?.couponCode !== this.prevContext?.couponCode) {
            this.setState({
                couponCode: this.context?.couponCode ?? '',
                userCode: this.context?.couponCode ?? '',
                isInputActive: !this.context?.couponCode,
            });
        }
        this.prevContext = this.context;
    }

    preventSpaceInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === 'Space') {
            e.preventDefault();
            return false;
        }

        if (e.key === 'Enter') {
            this.applyCoupon();
        }
    };

    onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            userCode: e.target.value,
        });
    };

    removeCouponCode = async (): Promise<void> => {
        this.context.updateCart({ couponCode: null });

        this.setState({
            isInputActive: true,
            userCode: '',
        });
    };

    applyCouponFail = async () => {
        this.setState({
            isInputActive: true,
            userCode: '',
            isLoading: false,
        });
    };

    applyCoupon = async (couponCode: string = this.state.userCode ?? '') => {
        this.setState({ isLoading: true });
        this.context.updateCart({ couponCode }, this.applyCouponFail, this.applyCouponSuccess, true);
    };

    applyCouponSuccess = (raw: any) => {
        this.setState({
            isInputActive: false,
            couponCode: raw.data.couponCode,
            isLoading: false,
        });
    };

    renderAppliedCode = () => {
        const { couponCode } = this.state;
        return (
            <div className='applied-coupon-wrapper'>
                <span className='applied-coupon-name'>{couponCode}</span>
                <button className='applied-coupon-remove-icon' onClick={this.removeCouponCode}>
                    <i className='fa fa-xmark fa-xl' />
                </button>
            </div>
        );
    };

    renderButton = (buttonText: string, btnClz: string) => {
        const { isInputActive, userCode } = this.state;
        return (
            <button
                className={`${btnClz} apply-code-button ${
                    userCode && isInputActive ? 'button-visible' : 'button-hidden'
                }`}
                onClick={() => {
                    if (userCode) {
                        this.applyCoupon();
                    } else {
                        EventBus.dispatch('toast', {
                            type: 'error',
                            message: 'Please add a discount code to apply!',
                        });
                    }
                }}
            >
                {this.state.isLoading ? (
                    <>
                        <Spinner animation='border' size='sm' />
                        &nbsp;applying
                    </>
                ) : (
                    `${buttonText}`
                )}
            </button>
        );
    };

    render() {
        const { isInputActive, couponCode, userCode } = this.state;

        if (this.props.isPayment) {
            return (
                <div className={`apply-coupon-wrapper ${couponCode && !isInputActive ? 'applied-code-wrapper' : ''}`}>
                    <div className='code-title-wrapper'>
                        <i className='fa-solid fa-ticket me-1' />
                        <span className='coupon-question'>
                            {!couponCode || isInputActive ? 'Apply' : 'Applied'} Discount code
                        </span>
                    </div>
                    {!couponCode || isInputActive ? (
                        <div className='coupon coupon--inside-icon'>
                            <input
                                type='text'
                                value={userCode}
                                onKeyDown={this.preventSpaceInput}
                                onChange={this.onInputChange}
                                placeholder={'Enter discount code'}
                            />
                            {this.renderButton('Apply', 'btn bp btn--small')}
                        </div>
                    ) : (
                        this.renderAppliedCode()
                    )}
                </div>
            );
        }
        return (
            <div className={`apply-coupon-wrapper ${couponCode && !isInputActive ? 'applied-code-wrapper' : ''}`}>
                <div className='coupon'>
                    <span className='coupon-question'>Got a Discount code?</span>
                    {!couponCode || isInputActive ? (
                        <div className='coupon-input'>
                            <input
                                type='text'
                                value={userCode}
                                onKeyDown={this.preventSpaceInput}
                                onChange={this.onInputChange}
                                placeholder={'Enter discount code'}
                            />
                        </div>
                    ) : (
                        this.renderAppliedCode()
                    )}
                </div>
                {isInputActive && this.renderButton('Apply Code', '')}
            </div>
        );
    }
}

export default withRouter(CouponArea);
