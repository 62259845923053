import React, { Component } from 'react';
import { EventBus } from 'src/helpers/new';
import LessonContext from '../../LessonContext';
import { Modal } from 'react-bootstrap';
import { SwitchInput } from 'src/components/_New/Form/SwitchInput';
import './StudentPreviewControls.scss';

const resizeOptions: IOption[] = [
    {
        name: 'Desktop',
        width: 1920,
        height: 1080,
        mobile: false,
    },
    {
        name: 'Laptop',
        width: 1366,
        height: 768,
        mobile: false,
    },
    {
        name: 'Tablet',
        width: 810,
        height: 1080,
        mobile: true,
    },
    {
        name: 'Phone',
        width: 375,
        height: 667,
        mobile: true,
    },
];

interface IOption {
    name: string;
    width: number;
    height: number;
    isLandscape?: boolean;
    mobile: boolean;
}

interface IProps {
    isCheckout?: boolean;
}
interface IState {
    modalOpen: boolean;
    screenSize: string;
    test: boolean;
    isPresetScreenSize: boolean;
    isLandscape?: boolean;
    isMobile?: boolean;
}
export default class StudentPreviewControls extends Component<IProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    maxWidth = 0;
    maxHeight = 0;

    constructor(props: IProps) {
        super(props);

        this.maxHeight = window.screen.availHeight - window.screenX;
        this.maxWidth = window.screen.availWidth - window.screenY;

        const initialScreenSize = this.currentWindowSize;

        this.state = {
            modalOpen: false,
            test: true,
            screenSize: initialScreenSize?.name ?? 'Custom',
            isPresetScreenSize: !!initialScreenSize,
            isLandscape: initialScreenSize?.isLandscape && initialScreenSize?.mobile,
            isMobile: initialScreenSize?.mobile,
        };
    }

    componentDidMount(): void {
        EventBus.onWindow('resize', this.handleWindowResize);
    }

    componentWillUnmount(): void {
        EventBus.removeFromWindow('resize', this.handleWindowResize);
    }

    get currentWindowSize(): IOption | undefined {
        const option = resizeOptions.find((option: IOption) => {
            return (
                ((option.width === window.outerWidth ||
                    (option.width > this.maxWidth && window.outerWidth === this.maxWidth)) &&
                    (option.height === window.outerHeight ||
                        (option.height > this.maxHeight && window.outerHeight === this.maxHeight))) ||
                (option.mobile &&
                    (option.height === window.outerWidth ||
                        (option.height > this.maxWidth && window.outerWidth === this.maxWidth)) &&
                    (option.width === window.outerHeight ||
                        (option.width > this.maxHeight && window.outerHeight === this.maxHeight)))
            );
        });

        if (option && window.outerWidth > window.outerHeight) {
            option.isLandscape = true;
        }

        return option;
    }

    handleWindowResize = () => {
        if (!this.state.isPresetScreenSize) return;
        const screenSize = this.currentWindowSize;
        this.setState({ isPresetScreenSize: !!screenSize, screenSize: screenSize?.name ?? 'Custom' });
    };

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    };

    updateScreenSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const screenSize = e.target.value;
        const resizeOption = resizeOptions.find((option: IOption) => option.name === screenSize) ?? resizeOptions[0];
        let { height, width } = resizeOption;

        if (resizeOption.mobile && this.state.isLandscape) {
            height = resizeOption.width;
            width = resizeOption.height;
        }

        if (width >= this.maxWidth) {
            width = this.maxWidth;
        }

        if (height >= this.maxHeight) {
            height = this.maxHeight;
        }

        setTimeout(() => {
            this.setState({ screenSize, isPresetScreenSize: true, isMobile: resizeOption.mobile }, () => {
                window.resizeTo(width, height);
            });
        }, 100);
    };

    handleOrientationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!this.state.isPresetScreenSize || !this.state.isMobile) return;

        const orientation = e.target.value;
        const currentOption = resizeOptions.find((option: IOption) => option.name === this.state.screenSize) as IOption;
        let { height, width } = currentOption;

        if (orientation === 'landscape') {
            height = currentOption.width;
            width = currentOption.height;
        }

        if (width >= this.maxWidth) {
            width = this.maxWidth;
        }

        if (height >= this.maxHeight) {
            height = this.maxHeight;
        }

        setTimeout(() => {
            this.setState({ isLandscape: orientation === 'landscape' }, () => {
                window.resizeTo(width, height);
                window.innerWidth = width;
                window.innerHeight = height;
            });
        }, 100);
    };

    handleToggleChange = (name: string, value: boolean) => {
        switch (name) {
            case 'isDraft':
                if (this.context.updateIsDraft) {
                    this.context.updateIsDraft(!value);
                }
                break;
            case 'isSynced':
                if (this.context.updateIsSynced) {
                    this.context.updateIsSynced(value);
                }
                break;
        }
    };

    render() {
        const { modalOpen, screenSize, isLandscape, isMobile } = this.state;
        const { isSynced, isDraft } = this.context;

        return (
            <>
                <div className='student-preview-controls' onClick={this.toggleModal}>
                    <i className='fas fa-cogs' />
                </div>
                <Modal id='preview-controls-popup' show={modalOpen} onHide={this.toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Preview settings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='settings'>
                            <label>
                                Screen size*
                                <select
                                    name='screenSize'
                                    id='screenSize'
                                    onChange={this.updateScreenSize}
                                    value={screenSize}
                                >
                                    {!this.state.isPresetScreenSize && <option value='custom'>Custom</option>}
                                    {resizeOptions.map((option: IOption, index: number) => {
                                        return (
                                            <option key={index} value={option.name}>
                                                {option.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </label>
                            {this.state.isPresetScreenSize && isMobile && (
                                <label>
                                    Orientation
                                    <select
                                        name='orientation'
                                        id='orientation'
                                        onChange={this.handleOrientationChange}
                                        value={isLandscape ? 'landscape' : 'portrait'}
                                    >
                                        <option value='portrait'>Portrait</option>
                                        <option value='landscape'>Landscape</option>
                                    </select>
                                </label>
                            )}
                            {!this.props.isCheckout && (
                                <div className='toggles'>
                                    <SwitchInput
                                        name='isSynced'
                                        title='Synced'
                                        handleClick={this.handleToggleChange}
                                        checkedValue={isSynced}
                                    />
                                    <SwitchInput
                                        name='isDraft'
                                        title='Published'
                                        handleClick={this.handleToggleChange}
                                        checkedValue={!isDraft}
                                    />
                                </div>
                            )}

                            <div className='preview-message'>
                                * To preview your content across different screen sizes, ensure that the window is not
                                maximized.
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}
