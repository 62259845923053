import React, { Component } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Api, EventBus } from 'src/helpers/new';
import withContext from 'src/helpers/withContext';
import AccountContext, { IAccountContext } from '../AccountContext';
import usStates from '../../../helpers/usStates';
import './ProfileAddress.scss';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IState {
    loading: boolean;
    failedToLoad: boolean | string;
    _id: string;
    addressLineOne: string;
    addressLineTwo: string;
    townCity: string;
    state: string;
    zipCode: string;
    billingAddress: {
        state: string;
        country: string;
        streetLines: string[];
        zipCode: string;
        phoneNumber: string;
        town: string;
        firstName: string;
        lastName: string;
        isSameAsResidentialAddress: boolean;
    };
    isValid: boolean;
    message: any;
}

interface IProps {
    contextValue: IAccountContext;
    loggedIn: {
        APP_VERSION: string;
        token: string;
        user: any;
    };
}

class ProfileAddress extends Component<IProps, IState> {
    static contextType = AccountContext;
    context!: React.ContextType<typeof AccountContext>;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: true,
            failedToLoad: false,
            _id: '',
            isValid: true,
            addressLineOne: '',
            addressLineTwo: '',
            townCity: '',
            state: '',
            zipCode: '',
            billingAddress: {
                state: 'Alabama',
                country: 'US',
                streetLines: [''],
                zipCode: '',
                phoneNumber: '',
                town: '',
                firstName: '',
                lastName: '',
                isSameAsResidentialAddress: false,
            },
            message: '',
        };
    }

    async componentDidMount() {
        this.setState({
            ...this.context.detailsTabData,
            loading: false,
            failedToLoad: this.context.failedToLoadMsg,
        });
    }

    componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.contextValue.detailsTabData !== this.props.contextValue.detailsTabData) {
            this.setState({
                ...this.context.detailsTabData,
                loading: false,
                failedToLoad: this.context.failedToLoadMsg,
            });
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name as keyof typeof this.state;
        const val = e.target.value as keyof typeof this.state;

        this.setState({
            [name]: val,
        } as unknown as Pick<IState, keyof IState>);
    };

    validateForm = () => {
        const isValid = true;

        if (!isValid) {
            this.setState({
                isValid,
            });
        }

        return isValid;
    };

    handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const isValid = this.validateForm();

        if (!isValid) {
            return;
        }

        const { success, message } = await Api.call('PUT', '/users/profile', {
            addressLineOne: this.state.addressLineOne,
            addressLineTwo: this.state.addressLineTwo,
            townCity: this.state.townCity,
            state: this.state.state,
            zipCode: this.state.zipCode,
            billingAddress: this.state.billingAddress,
        });
        if (success) {
            EventBus.dispatch('toast', {
                type: 'success',
                message: message ?? 'Address details saved successfully!',
            });
        }
    };

    handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let billingAddress = this.state.billingAddress;
        if (e.target.checked) {
            billingAddress = {
                ...this.state.billingAddress,
                firstName: this.context.detailsTabData.firstName,
                lastName: this.context.detailsTabData.lastName,
                town: this.state.townCity,
                state: this.state.state,
                streetLines: [this.state.addressLineOne, this.state.addressLineTwo],
                zipCode: this.state.zipCode,
                isSameAsResidentialAddress: e.target.checked,
            };
        } else {
            billingAddress = {
                ...this.state.billingAddress,
                isSameAsResidentialAddress: e.target.checked,
            };
        }

        this.setState({
            billingAddress,
        });
    };

    handleBilingInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const billingAddress: any = { ...this.state.billingAddress };
        billingAddress[e.target.name] = e.target.value;

        this.setState({ billingAddress });
    };

    handleStreetLineInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const billingAddress = { ...this.state.billingAddress };
        billingAddress.streetLines[parseInt(e.target.name)] = e.target.value;

        this.setState({ billingAddress });
    };

    render() {
        const { message, billingAddress } = this.state;
        return (
            <section id='user-profile-address' className='user-profile-address'>
                <Row className='header-wrapper'>
                    <Col>
                        <h3>Your address</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'form' + (message ? ' form--with-error' : '')}>
                            {message && <Alert variant={message.type}>{message.text}</Alert>}
                            <div className='form__content'>
                                <form action='/' onSubmit={this.handleFormSubmit}>
                                    <div className='autofill-address-check-box'>
                                        <label htmlFor='autofill-address-check-box' className='check-label'>
                                            <input
                                                type='checkbox'
                                                name='autofill-address-check-box'
                                                id='autofill-address-check-box'
                                                checked={billingAddress.isSameAsResidentialAddress}
                                                onChange={this.handleCheckBoxChange}
                                            />
                                            &nbsp;&nbsp; Same as Residential address
                                        </label>
                                    </div>
                                    <Row className='address-wrapper'>
                                        <Col className='billing-block'>
                                            <h5>Billing address</h5>
                                            <div className='form__field'>
                                                <label htmlFor='addressLineOneBilling'>Address line 1</label>
                                                <input
                                                    type='text'
                                                    name='0'
                                                    onChange={this.handleStreetLineInputChange}
                                                    defaultValue={billingAddress.streetLines[0] ?? ''}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='addressLineTwoBilling'>Address line 2</label>
                                                <input
                                                    type='text'
                                                    name='1'
                                                    onChange={this.handleStreetLineInputChange}
                                                    defaultValue={billingAddress.streetLines[1] ?? ''}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='townCityBilling'>Town / City</label>
                                                <input
                                                    type='text'
                                                    name='town'
                                                    onChange={this.handleBilingInputChange}
                                                    defaultValue={billingAddress.town}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='stateBilling'>State</label>
                                                <select
                                                    className={`dropdown-input`}
                                                    name='state'
                                                    value={billingAddress.state}
                                                    onChange={this.handleBilingInputChange}
                                                >
                                                    <option value=''>Select a State</option>
                                                    {usStates.map(({ key, value }) => (
                                                        <option value={key} key={key}>
                                                            {key}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='zipCodeBilling'>ZipCode</label>
                                                <input
                                                    type='text'
                                                    name='zipCode'
                                                    onChange={this.handleBilingInputChange}
                                                    defaultValue={billingAddress.zipCode}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='countryBilling'>Country</label>
                                                <input
                                                    disabled
                                                    type='text'
                                                    name='countryBilling'
                                                    defaultValue='United States'
                                                />
                                            </div>
                                        </Col>
                                        <Col className='residential-block'>
                                            <h5>Residential address</h5>
                                            <div className='form__field'>
                                                <label htmlFor='addressLineOne'>Address line 1</label>
                                                <input
                                                    type='text'
                                                    name='addressLineOne'
                                                    onChange={this.handleInputChange}
                                                    defaultValue={this.state.addressLineOne}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='addressLineTwo'>Address line 2</label>
                                                <input
                                                    type='text'
                                                    name='addressLineTwo'
                                                    onChange={this.handleInputChange}
                                                    defaultValue={this.state.addressLineTwo}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='townCity'>Town / City</label>
                                                <input
                                                    type='text'
                                                    name='townCity'
                                                    onChange={this.handleInputChange}
                                                    defaultValue={this.state.townCity}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='state'>State</label>
                                                <select
                                                    className={`dropdown-input`}
                                                    name='state'
                                                    value={this.state.state}
                                                    onChange={this.handleInputChange}
                                                >
                                                    <option value=''>Select a State</option>
                                                    {usStates.map(({ key, value }) => (
                                                        <option value={key} key={key}>
                                                            {key}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='zipCode'>ZipCode</label>
                                                <input
                                                    type='text'
                                                    name='zipCode'
                                                    onChange={this.handleInputChange}
                                                    defaultValue={this.state.zipCode}
                                                />
                                            </div>
                                            <div className='form__field'>
                                                <label htmlFor='countryBilling'>Country</label>
                                                <input
                                                    disabled
                                                    type='text'
                                                    name='countryBilling'
                                                    defaultValue='United States'
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='form__buttons'>
                                        <button type='submit' className='btn bp'>
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default withRouterAndRedux(
    withContext(ProfileAddress, AccountContext),
    (state: any) => ({
        loggedIn: state?.loggedIn,
    }),
    null,
);
