import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import TextareaInput from 'src/components/_New/Form/TextareaInput';
import './CheckoutSurveyPrompt.scss';
import { Api } from 'src/helpers/new';
import { isEmpty } from 'lodash';

interface IProps {
    onSubmit: () => void;
    onClose: () => void;
    isShow: boolean;
    cartDetails: {
        cartId: string;
    };
}
export function CheckoutSurveyPrompt({ onSubmit, onClose, isShow, cartDetails }: Readonly<IProps>) {
    const [feedbackText, setFeedbackText] = useState('');
    const [error, setError] = useState<string>('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (isEmpty(feedbackText)) {
            setError('Please provide some feedback before submit !');
        } else {
            const { success } = await Api.call('POST', `checkout/survey/create`, {
                feedback: feedbackText,
                cartId: cartDetails.cartId,
            });

            if (success) {
                setFeedbackText('');
                setError('');
                if (onSubmit) {
                    onSubmit();
                }
            }
        }
    };

    const handleInputChange = (key: string, value: any) => {
        setError('');
        setFeedbackText(value);
    };

    return (
        <Modal show={isShow} onHide={onClose} backdrop={false} className='checkout-survey-modal'>
            <Modal.Header closeButton>
                <h4>{`Please share your thoughts`}</h4>
            </Modal.Header>
            <Modal.Body>
                <p className='survey-header'>{`Is there anything holding you back from completing your purchase? `}</p>
                <p className='survey-body'>{` Your feedback would be greatly appreciated.`}</p>

                <TextareaInput
                    value={feedbackText ?? ''}
                    handleChange={handleInputChange}
                    className={error ? 'form_error' : ''}
                />
                {error && <small className={'mt-2 form-builder__error-color'}>{error}</small>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='primary' onClick={handleSubmit} className={`bp`}>
                    {`Submit`}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
