import React, { Component } from 'react';
import LessonContext from '../../../../LessonContext';
import NavigationCalendar, { TCalendarLegend } from 'src/components/_New/Navigation/Calendar';
import { ICard } from '../../../../Cards/LessonCard';
import { UnlockedLegend, PendingReviewLegend, PassedLegend, FailedLegend, CompletedLegend } from './LegendComponents';
import {
    LockedCard,
    CurrentCard,
    PendingReviewCard,
    PassedCard,
    FailedCard,
    CompletedCard,
    UnlockedCard,
} from './ItemComponents';
import { EventBus } from 'src/helpers/new';
import './SlideCalendar.scss';

interface IProps {
    activeCardIndex: number;
    setActiveCardIndex: (e: number) => any;
}

interface IState {
    expanded: boolean;
}

export type ICalendarCard = {
    _id: string;
    index: number;
    locked: boolean;
    completed: boolean;
    currentCard: boolean;
    status: string;
    colour: string;
};

export default class SlideCalendar extends Component<IProps, IState> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    state: IState = {
        expanded: false,
    };

    toggleCalendar = () => {
        this.setState((prevState) => ({
            ...prevState,
            expanded: !prevState.expanded,
        }));
    };

    get cards(): ICalendarCard[] {
        return this.context.cards?.map((card: ICard) => {
            const userCard = card.userLessonCardData;
            let status = '';
            let colour = '';

            if (
                card.cardType === 'SINGLE_QUESTION' &&
                userCard?.question?.questions?.some((question: any) => question?.userAnswer?.length > 0)
            ) {
                if (userCard.question.status === 'passed') {
                    status = 'passed';
                } else {
                    status = 'failed';
                }
            } else if (card.cardType === 'QUIZ' && userCard?.quiz?.previousAttempts?.length > 0) {
                if (userCard.quiz.quizPassed) {
                    status = 'passed';
                } else {
                    status = 'failed';
                }
            } else if (card.cardType === 'ESSAY' && userCard?.essay?.essayAttempts?.length > 0) {
                if (!!userCard.essay.essayApprovedAt) {
                    status = 'passed';
                } else if (userCard.essay.essayStatus === 'REVIEW_PENDING') {
                    status = 'pendingReview';
                } else {
                    status = 'failed';
                }
            }

            if (!!userCard.completedAt) colour = 'green';
            else if (!!status) colour = 'red';

            return {
                _id: userCard._id,
                index: card.orderIndex,
                locked: !userCard.unlockedAt,
                completed: !!userCard.completedAt,
                currentCard: card.orderIndex === this.props.activeCardIndex,
                status,
                colour,
            };
        });
    }

    render() {
        const legend: TCalendarLegend = [
            {
                matches: (card: ICalendarCard) => card.locked,
                onClick: () => {
                    EventBus.dispatch('alert', {
                        type: 'error',
                        title: 'Slide Locked',
                        message: 'You are unable to view this slide yet',
                    });
                },
                legendComponent: null,
                itemComponent: LockedCard,
            },
            {
                matches: (card: ICalendarCard) => card.currentCard,
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: null,
                itemComponent: CurrentCard,
                alwaysVisible: true,
            },
            {
                matches: (card: ICalendarCard) => card.status === 'pendingReview',
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: PendingReviewLegend,
                itemComponent: PendingReviewCard,
                legendOrderIndex: 4,
            },
            {
                matches: (card: ICalendarCard) => card.status === 'passed',
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: PassedLegend,
                itemComponent: PassedCard,
                legendOrderIndex: 2,
            },
            {
                matches: (card: ICalendarCard) => card.status === 'failed',
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: FailedLegend,
                itemComponent: FailedCard,
                legendOrderIndex: 3,
            },
            {
                matches: (card: ICalendarCard) => card.completed,
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: CompletedLegend,
                itemComponent: CompletedCard,
                legendOrderIndex: 1,
            },
            {
                matches: () => true,
                onClick: (_, index: number) => {
                    this.toggleCalendar();
                    this.props.setActiveCardIndex(index);
                },
                legendComponent: UnlockedLegend,
                itemComponent: UnlockedCard,
                legendOrderIndex: 0,
            },
        ];

        return (
            <>
                <div className='navigation-calendar-wrapper' onClick={this.toggleCalendar}>
                    {!this.context.isAdmin && <i className='fa fa-calendar' />}
                    <span className={'navigation-slide-index-text'}>
                        {this.props.activeCardIndex + 1} of {this.context.cards.length}
                    </span>
                </div>

                {!this.state.expanded && (
                    <div className='card-container__feedback__toggle' onClick={this.toggleCalendar}>
                        <i className='fa fa-chevron-up up-icon-padding' />
                    </div>
                )}
                {this.state.expanded && (
                    <div className='navigation-calendar-slide-index-wrapper'>
                        <div className='card-container__feedback__toggle' onClick={this.toggleCalendar}>
                            <i className='fa fa-chevron-down' />
                        </div>
                        <div className={'calendar-content-wrapper'}>
                            <NavigationCalendar basic={this.cards} legend={legend} />
                        </div>
                    </div>
                )}
            </>
        );
    }
}
