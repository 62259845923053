import React, { Component } from 'react';
import './ErrorPage.scss';

interface IProps {
    className?: string;
    isIframe: boolean;
    location?: {
        pathname: string;
    };
}
interface IState {}

class ErrorPageWithCustomMessage extends Component<IProps, IState> {
    state: IState = {};

    render() {
        const { className, isIframe, location } = this.props;
        return (
            <div className={`customr-error-page-wrapper ${className}`}>
                {!location?.pathname.includes('/custom_error_page') && (
                    <header>
                        <img className='logo-img' src={'/logo.png'} alt='RealEstateU logo' />
                    </header>
                )}
                <main>
                    <div className='content-wrapper'>
                        <i className='fa-solid fa-ban' />
                        <div className='custom-error-message'>
                            {isIframe ? (
                                <h4>IFrame Embedding is not supported due to security reasons !</h4>
                            ) : (
                                <>
                                    <h4>Electron browser is not supported due to security reasons !</h4>
                                    <h4>Kindly use other browser such as Chrome, Firefox, Edge & Safari.</h4>
                                </>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

export default ErrorPageWithCustomMessage;
