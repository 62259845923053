import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import CheckoutContext from '../../../CheckoutContext';
import { EventBus } from 'src/helpers/new';
import PackageHelper from 'src/helpers/PackageHelper';

export interface IUpsell {
    _id: string;
    discountValue: number;
    discountType: string;
    discountLocations: {
        cart: boolean;
    };
    upsoldPackage: {
        price: number;
        title: string;
        image: string;
        description: string;
    };
    upsoldPackageId: string;
}

export interface CartPackage {
    _id: string;
    packageId: string;
    isBundle: boolean;
    isUpsell: boolean;
}
interface IState {
    imageClass: { _id: string; className: string }[];
    status: string;
}
class Upsell extends Component<IUpsell & RouteComponentProps, IState> {
    static readonly contextType = CheckoutContext;
    context!: React.ContextType<typeof CheckoutContext>;

    state = {
        status: 'LOADING',
        imageClass: [],
    };

    async componentDidMount() {
        const resolvedImgClasses = await Promise.all(PackageHelper.getImagePromise([this.props.upsoldPackage]));
        if (resolvedImgClasses.length > 0) {
            this.setState({
                imageClass: resolvedImgClasses,
            });
        }
    }

    addUpsellToCart = async () => {
        const { _id, discountValue, discountType, upsoldPackageId } = this.props;
        const { price, title, image } = this.props.upsoldPackage;
        const discountedPrice = price - (discountType === 'FIXED' ? discountValue : (price * discountValue) / 100);

        const packageData = {
            _id,
            title,
            image,
            price,
            discountedPrice,
            isUpsell: true,
            isBundle: false,
            packagePurchaseType: 'new',
            packageId: upsoldPackageId,
            upsellLocation: 'cart',
        };

        let packages: CartPackage[] = Array.from(this.context.packages);

        let isToPushPackage = true;

        packages = packages.map((pkg: CartPackage) => {
            if (pkg.packageId === upsoldPackageId) {
                isToPushPackage = false;

                if (!pkg.isBundle) {
                    pkg = { ...packageData };
                } else {
                    EventBus.dispatch('alert', {
                        type: 'error',
                        title: 'Package Already Exists!',
                        message: 'Same Package already added in cart!',
                    });
                }
            }

            return pkg;
        });

        if (isToPushPackage) {
            packages.push(packageData);
        }

        this.context.updateCart({
            packages: [...packages.filter((pkg: CartPackage) => pkg.hasOwnProperty('packagePurchaseType'))],
        });
    };

    render() {
        const { title, image, description, price } = this.props.upsoldPackage,
            { discountType, discountValue, discountLocations } = this.props;

        let discount;

        if (discountType === 'FIXED') {
            discount = discountValue;
        } else {
            discount = (price * discountValue) / 100;
        }
        const discountedPrice = discountLocations.cart ? price - discount : price;

        const imgClassObj: any = this.state.imageClass.find(
            (v: { _id: string; className: string }) => v._id === this.props.upsoldPackageId,
        );

        return (
            <div className='checkout-upsells__item'>
                <img src={image[2]} alt={title} className={imgClassObj?.className} />
                <main className='checkout-upsells__itemData'>
                    <h1>{title}</h1>
                    <span>{description}</span>
                </main>
                <div>
                    <div className='mb-1'>
                        {discountedPrice !== price && discountLocations.cart && (
                            <img className='timer-icon' src={`timer.svg`} alt='timer' />
                        )}
                        <span className='checkout-upsells__price'>{global.USCurrency.format(discountedPrice)}</span>
                        {discountedPrice !== price && discountLocations.cart && (
                            <>
                                <span className='checkout-upsells__oldPrice'>{global.USCurrency.format(price)}</span>
                                <span className='checkout-upsells__discount'>
                                    {discountType === 'FIXED'
                                        ? global.USCurrency.format(discountValue) + ' off'
                                        : `${discountValue}% off`}
                                </span>
                            </>
                        )}
                    </div>
                    <button className='sm light-blue' onClick={this.addUpsellToCart}>
                        Add to cart
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(Upsell);
