import React, { Component } from 'react';
import CheckoutContext from '../../CheckoutContext';
import { EventBus } from 'src/helpers/new';
import './Progress.scss';
import { CheckoutValidateHelper } from '../Tabs/CheckoutValidateHelper';
import { isNil } from 'lodash';

export default class Progress extends Component {
    static contextType = CheckoutContext;

    switchTab = async (targetTab) => {
        const { tabs, currentTab, switchTab } = this.context;
        const eventMap = { 1: 'validate-contact-tab', 2: 'validate-billing-tab' };

        const dataMap = {
            1: CheckoutValidateHelper.validateContact({}, this.context.contact),
            2: CheckoutValidateHelper.validateBilling({}, this.context.billingAddress),
        };

        if (targetTab === currentTab) {
            return;
        } else {
            const targetTabCompleted = tabs[targetTab].completed;

            if (targetTab === 0) {
                switchTab(targetTab);
                await this.context.updateCart({
                    currentTab: targetTab,
                });
            } else if (targetTabCompleted || tabs[targetTab - 1].completed) {
                if (dataMap[currentTab]?.proceed || isNil(dataMap[currentTab])) {
                    switchTab(targetTab);
                    await this.context.updateCart({
                        currentTab: targetTab,
                    });
                } else {
                    EventBus.dispatch(eventMap[currentTab]);
                }
            } else {
                EventBus.dispatch(eventMap[currentTab]);
            }
        }
    };

    render() {
        const { currentTab } = this.context;
        return (
            <>
                <div className='checkout-progress'>
                    {this.context.tabs.map((tab, index) => {
                        const isTabComplete = tab.completed,
                            isTabActive = currentTab === index;

                        let tabStatus = '';
                        if (isTabActive) {
                            tabStatus = 'active';
                        } else if (isTabComplete) {
                            tabStatus = 'complete';
                        }

                        return (
                            <button
                                className={`tab ${tabStatus}`}
                                key={'tab-' + index}
                                onClick={() => {
                                    this.switchTab(index);
                                }}
                            >
                                <div>
                                    {!isTabActive && isTabComplete ? (
                                        <i className='fa fa-check fa-xl' />
                                    ) : (
                                        `${(index + 1).toString().padStart(2, '0')}`
                                    )}
                                </div>
                                <span>{tab.title}</span>
                            </button>
                        );
                    })}
                </div>
                <h4 className='checkout-progress__heading'>{this.context.tabs[currentTab].title}</h4>
            </>
        );
    }
}
