import React, { PureComponent } from 'react';
import { Api, EventBus, Utility } from 'src/helpers/new';
import UpsellCard, { IUpsell } from './UpsellCard';
import './UpsellsList.scss';
import { ICartDetail } from '../Home';
import { getState, setState } from 'src/helpers/localStorage';
import { compact } from 'lodash';
import { withRouter } from 'src/hoc/withRouter';

interface IProps {
    userId: string;
    cartDetails: ICartDetail;
    loadCarts: () => void;
    updateCartDetails: (data: any) => void;
}

interface IState {
    upsells: IUpsell[];
    upsoldPackageIdsInCart: string[];
    createIsProgress: boolean;
    upsellIdsInCart: string[];
}

interface ICartPackage {
    _id: string;
    isUpsell: boolean;
    isBundle: boolean;
    packagePurchaseType: string;
    upsellLocation?: string;
    packageId: string;
}
class UpsellsList extends PureComponent<IProps, IState> {
    state = {
        upsells: [],
        upsoldPackageIdsInCart: [],
        createIsProgress: false,
        upsellIdsInCart: [],
    };

    componentDidMount() {
        this.loadUpsells();
    }

    loadUpsells = async (): Promise<void> => {
        const { success, response } = await Api.call('GET', `users/upsells`, null, { location: 'dashboard' });
        if (success) {
            this.setState({
                upsells: response,
            });
        }
    };

    upsellAddToCart = async (_id: string, upsoldPackageId: string) => {
        const cartId = getState('reuCheckoutCartId');
        this.setState({
            upsoldPackageIdsInCart: [...this.state.upsoldPackageIdsInCart, upsoldPackageId],
            upsellIdsInCart: [...this.state.upsellIdsInCart, _id],
        });

        this.props.updateCartDetails({
            pkgIds: [...this.props.cartDetails.pkgIds, ...this.state.upsoldPackageIdsInCart, upsoldPackageId],
        });

        if (cartId) {
            const { success, response } = await this.updateCartCalling(
                [
                    {
                        _id,
                        isUpsell: true,
                        isBundle: false,
                        packagePurchaseType: 'new',
                        upsellLocation: 'dashboard',
                        packageId: upsoldPackageId,
                    },
                ],
                cartId,
            );
            this.successMessage(success, response, false, upsoldPackageId, _id);
        } else {
            if (!this.state.createIsProgress && !cartId) {
                this.setState({ createIsProgress: true });
                const { success, response } = await Api.call(
                    'post',
                    `checkout/cart/create/${_id ?? getState('cartPackageId')}`,
                    {
                        packagePurchaseType: 'new',
                        courses: [],
                        isUpsell: true,
                        userId: getState('user')?._id,
                        upsellLocation: 'dashboard',
                        device: Utility.isMobileView() ? 'mobile' : 'desktop',
                    },
                );
                this.successMessage(success, response, true, upsoldPackageId, _id);
            }
        }
    };

    updateCartCalling = async (packages: ICartPackage[], cartId: string) => {
        const id = getState('user')?._id ? getState('user')?._id : cartId;

        return await Api.call('PATCH', `checkout/cart/${id}/addPackagesInCart?isLoggedIn=${!!getState('user')?._id}`, {
            packages,
            device: Utility.isMobileView() ? 'mobile' : 'desktop',
        });
    };

    successMessage = async (
        success: boolean,
        rsp: any,
        isCreate: boolean,
        upsoldPackageId: string,
        upsellId: string,
    ) => {
        if (success) {
            if (isCreate) {
                setState('reuCheckoutCartId', rsp._id);

                const addedUpsells = compact(
                    this.state.upsellIdsInCart.map((_id) => {
                        if (_id !== upsellId) {
                            return {
                                _id,
                                isUpsell: true,
                                isBundle: false,
                                packagePurchaseType: 'new',
                                upsellLocation: 'dashboard',
                                packageId: upsoldPackageId,
                            };
                        }
                    }),
                );
                if (addedUpsells.length > 0) {
                    await this.updateCartCalling(addedUpsells, rsp._id);
                }

                this.setState(
                    {
                        createIsProgress: false,
                        upsellIdsInCart: [],
                        upsoldPackageIdsInCart: [],
                    },
                    () => {
                        this.loadCarts('Items added to the cart');
                    },
                );
            } else {
                const upsoldPackageIdsInCart = this.state.upsoldPackageIdsInCart.filter((v) => v !== upsoldPackageId);
                this.setState(
                    {
                        upsoldPackageIdsInCart,
                        upsellIdsInCart: this.state.upsellIdsInCart.filter((v) => v !== upsellId),
                    },
                    () => {
                        this.loadCarts('Item added to the cart');
                    },
                );
            }
        }
    };

    loadCarts = async (message: string) => {
        EventBus.dispatch('toast', {
            type: 'success',
            message,
        });

        if (this.state.upsoldPackageIdsInCart.length === 0 && this.props.loadCarts) {
            await this.props.loadCarts();
        }
    };

    render() {
        const { upsells } = this.state;
        return (
            <div className='upsells-list'>
                <h2>Recommended courses</h2>
                {upsells.map((upsell: IUpsell) => {
                    return (
                        <UpsellCard
                            upsell={upsell}
                            key={upsell._id}
                            confirmation={false}
                            cartDetails={this.props.cartDetails}
                            upsellAddToCart={this.upsellAddToCart}
                        />
                    );
                })}
            </div>
        );
    }
}

export default withRouter(UpsellsList);
