import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import './ChapterSkeleton.scss';
import 'react-loading-skeleton/dist/skeleton.css';

export default class ChapterSkeleton extends Component {
    render() {
        return (
            <div className='menu-bar'>
                <Skeleton count={1} duration={1} className='course-title' />
                <div className='course-progess'>
                    <Skeleton duration={1} circle className='progress-bar-skeleton' />
                    <Skeleton duration={1} className='course-details-skeleton' />
                </div>
                <Skeleton count={12} duration={1} className='chapter-title' />
            </div>
        );
    }
}
