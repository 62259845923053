import React, { Component } from 'react';
import { FormGroup, Form } from 'react-bootstrap';
import { EventBus } from 'src/helpers/new';
import LessonContext from '../../../LessonContext';
import Countdown from 'react-countdown';
import { IQuizAttempt } from './Quiz';
import { IQuizQuestion } from 'src/pages/Admin/Courses/Lesson/LessonEditor/LessonCards/CardTypes/Quiz/QuizQuestionList/QuizQuestion';
import FillGapQuestion from './FillGapQuestion';
import './CardsQuiz.scss';

interface IProps {
    userQuiz: IQuizAttempt;
    forceSubmit: () => void;
    onAnswer: (x: number, y: number) => void;
    activeCardIndex: number;
    isReview: boolean;
}

interface IState {
    endTime: any;
}

export default class CardsQuiz extends Component<IProps> {
    static readonly contextType = LessonContext;

    state: IState = {
        endTime: Date.now() + this.timeRemaining,
    };

    componentDidMount() {
        EventBus.dispatch('toggle-main-scroll', true);
        const timeExpired = this.timeRemaining > 0 ? false : true;

        if (!this.props.isReview && timeExpired) {
            this.handleTimeExpired();
        }
    }

    componentWillUnmount() {
        EventBus.dispatch('toggle-main-scroll', false);
    }

    get timeRemaining(): number {
        const time =
            this.props.userQuiz?.remainingTime && this.props.userQuiz?.remainingTime > 0
                ? this.props.userQuiz?.remainingTime
                : 0;

        return time;
    }

    handleTimeExpired = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Out of time',
            body: 'You have run out of time for this quiz. Your answers have been submitted',
            confirm: {
                text: 'Okay',
                action: () => {}, //Initiate with blank function because untill user click on okay API not clicked it add more time on quiz with timelimit
            },
            cancel: null,
        });

        this.props.forceSubmit();
    };

    leaveQuiz = () => {
        if (this.props.isReview) {
            EventBus.dispatch('leave-quiz');
            return;
        }

        EventBus.dispatch('confirmation-popup', {
            title: 'Leave Quiz?',
            body: 'Are you sure you wish to leave? you will be able to resume later if your time has not expired',
            confirm: {
                text: 'Leave quiz',
                action: () => EventBus.dispatch('leave-quiz'),
            },
            cancel: {
                text: 'Continue',
                action: () => {},
            },
            dismiss: () => {},
        });
    };

    handleAnswer = (questionIndex: number, userAnswer: number) => {
        this.props.onAnswer(questionIndex, userAnswer);
    };

    get currentQuestion(): IQuizQuestion {
        const { questions } = this.props.userQuiz.quiz,
            questionIndex = this.props.activeCardIndex;
        let question: any = {};

        if (questions) {
            question = questions[questionIndex];
        }
        return question;
    }

    get currentAnswer(): undefined | number {
        return this.props.userQuiz.answers[this.props.activeCardIndex];
    }

    get revealAnswers(): boolean {
        if (!this.props.isReview) return false;

        switch (this.props.userQuiz.quiz.revealAnswers) {
            case 'always':
                return true;
            case 'never':
                return false;
            case 'on_pass':
                return this.props.userQuiz.status === 'SUCCESS';
            default:
                return true;
        }
    }

    get results(): { correct: boolean; feedback: string; answerMessage: string } {
        const question = this.currentQuestion;
        const answer = this.currentAnswer;
        const results = {
            correct: false,
            feedback: '',
            answerMessage: '',
        };

        if (answer === undefined) {
            return {
                correct: false,
                feedback: question.incorrectFeedback ?? '',
                answerMessage: '',
            };
        }

        const correct = question.correctOptionIndex === answer;
        results.correct = correct;

        if (correct) {
            results.feedback = question.correctFeedback ?? '';
        } else {
            results.feedback = question.incorrectFeedback ?? '';
        }

        return results;
    }

    getOption = (userAnswer: number | undefined, optionIndex: number, content: any) => {
        const question = this.currentQuestion;
        let classes = '';
        let updatedOption = (
            <div className='option-content-wrapper'>
                <span>{content}</span>
            </div>
        );

        if (this.props.isReview) {
            if (optionIndex === userAnswer) {
                if (userAnswer === question.correctOptionIndex) {
                    classes = 'correct-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            <span>{content}</span>
                            <span className='feedback-text'>Your answer was correct</span>
                        </div>
                    );
                }

                if (userAnswer !== question.correctOptionIndex) {
                    classes = 'incorrect-feedback';
                    updatedOption = (
                        <div className='option-content-wrapper'>
                            <span>{content}</span>
                            <span className='feedback-text'>Your answer was incorrect</span>
                        </div>
                    );
                }
            }

            if (
                optionIndex === question.correctOptionIndex &&
                optionIndex !== userAnswer &&
                this.revealAnswers === true
            ) {
                classes = 'reveal-correct-feedback';
                updatedOption = (
                    <div className='option-content-wrapper'>
                        <span>{content}</span>
                        <span className='feedback-text'>The correct answer</span>
                    </div>
                );
            }
        }

        return { classes, updatedOption };
    };

    render() {
        const results = this.results;

        return (
            <div
                className={`cards-quiz ${
                    this.props.userQuiz?.quiz.quizType === 'slide' && 'quiz__unset-default-background'
                }`}
            >
                <header>
                    <div className='cards-quiz__tracker'>
                        {this.props.isReview || (
                            <>
                                <span>You have answered </span>
                                <strong>
                                    {this.props.userQuiz.answers.filter((answer) => answer !== null)?.length}
                                </strong>
                                / {this.props.userQuiz.quiz.questions?.length}
                            </>
                        )}
                    </div>
                    <div className='cards-quiz__timer'>
                        <div className='bd' onClick={this.leaveQuiz}>
                            Leave
                        </div>
                        {this.props.isReview || (
                            <>
                                <span>Time remaining:&nbsp;</span>
                                <Countdown
                                    date={this.state.endTime}
                                    onComplete={this.handleTimeExpired}
                                    renderer={({ hours, minutes, seconds }) => {
                                        return (
                                            <span>
                                                {hours.toString().padStart(2, '0')}:
                                                {minutes.toString().padStart(2, '0')}:
                                                {seconds.toString().padStart(2, '0')}
                                            </span>
                                        );
                                    }}
                                />
                            </>
                        )}
                    </div>
                </header>
                <div>
                    {this.currentQuestion?.title?.includes('___') ? (
                        <FillGapQuestion
                            question={this.currentQuestion}
                            answer={this.currentAnswer}
                            questionIndex={this.props.activeCardIndex}
                            readOnly={this.props.isReview}
                            handleAnswer={this.handleAnswer}
                            results={results}
                            revealAnswers={this.revealAnswers}
                        />
                    ) : (
                        <FormGroup className='question-wrap'>
                            <Form.Label className='pb-2'>
                                {this.props.activeCardIndex + 1}. {this.currentQuestion?.title}
                            </Form.Label>
                            {this.currentQuestion?.options?.map((option: any, optionIndex: number) => {
                                const opt = this.getOption(this.currentAnswer, optionIndex, option);
                                if (option.length > 0) {
                                    return (
                                        <div
                                            key={optionIndex}
                                            onClick={(e: any) => {
                                                if (this.currentAnswer !== optionIndex) {
                                                    this.handleAnswer(this.props.activeCardIndex, optionIndex);
                                                }
                                            }}
                                        >
                                            <Form.Check
                                                key={optionIndex}
                                                id={`Q${this.props.activeCardIndex}A${optionIndex}`}
                                                type='radio'
                                                name={option.title}
                                                label={opt.updatedOption}
                                                className={`cards-quiz--option ${opt.classes}`}
                                                onChange={() => {
                                                    if (this.currentAnswer !== optionIndex) {
                                                        this.handleAnswer(this.props.activeCardIndex, optionIndex);
                                                    }
                                                }}
                                                checked={this.currentAnswer === optionIndex}
                                            />
                                        </div>
                                    );
                                }
                            })}
                            {this.props.isReview && (results.feedback || results.answerMessage) && (
                                <div className={`quiz-feedback ${results.correct ? 'correct' : 'incorrect'}`}>
                                    {results.feedback} {results.answerMessage}
                                </div>
                            )}
                        </FormGroup>
                    )}
                </div>
            </div>
        );
    }
}
