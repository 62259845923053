import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import CourseContext from 'src/pages/Course/CourseContext';
import { EventBus } from 'src/helpers/new';
import { LessonCompletedContent, UnlockedNextLessonContent } from 'src/pages/Course/CourseManager';
import { isNil } from 'lodash';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface IProps {
    lesson: any;
    updateLesson: (lesson: any) => void;
}

type TProps = IProps & RouteComponentProps<IRouteProps>;

class ProgressionManager extends Component<TProps> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    mounting = false;

    componentDidMount() {
        this.mounting = true;
        window.socket.on('user-lesson-completed', this.completeLesson);
        window.socket.on('user-lesson-unlocked', this.unlockLesson);
        window.socket.on('user-exam-status-update', this.unlockExam);
        window.socket.on('unlock-next-lesson-messages', this.unlockLessonMessages);
        EventBus.on('change-lesson', this.changeLesson as any);
        EventBus.on('re-enter-lesson', this.reEnterLesson as any);
        EventBus.on('timer-mounted', () => {
            this.enterLesson();
        });
        EventBus.on('bio-sig-auth-success', this.bioSigSuccess as any);
    }

    componentWillUnmount() {
        EventBus.remove('change-lesson');
        EventBus.remove('re-enter-lesson');
        EventBus.remove('timer-mounted');
        EventBus.remove('bio-sig-auth-success');
    }

    componentDidUpdate(prevProps: TProps) {
        if (prevProps.params.lessonId !== this.props.params.lessonId && !this.mounting) {
            this.enterLesson();
        }
    }

    completeLesson = (lessonId: LessonCompletedContent) => {
        if (this.context.completeLesson) {
            this.context.completeLesson(lessonId);
        }
    };

    unlockLesson = (unlockedLessonContent: UnlockedNextLessonContent) => {
        if (this.context.unlockLesson) {
            this.context.unlockLesson(unlockedLessonContent);
        }
    };

    unlockLessonMessages = (unlockedLessonMessage: UnlockedNextLessonContent) => {
        if (this.context.unlockLessonMessages) {
            this.context.unlockLessonMessages(unlockedLessonMessage);
        }
    };

    bioSigSuccess = (event: Event) => {
        const { isCheckPoint, isResuming } = (event as CustomEvent).detail;
        if (isCheckPoint || isResuming) {
            window.socket.emit('enter-user-lesson', {
                lessonId: this.props.params.lessonId,
                courseType: this.context.course.courseType,
            });
        }
    };

    isToIntiateNMLSBiosig = (isToBypassBiosigOnComponentMount: boolean) => {
        let showBiosig = this.props.lesson?.showBiosig;

        if (
            this.context.course?.proctoringSettings?.bioSight !== 'biosig-nmls' ||
            this.props.location.state?.isToBypassBiosigOnComponentMount ||
            this.context.course.isAdminPreview ||
            isToBypassBiosigOnComponentMount ||
            this.context.course?.status === 'EXAM_PASSED'
        ) {
            showBiosig = false;
        }

        return showBiosig;
    };

    enterLesson = (isToBypassBiosig = false) => {
        const requiresReAuthentication = this.props?.lesson?.requiresReAuthentication;
        const isCheckPoint = this.context?.course?.proctoringSettings?.needsAuth?.includes(
            this.props.lesson.courseLessonId,
        );
        const { courseId } = this.props.params;

        let isToBypassBiosigOnComponentMount = false;
        if (localStorage.getItem(`${courseId}-manual-reload`)) {
            isToBypassBiosigOnComponentMount = true;
            localStorage.removeItem(`${courseId}-manual-reload`);
        }

        // on quiz submit re-enter-lesson omit biosig
        if (!isCheckPoint && isToBypassBiosig) {
            isToBypassBiosigOnComponentMount = isToBypassBiosig;
        }

        if (isCheckPoint && isNil(requiresReAuthentication) && !this.context.course.isAdminPreview) {
            EventBus.dispatch('require-auth', {
                stage: this.props.params.lessonId,
                isCheckPoint: true,
                callback: () => {
                    this.props.updateLesson({
                        ...this.props.lesson,
                        requiresReAuthentication: false,
                        requiresReAuthenticationForNmls: false,
                    });
                },
            });
        } else if (this.isToIntiateNMLSBiosig(isToBypassBiosigOnComponentMount)) {
            EventBus.dispatch('require-auth', {
                stage: this.props.params.lessonId,
                isCheckPoint: isCheckPoint,
                isResuming: true,
                callback: () => {
                    this.props.updateLesson({ ...this.props.lesson, requiresReAuthenticationForNmls: false });
                },
            });
        } else {
            if (this.props.lesson?.requiresReAuthenticationForNmls) {
                this.props.updateLesson({ ...this.props.lesson, requiresReAuthenticationForNmls: false });
            }
            window.socket.emit('enter-user-lesson', {
                lessonId: this.props.params.lessonId,
                courseType: this.context.course.courseType,
            });
        }
    };

    changeLesson = (event: Event) => {
        const { lessonId, chapterId } = (event as CustomEvent).detail;
        const { _id, isAdminPreview } = this.context.course;

        this.props.navigate(
            `/${isAdminPreview ? 'preview' : 'courses'}/${_id}/chapters/${chapterId}/lessons/${lessonId}`,
        );
    };

    reEnterLesson = () => {
        this.enterLesson(true);
    };

    unlockExam = (examStatus: any) => {
        if (this.context.unlockExam) {
            this.context.unlockExam(examStatus);
        }
    };

    render() {
        return null;
    }
}

export default withRouter(ProgressionManager);
