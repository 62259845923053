import React, { Component } from 'react';
import { Api } from 'src/helpers/new';
import AnnouncementAlert, {
    IAnnouncementAlert,
} from 'src/GlobalHandlers/AlertHandler/Alerts/AnnouncemntAlerts/Announcement';
import { RootState } from 'src/store/reducers/rootReducer';
import { RouteComponentProps } from 'src/hoc/withRouter';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

interface IConnectProps {
    announcements?: any;
    setAnnouncements: (payload: any) => void;
}

interface IProps extends IConnectProps, RouteComponentProps {
    pathname: string;
}
class AnnouncementToast extends Component<IProps> {
    state = {
        isLoading: false,
        announcements: null,
    };

    loadAnnouncement = async () => {
        if (this.isToShow) {
            this.props.setAnnouncements({
                isLoaded: false,
                announcements: null,
            });
            const { success, response } = await Api.call('POST', '/user-announcements', {});

            if (success) {
                if (response) {
                    const announcementData: { [key: string]: IAnnouncementAlert } = response;
                    if (announcementData) {
                        this.setState({
                            announcements: announcementData,
                            isLoading: false,
                        });

                        this.props.setAnnouncements({ isLoaded: true, announcements: announcementData });
                    }
                }
            }
        }
    };

    async componentDidMount() {
        if (localStorage.getItem('user') && this.isToShow) {
            this.setState({
                isLoading: true,
            });
            await this.loadAnnouncement();
        }

        if (window?.socket) {
            window.socket.on('announcement-update', this.handleAnnouncementUpdate);
        }
    }

    componentWillUnmount() {
        if (window?.socket) {
            window.socket.off('announcement-update', this.handleAnnouncementUpdate);
        }
    }

    async componentDidUpdate() {
        if (
            !this.props.announcements.isLoaded &&
            localStorage.getItem('user') &&
            this.isToShow &&
            !this.state.isLoading
        ) {
            this.setState({
                isLoading: true,
            });
            await this.loadAnnouncement();
        }
    }

    handleAnnouncementUpdate = async () => {
        await this.loadAnnouncement();
    };

    get isToShow() {
        if (this.props.location.pathname.includes('/admin') || this.props.location.pathname.includes('/login')) {
            return false;
        }
        return true;
    }

    render() {
        const { announcements } = this.state;

        return (
            announcements &&
            announcements['sendAlerts'] &&
            this.isToShow && (
                <div className='announcemnent-container'>
                    <AnnouncementAlert announcement={announcements['sendAlerts']} />
                </div>
            )
        );
    }
}

export default withRouterAndRedux(AnnouncementToast, (state: RootState) => ({ announcements: state.announcements }), {
    setAnnouncements: (payload: any) => ({
        type: 'SET_ANNOUNCEMENTS',
        payload,
    }),
});
