import React, { Component } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FileImage } from 'src/components/ApiFile';
import './UpsellCard.scss';
import CheckoutContext from 'src/pages/Checkout/CheckoutContext';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { ICartDetail } from '../../Home';
import PackageHelper from 'src/helpers/PackageHelper';

export interface IUpsell {
    _id: string;
    packageId: string;
    createdAt: Date;
    updatedAt: Date;
    description: string;
    image: string[];
    importance: number;
    orderIndex: number;
    title: string;
    upsoldPackageId: string;
    discountType: string;
    discountValue: number;
    price: number;
    readMoreUrl?: string;
}

interface IProps {
    upsell: IUpsell;
    confirmation: boolean;
    cartDetails?: ICartDetail;
    upsellAddToCart?: (id: string, upsoldPackageId: string) => void;
    isAdminPreview?: boolean;
}

interface IState {
    imageClass: { _id: string; className: string }[];
    isLoading: boolean;
}
export default class UpsellCard extends Component<IProps, IState> {
    static readonly contextType = CheckoutContext;
    state: IState = {
        isLoading: false,
        imageClass: [],
    };

    async componentDidMount() {
        const resolvedImgClasses = await Promise.all(PackageHelper.getImagePromise([this.props.upsell]));
        if (resolvedImgClasses.length > 0) {
            this.setState({
                imageClass: resolvedImgClasses,
            });
        }
    }

    get isAlreadyInCart(): boolean {
        const { cartDetails } = this.props;
        if (cartDetails?.pkgIds && cartDetails.pkgIds?.length > 0) {
            return cartDetails.pkgIds.includes(this.props.upsell.upsoldPackageId);
        }
        return false;
    }

    render() {
        const { upsell, confirmation } = this.props;
        const { discountType, discountValue, price } = upsell,
            newPrice = price - (discountType === 'FIXED' ? discountValue : (price * discountValue) / 100);

        const buyCourseUrl = this.props.isAdminPreview
            ? `${process.env.REACT_APP_URL}/checkout?packageId=${this.props.upsell._id}&isUpsell=true&location=thankYou&isAdminPreview=true`
            : `${process.env.REACT_APP_URL}/checkout?packageId=${this.props.upsell._id}&isUpsell=true&location=thankYou`;

        const imgClassObj: any = this.state.imageClass.find(
            (v: { _id: string; className: string }) => v._id === this.props.upsell._id,
        );

        return (
            <div className='upsell-card'>
                <div className={confirmation ? 'confirmation-square-image' : 'square-img'}>
                    <FileImage className={`image ${imgClassObj?.className}`} fileId={upsell.image} />
                </div>
                {confirmation ? (
                    <div className='content content-upsells'>
                        <Row>
                            <Col sm={12} lg={8} className='confirmation-header'>
                                <h2>{upsell.title}</h2>
                                <div>{upsell.description}</div>
                            </Col>
                            <Col sm={12} lg={4}>
                                <div className='confirmation_actions'>
                                    {confirmation ? (
                                        <div className='confirmation__upsell mb-2'>
                                            {newPrice !== price && !isNaN(newPrice) && (
                                                <img className='timer-icon' src={`/timer.svg`} alt='timer' />
                                            )}
                                            <span className='confirmation__upsell-price'>
                                                {isNaN(newPrice)
                                                    ? global.USCurrency.format(price)
                                                    : global.USCurrency.format(newPrice)}
                                            </span>
                                            {newPrice !== price && !isNaN(newPrice) && (
                                                <>
                                                    <span className='confirmation__upsell-oldprice'>
                                                        {global.USCurrency.format(price)}
                                                    </span>
                                                    <span className='confirmation__upsell-discount'>
                                                        {discountType === 'FIXED'
                                                            ? global.USCurrency.format(discountValue) + ' off'
                                                            : `${discountValue}% off`}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    <a href={buyCourseUrl} className='sm light-blue'>
                                        Buy Course
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <>
                        <div className='content'>
                            <h2>{upsell.title}</h2>
                            <div className=' mb-2'>
                                {newPrice !== price && !isNaN(newPrice) && (
                                    <span className='confirmation__upsell-oldprice me-3'>
                                        {global.USCurrency.format(price)}
                                    </span>
                                )}
                                <span className='confirmation__upsell-price'>
                                    {isNaN(newPrice)
                                        ? global.USCurrency.format(price)
                                        : global.USCurrency.format(newPrice)}
                                </span>
                                {discountValue !== 0 && (
                                    <span className='confirmation__upsell-discount ms-1'>
                                        (
                                        {discountType === 'FIXED'
                                            ? global.USCurrency.format(discountValue) + ' off'
                                            : `${discountValue}% off`}
                                        )
                                    </span>
                                )}
                            </div>
                            <div>{upsell.description}</div>
                        </div>
                        <div className='upsells-button-wrapper'>
                            {upsell?.readMoreUrl && (
                                <a
                                    className='read-more-button'
                                    href={upsell.readMoreUrl ?? '#'}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    Read more
                                </a>
                            )}

                            <ConditionalWrapper
                                condition={this.isAlreadyInCart}
                                wrapper={(children: any) => (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`cart-button-tooltip`} className='abs-tooltip'>
                                                Package already exists in your cart!
                                            </Tooltip>
                                        }
                                    >
                                        {children}
                                    </OverlayTrigger>
                                )}
                            >
                                <button
                                    className={`bp buy-now-button ${this.isAlreadyInCart ? 'button--disabled' : ''} ${
                                        this.state.isLoading && 'button--disabled'
                                    }`}
                                    onClick={() =>
                                        !this.isAlreadyInCart &&
                                        this.props.upsellAddToCart &&
                                        this.props.upsellAddToCart(
                                            this.props.upsell._id,
                                            this.props.upsell.upsoldPackageId,
                                        )
                                    }
                                    disabled={this.state.isLoading}
                                >
                                    {this.state.isLoading ? 'Loading' : 'Add to cart'}
                                </button>
                            </ConditionalWrapper>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
