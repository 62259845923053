import { store } from 'src/store';
import axios from 'axios';
import EventBus from './EventBus';
import { getState, removeState } from '../localStorage';

interface IObject {
    [key: string]: any;
}
type IOptionalObject = IObject | null;
type IPayload = IOptionalObject | FormData;
type RequestTypes = 'get' | 'post' | 'patch' | 'delete' | 'put';

interface ICallConfig {
    method: RequestTypes | Uppercase<RequestTypes>;
    url: string;
    data: IPayload;
    headers: IObject;
}

interface IReturnType {
    success: boolean;
    response: any;
    message: string;
    error: any | null;
    notification?: {
        message: string;
        title?: string;
    };
    raw: any;
}

export default class Api {
    static baseUrl = `${process.env.REACT_APP_API_URL}/`;
    static chatBaseUrl = `${process.env.REACT_APP_CHAT_API_URL}/`;

    static async call(
        method: RequestTypes | Uppercase<RequestTypes>,
        path: string,
        payload: IPayload = null,
        params: IOptionalObject = null,
    ): Promise<IReturnType> {
        const callConfig = this.getCallConfig(this.baseUrl, method, path, payload, params);
        return await this.sendRequest(callConfig);
    }

    static async chatCall(
        method: RequestTypes | Uppercase<RequestTypes>,
        path: string,
        payload: IPayload = null,
        params: IOptionalObject = null,
    ): Promise<IReturnType> {
        const callConfig = this.getCallConfig(this.chatBaseUrl, method, path, payload, params);
        return await this.sendRequest(callConfig);
    }

    static getCallConfig(
        baseUrl: string,
        method: RequestTypes | Uppercase<RequestTypes>,
        path: string,
        payload: IPayload = null,
        params: IOptionalObject = null,
    ) {
        const { loggedIn } = store.getState();
        if (!loggedIn) {
            throw new Error('user must be logged in to use this method');
        }
        path = path.replace(/^\//g, '');
        const queryParameters = this.paramBuilder(params),
            token = loggedIn.token ?? getState('authToken'),
            callConfig = {
                method: method.toUpperCase() as Uppercase<RequestTypes>,
                url: baseUrl + path + queryParameters,
                data: payload,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

        return callConfig;
    }

    static paramBuilder(params: IOptionalObject) {
        let retVal = '';
        if (params) {
            retVal = '?';
            for (const param in params) {
                if (param && params[param] !== null && params[param] !== undefined) {
                    const query = `${param}=${params[param]}&`;
                    retVal = retVal + query;
                } else {
                    continue;
                }
            }
        }
        return retVal.slice(0, -1);
    }

    static async sendRequest(callConfig: ICallConfig): Promise<IReturnType> {
        if (callConfig.data !== null) {
            callConfig.headers['Content-Type'] = `application/json`;
        }
        if (callConfig.data instanceof FormData) {
            callConfig.headers['Content-Type'] = `multipart/form-data`;
        }

        try {
            const { data: response } = await axios(callConfig),
                { message, error, success, data, notification } = response;

            if (success === false || (success && notification)) {
                EventBus.dispatch('api-response', { message, success, notification });
            }

            return {
                success,
                message,
                response: data || null,
                error: error || success ? null : message,
                notification,
                raw: response,
            };
        } catch (e: any) {
            if (e.response) {
                const { data: response, status } = e.response;
                const { message, success, data, error } = response;
                if (status && status === 403) {
                    EventBus.dispatch('alert', { type: 'error', title: error ?? 'FORBIDDEN', message: message });
                }
                if (status && status === 404) {
                    let notFoundMessage = 'The requested servce could not be found. Please contact support';
                    if (['development', 'qa', 'staging'].includes(process.env.ENV_NAME ?? '')) {
                        notFoundMessage = `The requested route could not be found. please check for typos and verify the endpoint has not been removed - ${callConfig.url}`;
                    }
                    EventBus.dispatch('alert', { type: 'error', title: 'Route not found', message: notFoundMessage });

                    return {
                        success,
                        message,
                        response: message ?? '',
                        error: message ?? '',
                        raw: e,
                    };
                }

                if (status && status === 401) {
                    removeState('authToken');
                    removeState('APP_VERSION');
                    removeState('reuCheckoutCartId');
                    removeState('reuPreviewCheckoutCartId');
                    if (!response.data) {
                        const isInExam = /\/exam$/.test(window.location.pathname);
                        if (isInExam) {
                            EventBus.dispatch('exit-exam', { pathname: '/login' });
                        } else {
                            window.location.replace(`${window.location.origin}/login`);
                        }
                    }
                }

                if (status && status === 460) {
                    EventBus.dispatch('show-custom-error-page');
                    EventBus.dispatch('alert', { type: 'error', title: error ?? 'FORBIDDEN', message: message });
                }

                return {
                    success,
                    message,
                    response: data ?? '',
                    error: message ?? '',
                    raw: e,
                };
            } else {
                const errorMessage = {
                    success: false,
                    message:
                        "Couldn't connect to server, please try again.\r\n If the problem persits please contact support",
                    response: '',
                    error: 'SERVERDOWN',
                    raw: e,
                };

                return errorMessage;
            }
        }
    }
}
