import React, { Component } from 'react';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Accordion, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';
import './Notification.scss';
export default class Notification extends Component {
    render() {
        const { doc } = this.props;
        return (
            <Accordion className='user-notification-count-wrapper'>
                <Card>
                    <Accordion.Item eventKey={doc._id}>
                        <Accordion.Header>
                            <Card.Header>
                                <div className='card-title' onClick={!doc.readAt ? this.props.markAsRead : undefined}>
                                    {doc.subject}
                                </div>
                                <div className='card-controls float-right'>
                                    <span style={{ marginRight: '10px' }}>
                                        Send Date & Time:
                                        {DateTimeFormatHelper.format(doc.sentAt, 'MM/DD/YYYY, hh:mm A')}
                                    </span>
                                    {!doc.readAt && (
                                        <OverlayTrigger
                                            placement='left'
                                            overlay={
                                                <Tooltip id={`notification-${doc._id}__tooltip__courses`}>
                                                    Mark as read
                                                </Tooltip>
                                            }
                                        >
                                            <span className='btn btn--small btn--link' onClick={this.props.markAsRead}>
                                                <Fa icon={faEye} />
                                            </span>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </Card.Header>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Card.Body>
                                {doc.emailSentAt && <p className='color--light'>Email sent at {doc.emailSentAt}</p>}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: doc.message,
                                    }}
                                />
                            </Card.Body>
                        </Accordion.Body>
                    </Accordion.Item>
                </Card>
            </Accordion>
        );
    }
}
