import React from 'react';

interface QuestionContextType {
    isOptionSelected: (questionIndex: number, arg1: number) => boolean;
    getResultIcon: (questionIndex: number, arg1: any) => React.ReactNode;
    questionResults: any[];
    selectAnswer: (index: number, arg1: any, res?: string | number) => any;
    questions: any;
    heading?: string;
    subHeading?: string;
    content?: string;
    userAnswers: any[];
    bgColor?: string;
    fgColor?: string;
    result?: any;
    detailedQuestion?: any;
    multipleAnswers?: any;
    getSelectedOption: (val: number) => any;
    isSelectedCorrect: (questionIndex: number, optionIndex: number) => void;
    isOptionSelectedAnswer: (questionIndex: number, optionIndex: number) => any;
    retry?: () => void;
}

export const QuestionContext = React.createContext<QuestionContextType>({
    questions: [],
    heading: '',
    subHeading: '',
    content: '',
    userAnswers: [],
    selectAnswer: (index: number, arg1: null, res?: string | number) => {},
    questionResults: [],
    getSelectedOption: (val: number) => {},
    getResultIcon: (questionIndex: number, arg1: any) => null,
    isOptionSelected: (questionIndex: number, arg1: number) => false,
    isSelectedCorrect: (questionIndex: number, optionIndex: number) => {},
    isOptionSelectedAnswer: (questionIndex: number, optionIndex: number) => {},
});
