import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import CourseContext from '../../CourseContext';
import './PassProctoring.scss';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { Header } from '../Lessons/Cards/Components';
import { VimeoPlayer } from 'src/components/VideoPlayer';
import { EventBus } from 'src/helpers/new';
import { setState } from 'src/helpers/localStorage';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
    userPackageId?: string;
}

interface IProps {}
interface IState {}

type TProps = RouteComponentProps<IRouteProps>;
class PassProctoring extends Component<TProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    startExam = () => {
        this.props.navigate(`/courses/${this.props.params.courseId}/exam-summary`);
    };

    componentDidMount(): void {
        const { course } = this.context;
        EventBus.dispatch('display-chat-icon', { enable: false, course: this.context.course });
        if (course?.isProctorPass) {
            this.props.navigate(`/courses/${this.props.params.courseId}/exam-summary`);
        }
    }

    onProctoringExit = () => {
        const { course } = this.context;
        if (course?.lastChapterId && course?.lastLessonId) {
            EventBus.dispatch('display-chat-icon', { enable: true, course: this.context.course });
            this.props.navigate(
                `/courses/${this.props.params.courseId}/chapters/${course.lastChapterId}/lessons/${course.lastLessonId}`,
                {
                    state: {
                        isToBypassBiosigOnComponentMount: true,
                    },
                },
            );
        }
    };

    purchaseProctor = () => {
        const { course } = this.context;
        const purchaseData: any = {};
        if (course) {
            purchaseData[course.userPackageId] = {
                price: course?.proctorPass?.price,
                purchaseType: 'proctorPass',
                userPackageId: course.userPackageId,
                _id: course.courseId,
                userCourseId: course._id,
                extensionId: null,
            };

            const data = {
                ...Object.values(purchaseData).filter((data: any) => {
                    return true;
                }),
            };
            setState('cartPackageId', course.userPackageId);
            this.props.navigate(`/checkout`, { replace: true, state: data });
        }
    };

    render() {
        const { course } = this.context;
        const {
            proctorPass: { heading, subHeading, content, price, vimeoId },
        } = course;

        return (
            <div className='proctoring-password'>
                <span className='proctoring-password__header'>
                    <h3>Final Exam | {course.title}</h3>
                </span>
                <div className='content'>
                    <div className='content-container'>
                        <div className='content-container-wrapper'>
                            {(heading || subHeading) && <Header heading={heading} subHeading={subHeading} />}
                            {vimeoId && (
                                <div className='video-player'>
                                    <VimeoPlayer videoId={vimeoId} width={550} height={300} />
                                </div>
                            )}
                            <div className='proctoring-content'>
                                <div dangerouslySetInnerHTML={{ __html: content ?? '' }} />
                            </div>

                            <div className='purchase-proctor-button-wrapper'>
                                <div className='price-wrapper'>
                                    <h5 className='price'>${price}</h5>
                                </div>

                                <Button
                                    className={`bp ${course.isProctorPass ? 'btn--disabled' : ''}`}
                                    onClick={!course.isProctorPass ? this.purchaseProctor : undefined}
                                >
                                    Get proctor pass
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='proctoring-footer'>
                    <div className='buttons-container'>
                        <Button className='bp exit-button' onClick={this.onProctoringExit}>
                            Exit
                        </Button>
                        <ConditionalWrapper
                            condition={!course.isProctorPass}
                            wrapper={(children: any) => (
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`tooltip-proctor-pass`} className={`abs-tooltip`}>
                                            {`You need to buy proctor pass before proceeding further!`}
                                        </Tooltip>
                                    }
                                >
                                    {children}
                                </OverlayTrigger>
                            )}
                        >
                            <Button
                                className={`bp ${!course.isProctorPass ? 'button--disabled' : ''}`}
                                onClick={course.isProctorPass ? this.startExam : undefined}
                            >
                                Pre exam briefing
                            </Button>
                        </ConditionalWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PassProctoring);
