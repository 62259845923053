import React, { Component } from 'react';
import WithOverFlowTooltipTags from 'src/helpers/withOverFlowTooltipTags';
import './Header.slide.scss';

export interface IProps {
    heading?: string;
    subHeading?: string;
    specificClass?: string;
}

export default class SlideHeader extends Component<IProps> {
    render() {
        const { heading, subHeading, specificClass } = this.props;

        return heading || subHeading ? (
            <header className={`slide-header ${specificClass ? specificClass : ''}`}>
                {heading && (
                    <WithOverFlowTooltipTags
                        className={subHeading ? 'heading' : 'heading single'}
                        Tag={'h1'}
                        content={heading}
                    />
                )}
                {subHeading && (
                    <WithOverFlowTooltipTags
                        className={heading ? 'subheading' : 'subheading single'}
                        Tag={'h3'}
                        content={subHeading}
                    />
                )}
            </header>
        ) : null;
    }
}
