import React, { Component } from 'react';
import './PaymentFail.scss';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { Button } from 'react-bootstrap';

type IRouteProps = RouteComponentProps<{ [K in keyof any]?: string }>;
interface IProps extends IRouteProps {}

interface IState {
    previousPath: string;
    loginToken: string;
    userExists: boolean;
}
class PaymentFail extends Component<IProps, IState> {
    state: IState = {
        previousPath: '',
        loginToken: '',
        userExists: false,
    };

    onRedirect = () => {
        this.props.navigate('/checkout', { replace: true });
    };

    render() {
        return (
            <div
                className='payment_fail_header'
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/bg-yourcourseready.jpg)` }}
            >
                <i className='fa-solid fa-circle-xmark'></i>
                <h1>Your payment could not be processed, Please try again!</h1>
                <div className='back-to-checkout-button'>
                    <Button variant='primary' onClick={this.onRedirect} className={'mt-2'}>
                        Return to your cart
                    </Button>
                </div>
            </div>
        );
    }
}

export default withRouter(PaymentFail);
