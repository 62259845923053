import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './ExamStartModal.scss';

export default class ExamStartModal extends Component {
    state = {
        password: '',
    };

    handleChange = (e) => {
        this.setState({ password: e.target.value });
    };

    handleSubmit = (e) => {
        const { submitModal } = this.props;
        e.preventDefault();
        this.state.password && submitModal(this.state.password);
    };

    render() {
        const { closeModal } = this.props;

        return (
            <div className='modal show'>
                <Modal show={this.props.visible} onHide={closeModal} centered className='examStartModal'>
                    <Modal.Header closeButton>
                        <Modal.Title>Please provide exam password before start.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form'>
                            <form onSubmit={this.handleSubmit}>
                                <div className='password'>
                                    <label>Enter Password</label>
                                    <input type={'password'} onChange={this.handleChange} />
                                </div>
                                <div className='form__buttons btn-footer'>
                                    <button className='btn bp' type='submit'>
                                        Confirm
                                    </button>
                                    <button
                                        className='btn bd'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            closeModal();
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}
