import React, { useState, useRef, useEffect } from 'react';
import './DateSelection.scss';
import { DATEPICKERMONTHS } from 'src/helpers/GlobalConstants';
import { Utility } from 'src/helpers/new';

interface DatePickerProps {
    defaultYear?: number;
    defaultMonth?: number;
    defaultDay?: number;
    onDateChange?: (date: { year: number; month: number; day: number } | null | undefined) => void;
    yearRange?: { start: number; end: number };
    formatDateDisplay?: (year: number, month: number, day: number) => string;
    disablePrev?: boolean;
    disableNext?: boolean;
    isFormElement?: boolean;
    description?: string;
    id?: string;
    label?: string;
    error?: string;
    isClearable?: boolean;
}

const DatePickerSelection: React.FC<DatePickerProps> = ({
    defaultYear,
    defaultMonth,
    defaultDay,
    onDateChange,
    yearRange = { start: new Date().getFullYear() - 10, end: new Date().getFullYear() + 10 },
    formatDateDisplay,
    disablePrev = false,
    disableNext = false,
    isFormElement,
    description,
    id,
    label,
    error,
    isClearable,
}) => {
    const [selectedYear, setSelectedYear] = useState<number | null>(defaultYear || null);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(defaultMonth || null);
    const [selectedDay, setSelectedDay] = useState<number | null>(defaultDay || null);
    const [daysInMonth, setDaysInMonth] = useState<number[]>([]);
    const [showPicker, setShowPicker] = useState<boolean>(false);

    const pickerRef = useRef<HTMLDivElement>(null);
    const months = DATEPICKERMONTHS;

    useEffect(() => {
        if (selectedYear && selectedMonth) {
            const days = new Date(selectedYear, selectedMonth, 0).getDate();
            setDaysInMonth(Array.from({ length: days }, (_, i) => i + 1));
        } else {
            setDaysInMonth([]);
        }
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        if (onDateChange && selectedYear && selectedMonth && selectedDay) {
            onDateChange({ year: selectedYear, month: selectedMonth, day: selectedDay });
        }
    }, [selectedYear, selectedMonth, selectedDay, onDateChange]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
                setShowPicker(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, []);

    const handlePreviousDay = () => {
        if (selectedDay && selectedMonth && selectedYear) {
            let newDay = selectedDay - 1;
            let newMonth = selectedMonth;
            let newYear = selectedYear;

            if (newDay < 1) {
                newMonth -= 1;
                if (newMonth < 1) {
                    newMonth = 12;
                    newYear -= 1;
                }
                newDay = new Date(newYear, newMonth, 0).getDate();
            }

            setSelectedDay(newDay);
            setSelectedMonth(newMonth);
            setSelectedYear(newYear);
        }
    };

    const handleNextDay = () => {
        if (selectedDay && selectedMonth && selectedYear) {
            let newDay = selectedDay + 1;
            let newMonth = selectedMonth;
            let newYear = selectedYear;

            const daysInCurrentMonth = new Date(selectedYear, selectedMonth, 0).getDate();
            if (newDay > daysInCurrentMonth) {
                newDay = 1;
                newMonth += 1;
                if (newMonth > 12) {
                    newMonth = 1;
                    newYear += 1;
                }
            }

            setSelectedDay(newDay);
            setSelectedMonth(newMonth);
            setSelectedYear(newYear);
        }
    };

    const formatDisplay = () => {
        if (selectedYear && selectedMonth && selectedDay) {
            return formatDateDisplay
                ? formatDateDisplay(selectedYear, selectedMonth, selectedDay)
                : `${months[selectedMonth - 1]} ${selectedDay}, ${selectedYear}`;
        }
        return 'Select Date';
    };

    const clearDate = () => {
        if (onDateChange && selectedYear && selectedMonth && selectedDay) {
            setSelectedDay(null);
            setSelectedMonth(null);
            setSelectedYear(null);
            onDateChange(null);
        }
    };
    return (
        <>
            {label ? (
                <label htmlFor={id} className='label-date-selection'>
                    {isFormElement ? (
                        <label>
                            {label ?? ''}
                            {!!description && <small>{description}</small>}
                        </label>
                    ) : (
                        <strong>{label ?? ''}</strong>
                    )}
                </label>
            ) : (
                <></>
            )}

            <div className='date-selection-wrapper'>
                <div className='date-selection-container'>
                    <input
                        type='text'
                        value={formatDisplay()}
                        readOnly
                        onClick={() => setShowPicker(true)}
                        className='date-selection-input'
                    />
                    {isClearable ? (
                        <button
                            type='button'
                            onClick={clearDate}
                            className='clear-button'
                            aria-label='Clear date selection'
                        >
                            {`✖`}
                        </button>
                    ) : null}
                </div>

                {showPicker && (
                    <div ref={pickerRef} className='date-selection-picker'>
                        <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', alignItems: 'center' }}>
                            <button
                                onClick={handlePreviousDay}
                                disabled={!selectedYear || !selectedMonth || !selectedDay || disablePrev}
                            >
                                <i className='fa-solid fa-arrow-left' />
                            </button>

                            <select
                                value={selectedMonth ?? ''}
                                onChange={(e) => setSelectedMonth(Number(e.target.value))}
                                className='month-selection'
                            >
                                <option value='' disabled>
                                    {`${Utility.isMobileView() ? '' : 'Select'}  Month`}
                                </option>
                                {months.map((month, index) => (
                                    <option key={'' + index} value={index + 1}>
                                        {month}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedDay ?? ''}
                                onChange={(e) => setSelectedDay(Number(e.target.value))}
                                disabled={!selectedMonth || !selectedYear}
                                className='day-selection'
                            >
                                <option value='' disabled>
                                    {`${Utility.isMobileView() ? '' : 'Select'}  Day`}
                                </option>
                                {daysInMonth.map((day) => (
                                    <option key={day} value={day}>
                                        {day}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={selectedYear ?? ''}
                                onChange={(e) => setSelectedYear(Number(e.target.value))}
                                className='year-selection'
                            >
                                <option value='' disabled>
                                    {`${Utility.isMobileView() ? '' : 'Select'}  Year`}
                                </option>
                                {Array.from(
                                    { length: yearRange.end - yearRange.start + 1 },
                                    (_, i) => yearRange.start + i,
                                ).map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>

                            <button
                                onClick={handleNextDay}
                                disabled={!selectedYear || !selectedMonth || !selectedDay || disableNext}
                            >
                                <i className='fa-solid fa-arrow-right' />
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {error && <small className={'mt-2 form-builder__error-color'}>{error}</small>}
        </>
    );
};

export default DatePickerSelection;
