import React, { Component, ReactNode } from 'react';
import { Link } from 'src/components/Link';
import './CourseButton.scss';
import { RouteComponentProps } from 'src/hoc/withRouter';
import ERModal from './ERModal';
import { EventBus, Utility } from 'src/helpers/new';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { setState } from 'src/helpers/localStorage';
import { RootState } from 'src/store/reducers/rootReducer';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';
import DateTimeFormatHelper from 'src/helpers/DateTimePickerHelperWrapper';

interface User {
    suspended?: Date;
    maintenance?: {
        startTime: string;
        endTime: string;
        allOperationMsg: string;
        maintenanceScheduleMsg: string;
    };
}
interface IConnectProps {
    loggedIn: {
        user: User;
    };
}
interface IProps extends RouteComponentProps, IConnectProps {
    userCourse: IUserCourse;
    allCourses?: IUserCourse[];
    packageId?: string;
    userPackageSuspendedAt?: Date;
}

export interface IUserCourse {
    _id: string;
    userId: string;
    userPackageId: string;
    courseId: string;
    title: string;
    description: string;
    image: string;
    availability: number;
    status: string;
    conditionStatement: string;
    courseProgress: string;
    percentageProgress: number;
    inactiveTime: number;
    timerPause: boolean;
    totalLessonsTime: number;
    lessons: any;
    lastChapterId: string;
    lastLessonId: string;
    lastPageIndex: number;
    editingAdmins: any[];
    enrollmentForm: any;
    enrollmentUpdatedAt?: Date;
    createdAt: Date;
    updatedAt: Date;
    unlockedAt: Date;
    expiresAt: Date;
    preExamUpdatedAt?: Date;
    postExamUpdatedAt?: Date;
    suspendedAt?: Date;
    readyForExam?: boolean;
    extensionLimit: number;
    extensionLimitUsed: number;
    maximumCourseTimeFrame: number;
    offerConditions: IOfferConditions;
    offerOptions: IOfferOptions;
    certificateExpiresAt: Date;
    examAttempts: number;
    attemptLimit: number;
    repurchase: string;
    userPackage: any;
    passedAt: string;
    affiliateCode: {
        allowed: boolean;
        code: string;
    };
    isCourseAvailable: {
        available: boolean;
        cond: string;
        prompt?: boolean;
    };
    certificateData: {
        url: string;
        fileName: string;
    };
    certificateFileId: string;
    courseType: string;
    examReviewStatus?: string;
    preExamForm: any;
    postExamForm: any;
}

interface IOfferOptions {
    extension: [
        {
            _id: string;
            price: number;
            time: number;
        },
    ];
}
interface IOfferConditions {
    pass: string;
    fail: string;
    expiry: string;
    certificateExpiry: string;
}

interface IConfirmationPopup {
    displayText: string;
    confimCallback: any;
    cancelCallback: any;
}

interface RepurchaseState {
    [key: string]: {
        price: string;
        purchaseType: string;
        userPackageId: string;
        _id: string;
        userCourseId: string;
    };
}

interface IState {
    value: string;
    link: string | { pathname: string; state: any };
    initialText: string;
    certificationBtnValue: string;
    isCertificateExpired: boolean;
    isExtensionAvailable: boolean;
    showResaleOffers: boolean;
    mostPriority: offerConditionType | '';
    showDownloadAndViewBtns: boolean;
    isCourseFailed: boolean;
}

enum offerConfitionsEnum {
    PASS = 'pass',
    FAIL = 'fail',
    EXPIRY = 'expiry',
    CERTIFICATE_EXPIRY = 'certificateExpiry',
}

enum courseStatus {
    NEW = 'NEW',
    FAILED = 'FAILED',
    FINISHED = 'FINISHED',
    EXAM_PASSED = 'EXAM_PASSED',
    IN_EXAM = 'IN_EXAM',
}

type offerConditionType =
    | offerConfitionsEnum.PASS
    | offerConfitionsEnum.FAIL
    | offerConfitionsEnum.EXPIRY
    | offerConfitionsEnum.CERTIFICATE_EXPIRY;

class CourseButton extends Component<IProps, IState> {
    supportUrl = 'mailto:support@realestateu.com';

    state: IState = {
        value: '',
        link: '/',
        initialText: '',
        isCertificateExpired: false,
        isExtensionAvailable: false,
        certificationBtnValue: 'Certification Expired',
        showResaleOffers: false,
        mostPriority: '',
        showDownloadAndViewBtns: false,
        isCourseFailed: false,
    };

    async componentDidMount(): Promise<void> {
        const { userCourse } = this.props;
        if (this.getExtensionDetails()) {
            return;
        }

        const { value, link } = this.courseAvailability(userCourse);

        this.setState({ value, link });
    }

    getIsPostExamRedirection = (course: any) => {
        if (course?.postExamSetting?.isExamPassRequired === true) {
            return course.status === 'EXAM_PASSED' ? true : false;
        } else {
            return true;
        }
    };

    courseAvailability(userCourse: IUserCourse) {
        if (userCourse.isCourseAvailable.available) {
            let value = '',
                link = '/';

            if (!userCourse.enrollmentUpdatedAt) {
                link = `/courses/${userCourse._id}/enrollment`;
                value = 'Start';
            } else {
                if (userCourse.courseType !== 'optional') {
                    if (userCourse.status === 'IN_EXAM') {
                        link = `/courses/${userCourse._id}/exam-summary`;
                    } else if (
                        !userCourse.postExamUpdatedAt &&
                        userCourse.examAttempts === 1 &&
                        !userCourse.postExamForm &&
                        this.getIsPostExamRedirection(userCourse)
                    ) {
                        link = `/courses/${userCourse._id}/postexam`;
                    } else {
                        link = `/courses/${userCourse._id}`;
                    }
                } else {
                    link = `/courses/${userCourse._id}`;
                }

                value = 'Resume';
            }

            if (
                (userCourse.isCourseAvailable.cond === 'PROMPT_STARTED' ||
                    userCourse.isCourseAvailable.cond === 'PROMPT_COMPLETED' ||
                    userCourse.isCourseAvailable.cond === 'PROMPT_PASSED') &&
                userCourse.isCourseAvailable.prompt
            ) {
                link = '';
            }

            if (this.isSuspended(userCourse).isSuspended) {
                link = '';
            }

            return { value, link };
        } else {
            return { value: 'Start', link: '/' };
        }
    }

    get isCourseExpired() {
        return (
            this.props.userCourse.expiresAt &&
            DateTimeFormatHelper.diff(
                DateTimeFormatHelper.currentDate(),
                DateTimeFormatHelper.getDate(this.props.userCourse.expiresAt),

                'minute',
            ) < 1
        );
    }

    get isCourseFailed() {
        return (
            this.props.userCourse.status === 'FAILED' &&
            this.props.userCourse.attemptLimit === this.props.userCourse.examAttempts
        );
    }

    get certificateExpired() {
        return (
            this.props.userCourse.certificateExpiresAt &&
            DateTimeFormatHelper.diff(
                DateTimeFormatHelper.currentDate(),
                DateTimeFormatHelper.getDate(this.props.userCourse.certificateExpiresAt),
                'days',
            ) < 1
        );
    }

    getExtensionConfiguration = (type: offerConditionType) => {
        const { offerConditions } = this.props.userCourse;

        return offerConditions && (!offerConditions[type] || offerConditions[type] !== 'none')
            ? offerConditions[type]
            : '';
    };

    getExtensionDetails = () => {
        const { userCourse } = this.props;
        if (this.isCourseFailed && this.getExtensionConfiguration(offerConfitionsEnum.FAIL)) {
            this.setState({
                isExtensionAvailable: false,
                value: 'Repurchase',
                initialText: 'Repurchase',
                mostPriority: offerConfitionsEnum.FAIL,
                isCourseFailed: true,
                link: {
                    pathname: '/checkout',
                    state: { ...this.repurchaseRedirectCheckOut() },
                },
            });
            return true;
        } else if (this.isCourseExpired) {
            const updateStateData: Partial<IState> = {
                isExtensionAvailable: false,
                value: 'Expired',
                initialText: 'Expired',
                mostPriority: offerConfitionsEnum.EXPIRY,
            };

            const optionValue = this.getExtensionConfiguration(offerConfitionsEnum.EXPIRY);
            if (optionValue && optionValue === 'extension') {
                updateStateData.value = 'Extend';
                updateStateData.initialText = 'Extend';
                updateStateData.isExtensionAvailable = true;
            }

            if (
                (optionValue && optionValue === 'repurchase') ||
                (Utility.calculateRemainingAccessibleDays(userCourse) === 0 &&
                    this.props.userCourse.maximumCourseTimeFrame !== 0)
            ) {
                updateStateData.value = 'Repurchase';
                updateStateData.initialText = 'Repurchase';
                updateStateData.isExtensionAvailable = true;
            }

            this.setState((prevState) => ({
                ...prevState,
                ...updateStateData,
            }));
            return true;
        } else if (this.certificateExpired) {
            this.setState({
                isCertificateExpired: true,
                mostPriority: offerConfitionsEnum.CERTIFICATE_EXPIRY,
                value: 'Resume',
                initialText: 'Resume',
                link: `/courses/${this.props.userCourse._id}`,
            });
            return true;
        } else {
            return false;
        }
    };

    togglePreview = () => {
        this.setState({ showResaleOffers: !this.state.showResaleOffers });
    };

    getExamStatus = (status: string) => {
        if (status === courseStatus.FAILED) {
            return 'Failed';
        } else if (status === courseStatus.FINISHED) {
            return 'Complete';
        } else {
            return 'Incomplete';
        }
    };

    toggleShowBtns = () => {
        this.setState({ showDownloadAndViewBtns: !this.state.showDownloadAndViewBtns });
    };

    showConditionPrompt = () => {
        const { userCourse } = this.props;
        if (!userCourse.isCourseAvailable.prompt) return;

        const displayPrompt = { display: false, text: '' };
        if (userCourse.isCourseAvailable.cond === 'PROMPT_STARTED') {
            displayPrompt.display = true;
            displayPrompt.text = 'has been started';
        } else if (userCourse.isCourseAvailable.cond === 'PROMPT_COMPLETED') {
            displayPrompt.display = true;
            displayPrompt.text = 'is complete';
        } else if (userCourse.isCourseAvailable.cond === 'PROMPT_PASSED') {
            displayPrompt.display = true;
            displayPrompt.text = 'is passed';
        }

        if (displayPrompt.display) {
            this.confirmationPopup({
                displayText: displayPrompt.text,
                confimCallback: this.courseStart,
                cancelCallback: this.onCancelPrompt,
            });
        }
    };

    confirmationPopup = (content: IConfirmationPopup) => {
        EventBus.dispatch('confirmation-popup', {
            title: '',
            body: `Are you sure you'd like to begin this course before the previous course ${content.displayText}? 
            It's recommended to complete each course in their original order.`,
            confirm: {
                text: 'Start course anyway',
                action: content.confimCallback,
            },
            cancel: {
                text: 'Cancel',
                action: content.cancelCallback,
            },
        });
    };

    courseStart = () => {
        const { userCourse } = this.props;

        if (
            userCourse.isCourseAvailable.cond === 'PROMPT_STARTED' ||
            userCourse.isCourseAvailable.cond === 'PROMPT_COMPLETED' ||
            userCourse.isCourseAvailable.cond === 'PROMPT_PASSED'
        ) {
            this.props.navigate(`/courses/${userCourse._id}/enrollment`);
            this.setState({
                link: `/courses/${userCourse._id}/enrollment`,
            });
        }
    };

    onCancelPrompt = () => {};

    getButtonClz = () => {
        if (this.props.userCourse.isCourseAvailable.available) {
            if (
                (this.isCourseExpired &&
                    this.state.mostPriority &&
                    !this.getExtensionConfiguration(this.state.mostPriority)) ||
                this.isSuspended(this.props.userCourse).isSuspended
            ) {
                return 'expired-course-button';
            } else if (this.state.isExtensionAvailable || this.state.isCourseFailed) {
                return 'extension-course-button';
            }

            return 'course-button';
        } else {
            return 'expired-course-button';
        }
    };

    repurchaseRedirectCheckOut = () => {
        const repurchaseData: RepurchaseState = {};

        repurchaseData[this.props.userCourse.userPackageId] = {
            price: this.props.userCourse.repurchase,
            purchaseType: 'repurchase',
            userPackageId: this.props.userCourse.userPackageId,
            _id: this.props.userCourse.courseId,
            userCourseId: this.props.userCourse._id,
        };

        const data = {
            ...Object.values(repurchaseData).filter((data: any) => {
                return true;
            }),
        };

        return data;
    };

    linkButton = () => {
        const { value, link, isCertificateExpired, isCourseFailed } = this.state;
        return (
            <Link
                to={link}
                className={this.getButtonClz()}
                onClick={() => {
                    if (isCourseFailed) {
                        setState('cartPackageId', this.props.userCourse.userPackageId);
                    } else {
                        !isCertificateExpired &&
                            this.state.mostPriority &&
                            this.getExtensionConfiguration(this.state.mostPriority) &&
                            this.togglePreview();
                    }
                    this.showConditionPrompt();
                }}
            >
                {value}
            </Link>
        );
    };

    displayTooltip = (tooltipMessage: string) => {
        return (
            <OverlayTrigger
                placement='right'
                overlay={
                    <Tooltip id={`course-button-tooltip}`} className={`abs-tooltip`}>
                        {tooltipMessage}
                    </Tooltip>
                }
                trigger={['hover', 'focus']}
            >
                <div>{this.linkButton()}</div>
            </OverlayTrigger>
        );
    };

    isSuspended = (userCourse: IUserCourse) => {
        let isSuspended = false;
        let toolTipMsg = '';

        const { user } = this.props.loggedIn;

        if (user && user?.suspended) {
            isSuspended = true;
            toolTipMsg =
                'Your account has been suspended. If you believe this is an error, please contact support@realestateu.com.';

            return { isSuspended, toolTipMsg };
        }

        if (userCourse.suspendedAt || this.props.userPackageSuspendedAt) {
            isSuspended = true;
            toolTipMsg =
                'This course has been suspended. If you believe this is an error, please contact support@realestateu.com.';

            return { isSuspended, toolTipMsg };
        }

        return { isSuspended, toolTipMsg };
    };

    displayButton = (userCourse: IUserCourse) => {
        const { isSuspended, toolTipMsg } = this.isSuspended(userCourse);
        if (isSuspended) {
            return this.displayTooltip(toolTipMsg);
        } else if (
            this.isCourseExpired &&
            this.state.mostPriority &&
            !this.getExtensionConfiguration(this.state.mostPriority)
        ) {
            return this.displayTooltip('This course has expired and cannot be extended.');
        } else if (
            !userCourse.isCourseAvailable.available &&
            userCourse.isCourseAvailable.cond === 'PREVIOUS_STARTED'
        ) {
            return this.displayTooltip('You cannot start this course until the previous course has been started.');
        } else if (
            !userCourse.isCourseAvailable.available &&
            userCourse.isCourseAvailable.cond === 'PREVIOUS_COMPLETED'
        ) {
            return this.displayTooltip('You cannot start this course until the previous course has been completed.');
        } else if (!userCourse.isCourseAvailable.available && userCourse.isCourseAvailable.cond === 'PREVIOUS_PASSED') {
            return this.displayTooltip('You cannot start this course until the previous course has been passed.');
        }

        return this.linkButton();
    };

    render(): ReactNode {
        const { isExtensionAvailable, showResaleOffers } = this.state;
        const { userCourse, allCourses, packageId } = this.props;
        return (
            <>
                {this.displayButton(userCourse)}
                {isExtensionAvailable && (
                    <ERModal
                        showResaleOffers={showResaleOffers}
                        togglePreview={this.togglePreview}
                        allCourses={allCourses}
                        userCourse={userCourse}
                        getExamStatus={this.getExamStatus}
                        packageId={packageId}
                    />
                )}
            </>
        );
    }
}

export default withRouterAndRedux(
    CourseButton,
    (state: RootState) => {
        return { loggedIn: state.loggedIn };
    },
    null,
);
