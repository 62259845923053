import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import CourseContext from '../../../../CourseContext';
import humanizeDuration from 'humanize-duration';
import './Progress.scss';
import courseStatusIndicator from 'src/helpers/CourseStatusIndicator';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ExtensionRepurchase from 'src/pages/Checkout/ExtensionRepurchase';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { isNil } from 'lodash';
import { EventBus, Utility } from 'src/helpers/new';
import DateTimeFormatHelper from '../../../../../../helpers/DateTimePickerHelperWrapper';

type TProps = RouteComponentProps;
class Progress extends Component<TProps> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;
    state = {
        packageId: null,
    };

    get hoursOrLessonCompleted() {
        const { course } = this.context;
        let hoursProgress, totalHours;
        if (course.lessons?.totalRequiredTime === 0) {
            return `${course?.totalLessonCompleted ?? '1'} of ${course.lessons?.total} lessons completed`;
        }

        hoursProgress = humanizeDuration((this.context.course?.courseProgressTime ?? 0) * 60_000, {
            units: ['h', 'm'],
            round: true,
        });
        totalHours = humanizeDuration((this.context.course.lessons?.totalRequiredTime ?? 0) * 60_000, {
            units: ['h', 'm'],
            round: true,
        });
        hoursProgress = hoursProgress.replace(',', '');
        totalHours = totalHours.replace(',', '');
        return `${hoursProgress} of ${totalHours} completed`;
    }

    get daysLeft() {
        const noOfDaysRemaning = Math.floor(
            DateTimeFormatHelper.diff(
                DateTimeFormatHelper.currentDate(),
                DateTimeFormatHelper.getDate(this.context.course.expiresAt),
                'minutes',
            ) < 1
                ? 0
                : DateTimeFormatHelper.diff(
                      DateTimeFormatHelper.currentDate(),
                      DateTimeFormatHelper.getDate(this.context.course.expiresAt),
                      'minutes',
                  ) / 1440,
        );

        let returnString = `Expires in`;

        if (noOfDaysRemaning < 1) {
            returnString += ` less than 24 hours`;
        } else if (noOfDaysRemaning === 1) {
            returnString += ` ${noOfDaysRemaning} day`;
        } else {
            returnString += ` ${noOfDaysRemaning} days`;
        }

        return returnString;
    }

    getBtnText = () => {
        const extensionOptionValue = this.context.course?.offerConditions?.expiry;
        let btnText = '';

        if (extensionOptionValue && extensionOptionValue !== 'none') {
            if (extensionOptionValue === 'extension') {
                btnText = 'Extend';
            }

            if (
                extensionOptionValue === 'repurchase' ||
                (Utility.calculateRemainingAccessibleDays(this.context.course) === 0 &&
                    this.context.course.maximumCourseTimeFrame !== 0)
            ) {
                btnText = 'Repurchase';
            }
        }

        return btnText;
    };

    navigateToExam = () => {
        EventBus.dispatch('enter-pre-exam');
    };

    render() {
        const { percentageProgress, expiresAt, passedAssignments, totalAssignments } = this.context.course;
        const expiryDate = courseStatusIndicator.getCourseExpiryDate(expiresAt);
        const btnText = this.getBtnText();
        const button = (
            <Button className={`bp btn`} onClick={this.navigateToExam} disabled={passedAssignments < totalAssignments}>
                {`Go to final exam`}
            </Button>
        );
        return (
            <>
                <div className='navigation-progress'>
                    <CircularProgressbar
                        value={percentageProgress}
                        text={`${Math.floor(percentageProgress)}%`}
                        strokeWidth={14}
                    />
                    <div>
                        <span>{this.hoursOrLessonCompleted}</span>
                        {expiryDate && btnText ? (
                            <div className='action-course'>
                                <div className='course-status'>{expiryDate}</div>
                                <Button
                                    className={`extend-button ${btnText === 'Extend' ? 'extend-btn-space' : ''}`}
                                    onClick={() => {
                                        this.setState({
                                            packageId: this.context.course.userPackageId,
                                        });
                                    }}
                                >
                                    {btnText}
                                </Button>
                            </div>
                        ) : !isNil(expiresAt) ? (
                            <b>{this.daysLeft}</b>
                        ) : (
                            <></>
                        )}
                    </div>

                    {this.state.packageId && (
                        <ExtensionRepurchase
                            redirectUrl={this.props.location.pathname}
                            userPackageId={this.state.packageId}
                            onClose={() => {
                                this.setState({ packageId: '' });
                            }}
                            {...this.props}
                        />
                    )}
                </div>
                {percentageProgress === 100 &&
                    isNil(this.context.examStatus?.allLessonCompleted) &&
                    this.context.course.courseType !== 'optional' && (
                        <div className='exam-proctor-progress'>
                            {((passedAssignments === undefined && totalAssignments === undefined) ||
                                (passedAssignments !== undefined && totalAssignments === passedAssignments)) && (
                                <span>{`Course complete: Proceed to exam when ready.`}</span>
                            )}
                            {passedAssignments !== undefined &&
                                totalAssignments !== undefined &&
                                totalAssignments > 0 && (
                                    <span>{`${passedAssignments} of ${totalAssignments} passed graded assignments`}</span>
                                )}
                            {passedAssignments < totalAssignments ? (
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Tooltip id={`item-button-exam-button}`} className={`abs-tooltip`}>
                                            {
                                                'To access your final exam, you still have to answer correctly a few graded assignments. Please see at the end of each module which assignments you did not pass yet.'
                                            }{' '}
                                        </Tooltip>
                                    }
                                    trigger={['hover', 'focus']}
                                >
                                    <div>{button}</div>
                                </OverlayTrigger>
                            ) : (
                                button
                            )}
                        </div>
                    )}
            </>
        );
    }
}
export default withRouter(Progress);
