import React, { ReactElement } from 'react';
import './Switcher.scss';

interface SwitcherProps {
    current: number;
    total: number;
    onPrev: (index: number) => void;
    onNext: (index: number) => void;
}

const Switcher = ({ current, total, onPrev, onNext }: SwitcherProps): ReactElement => {
    const handlePrev = () => {
        const newIndex = Math.max(1, current - 1);
        onPrev(newIndex);
    };

    const handleNext = () => {
        const newIndex = Math.min(total, current + 1);
        onNext(newIndex);
    };

    return (
        <div className='switcher'>
            <button onClick={handlePrev} disabled={current === 1} className={current === 1 ? 'disabled' : ''}>
                <i className='fa fa-chevron-left' aria-hidden='true'></i>
            </button>
            <span>
                <b>{current}</b> of {total}
            </span>
            <button onClick={handleNext} disabled={current === total} className={current === total ? 'disabled' : ''}>
                <i className='fa fa-chevron-right' aria-hidden='true'></i>
            </button>
        </div>
    );
};

export default Switcher;
