import React, { Component } from 'react';
import './ResizableTextArea.scss';
import { isNil } from 'lodash';
import { commonTimeout } from 'src/helpers/commonTimeout';

interface IProps {
    value: string;
    name: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    classsName?: string;
    id?: string;
    placeholder?: string;
    onClick?: (e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    readOnly?: boolean;
    onKeyPress?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    isBottomScroll?: boolean;
}

interface IState {}
export default class ResizableTextArea extends Component<IProps, IState> {
    textAreaInputRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    state: IState = {};

    componentDidMount(): void {
        if (this.textAreaInputRef.current) {
            const textarea = this.textAreaInputRef.current;

            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 2 + 'px';

            if (parseInt(textarea.style.height.replace('px', '')) >= 126) {
                textarea.style.overflow = 'auto';
                textarea.style.height = Math.min(Math.max(textarea.scrollHeight, 42), 126) + 2 + 'px';
            } else {
                textarea.style.overflow = 'hidden';
            }
        }
    }

    componentWillUnmount(): void {
        this.resetScrollTimer();
    }

    resetScrollTimer = () => {
        if (!isNil(this.props.isBottomScroll) && this.props.isBottomScroll) {
            commonTimeout.clearTimer('resizeable-input-box');
        }
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.props.onChange(e);
    };

    isiPhoneSafari = () => {
        const userAgent = navigator.userAgent;
        return /iPhone/i.test(userAgent) && /Safari/i.test(userAgent);
    };
    handleTitleTextResize = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        const minHeight = 42;
        const maxHeight = 126;

        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 2 + 'px';

        if (parseInt(textarea.style.height.replace('px', '')) >= maxHeight) {
            textarea.style.overflow = 'auto';
            textarea.style.height = Math.min(Math.max(textarea.scrollHeight, minHeight), maxHeight) + 2 + 'px';
            this.resetScrollTimer();
        } else {
            textarea.style.overflow = 'hidden';
            if (!isNil(this.props.isBottomScroll) && this.props.isBottomScroll) {
                const container = document.getElementById('web-app-main-container-id');

                if (container) {
                    commonTimeout.startTimer(
                        'resizeable-input-box',
                        () => {
                            if (this.isiPhoneSafari()) {
                                const elementBottom = container?.getBoundingClientRect().bottom + window.scrollY;

                                const statusBarHeight = 50;
                                const scrollToPosition = elementBottom - window.innerHeight + statusBarHeight;

                                window.scrollTo({
                                    top: scrollToPosition,
                                    behavior: 'smooth',
                                });
                            } else {
                                container.scrollIntoView({ block: 'end', behavior: 'smooth' });
                            }
                        },
                        1000,
                    );
                }
            }
        }
    };

    render() {
        const { value, name, id, placeholder, readOnly } = this.props;
        return (
            <textarea
                ref={this.textAreaInputRef}
                className={`resizable-textarea-input ${this.props.classsName}`}
                name={name}
                value={value}
                id={id ?? name}
                onChange={this.handleInputChange}
                onInput={this.handleTitleTextResize}
                placeholder={placeholder ?? ''}
                readOnly={isNil(readOnly) ? false : readOnly}
                rows={1}
                onClick={this.props.onClick}
                onKeyPress={this.props.onKeyPress}
            />
        );
    }
}
