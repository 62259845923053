import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import LessonContext from '../../../LessonContext';
import NextButton from './NextButton';
import './Feedback.scss';
import { first } from 'lodash';
import PreviousButton from './PreviousButton';
import { StartExam } from '../../../StartExam';
import RetryButton from './RetryButton';
import { EventBus } from 'src/helpers/new';

interface IRouteProps {}
interface IProps {
    activeCardIndex: number;
    course: any;
    setActiveCardIndex: (e: number) => any;
    cardType: string;
}
export type TProps = IProps & RouteComponentProps<IRouteProps>;

class Feedback extends Component<TProps> {
    static contextType = LessonContext;
    context!: React.ContextType<typeof LessonContext>;
    get isFinalExamActive(): boolean {
        return this.context.cards.length - 1 === this.props.activeCardIndex;
    }

    get isExamAvailable(): boolean {
        const { course, nextLesson } = this.context;
        return (
            !(nextLesson && nextLesson._id) &&
            course?.courseType != 'optional' &&
            this.isFinalExamActive &&
            !course.isAdminPreview
        );
    }

    isNextCardAvailable = () => {
        let flag = true;
        switch (this.props.cardType) {
            case 'SINGLE_QUESTION':
                const { isAttempted, correct, nextCardAvailable } = this.context.feedback;
                const nextCardAvailableCond = nextCardAvailable || 'AFTER_CORRECT';

                if (nextCardAvailableCond) {
                    if (nextCardAvailableCond === 'AFTER_ANSWER' && !isAttempted) {
                        flag = false;
                    }

                    if (nextCardAvailableCond === 'AFTER_CORRECT' && !correct) {
                        flag = false;
                    }
                }
                break;
            case 'ESSAY':
                const currentCard = this.context.cards.at(this.props.activeCardIndex);
                const userLessonCardData = currentCard?.userLessonCardData;
                const latestAttempt: any = first(userLessonCardData?.essay?.essayAttempts);
                if (
                    (latestAttempt && latestAttempt?.attemptStatus === 'REJECTED') ||
                    latestAttempt?.attemptStatus === 'PENDING'
                ) {
                    flag = false;
                }
                break;
            default:
                flag = true;
                break;
        }
        return flag;
    };

    nextLessonMiddleware = () => {
        const cardsLength = this.context.cards.length,
            { activeCardIndex, course } = this.props,
            { nextLesson } = this.context;

        if (!nextLesson?.unlocked && !nextLesson?._id && this.isFinalExamActive) {
            if (!course.preExamUpdatedAt) {
                this.props.navigate(`/courses/${course._id}/preexam`);

                return false;
            }

            if (course.passedAt) {
                this.props.navigate(`/courses/${course._id}/postexam`);

                return false;
            }
        }

        if (activeCardIndex !== cardsLength - 1) {
            this.props.setActiveCardIndex(activeCardIndex + 1);

            return false;
        }

        return true;
    };

    handleToggleClick = () => {
        if (this.context.setFeedback) {
            this.context.setFeedback({
                expanded: !this.context.feedback.expanded,
            });
        }
    };

    previousLessonMiddleWare = () => {
        const { activeCardIndex } = this.props;
        const cardsLength = this.context.cards.length;

        if (activeCardIndex !== 0) {
            this.props.setActiveCardIndex(activeCardIndex - 1);

            return false;
        }
        this.props.setActiveCardIndex(cardsLength - 1);

        return true;
    };

    retryClick = () => {
        if (!this.isNextCardAvailable()) {
            EventBus.dispatch('base-question-card-retry');
            EventBus.dispatch('base-essay-attempt-retry');
            return false;
        }
    };

    showNextNavigationButton = () => {
        if (!this.isNextCardAvailable()) {
            return <RetryButton onClick={this.retryClick} />;
        } else if (this.isExamAvailable) {
            return (
                <StartExam
                    lessonContext={this.context}
                    isFinalExamActive={this.isFinalExamActive}
                    examBtnCls={'lesson-button'}
                />
            );
        } else {
            return (
                <NextButton
                    middleware={this.nextLessonMiddleware}
                    activeCardIndex={this.props.activeCardIndex}
                    lessonContext={this.context}
                >
                    Next
                </NextButton>
            );
        }
    };

    render() {
        if (!this.context.feedback) return <></>;

        const { show, expanded, correct, content, title, attemptStatus } = this.context.feedback;

        if (!show) return <></>;

        return (
            <div
                className={`card-container__feedback${correct ? ' card-container__feedback--correct' : ''}${
                    show ? ' card-container__feedback--visible' : ''
                }${expanded && content ? ' card-container__feedback--expanded' : ''} ${
                    attemptStatus ? ` card-container__feedback--${attemptStatus}` : ''
                }`}
            >
                <div className='card-container__feedback__header'>
                    {this.props.cardType === 'ESSAY' && this.isNextCardAvailable() && (
                        <PreviousButton
                            lessonContext={this.context}
                            activeCardIndex={this.props.activeCardIndex}
                            visible={this.props.activeCardIndex !== 0}
                            middleware={this.previousLessonMiddleWare}
                        >
                            Previous
                        </PreviousButton>
                    )}
                    {!!title && (
                        <h1 className={this.props.cardType === 'ESSAY' ? 'mobile-view-center' : ''}>{title}</h1>
                    )}
                    {this.props.cardType === 'ESSAY' && !title && !expanded ? (
                        <h1>{content && content?.length > 20 ? content?.substring(0, 20) + '...' : content}</h1>
                    ) : (
                        ' '
                    )}

                    {this.showNextNavigationButton()}
                </div>
                {content && (
                    <div className='card-container__feedback__content'>
                        <div className='card-container__feedback__toggle' onClick={this.handleToggleClick}>
                            <Fa icon={!expanded ? faChevronUp : faChevronDown} />
                        </div>
                        <main dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                )}
            </div>
        );
    }
}
export default withRouter(Feedback);
