import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

type DateString = Dayjs | Date | string | null;

export default class DateTimeFormatHelper {
    static readonly formats = {
        default: 'MM/DD/YYYY, hh:mm:ss A',
        day: 'MM/DD/YYYY',
    };

    static getUserTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    static getYear(dateString?: DateString) {
        return dayjs(dateString).year();
    }

    static getMonth(dateString?: DateString) {
        return dayjs(dateString).month();
    }

    static getDay(dateString?: DateString) {
        return dayjs(dateString).day();
    }

    static format(dateString?: DateString, format?: string) {
        return dayjs(dateString).format(format ?? this.formats.default);
    }

    static utcToUserTimeZone(dateString: DateString, format?: string) {
        return dayjs(dateString).tz(this.getUserTimeZone()).format(format);
    }

    static userTimeZoneToUTC(dateString: DateString, format?: string) {
        return dayjs(dateString, format).tz(this.getUserTimeZone()).utc().format();
    }

    static getUtc(dateString: DateString, format: string) {
        return dayjs(dateString).utc().format(format);
    }

    static getUtcDate(dateString: DateString) {
        return dayjs.utc(dateString);
    }

    static currentDate() {
        return dayjs();
    }

    static getCurrentTimeStamp() {
        return dayjs().valueOf();
    }

    static isBefore(currentDate: Dayjs | string, compareDate: Dayjs | string) {
        return dayjs(currentDate).isBefore(compareDate);
    }

    static diff(currentDate: DateString, compareDate: Dayjs, unit?: any) {
        return compareDate?.diff(currentDate, unit);
    }

    static add(date: Dayjs, duration: number, unit: any) {
        return date.add(duration, unit);
    }

    static subtract(date: Dayjs, duration: number, unit: any) {
        return dayjs(date).subtract(duration, unit);
    }

    static isSameOrAfter(dateString: DateString, compareDate: Dayjs | string) {
        return dayjs(dateString).isSameOrAfter(dayjs(compareDate));
    }

    static isSameOrBefore(dateString: DateString, compareDate: Dayjs | string) {
        return dayjs(dateString).isSameOrBefore(dayjs(compareDate));
    }

    static isAfter(dateString: DateString, compareDate: Dayjs | string) {
        return dayjs(dateString).isAfter(dayjs(compareDate));
    }

    static getDate(dateString?: DateString) {
        return dayjs(dateString);
    }

    static startOfDay(unit: OpUnitType, dateString?: Dayjs | string | null) {
        return dayjs(dateString).startOf(unit);
    }

    static getDateForTimeZones(dateString: Dayjs | string | null, zone: string, format?: string) {
        return dayjs(dateString)
            .tz(zone)
            .format(format ?? this.formats.default);
    }

    static getStartOfDateForTimeZone(dateString: Dayjs | string | null, zone: string, format?: string) {
        return dayjs(dateString)
            .tz(zone)
            .startOf('day')
            .set('second', 1)
            .format(format ?? this.formats.default);
    }

    static getUserLocalTimezone(dateString: DateString, format?: string) {
        return dayjs(dateString, format)
            .tz(this.getUserTimeZone())
            .format(format ?? this.formats.default);
    }

    static isBetween(
        date: Dayjs,
        startDate: DateString,
        endDate: Dayjs | Date | string | null,
        unit: OpUnitType | null,
        limit: '()' | '[]' | '[)' | '(]',
    ) {
        return date.isBetween(startDate, endDate, unit, limit);
    }

    static setCurrentTimeToDate(date: DateString) {
        const currentHour = dayjs().hour();
        const currentMinute = dayjs().minute();
        const currentSecond = dayjs().second();

        return dayjs(date).set('hour', currentHour).set('minute', currentMinute).set('second', currentSecond).toDate();
    }

    static setCurrentTimeToFormat(date: Dayjs | Date | string | null, format?: string) {
        const currentHour = dayjs().hour();
        const currentMinute = dayjs().minute();
        const currentSecond = dayjs().second();

        return dayjs(date)
            .set('hour', currentHour)
            .set('minute', currentMinute)
            .set('second', currentSecond)
            .format(format);
    }

    static getMinMaxTime(
        selectedDate: Date | string | null,
        minDate: Date | string | null = null,
        isPreviousTimeDisabled = true,
    ) {
        let minTime = undefined;
        let maxTime = undefined;

        if (!minDate && !isPreviousTimeDisabled) {
            return { minTime, maxTime };
        }

        let actualMinimamDate = new Date();
        let newSelectedDate = new Date();
        if (selectedDate) {
            newSelectedDate = new Date(selectedDate);
        }

        if (minDate) {
            if (new Date(minDate) > new Date(actualMinimamDate)) {
                actualMinimamDate = new Date(minDate);
                if (!selectedDate) {
                    newSelectedDate = new Date(minDate);
                }
            }
        }

        const isSelectedDateIsCurrentOrPreviousDate = DateTimeFormatHelper.isSameOrAfter(
            DateTimeFormatHelper.format(actualMinimamDate, 'MM/DD/YYYY'),
            DateTimeFormatHelper.format(newSelectedDate, 'MM/DD/YYYY'),
        );

        if (isSelectedDateIsCurrentOrPreviousDate) {
            const isSelectedDateIsPreviousDate = DateTimeFormatHelper.isAfter(
                DateTimeFormatHelper.format(actualMinimamDate, 'MM/DD/YYYY'),
                DateTimeFormatHelper.format(newSelectedDate, 'MM/DD/YYYY'),
            );

            if (isSelectedDateIsPreviousDate) {
                minTime = new Date();
                minTime.setHours(23, 59, 59, 999);
                maxTime = new Date(newSelectedDate);
                maxTime.setHours(23, 59, 59, 999);
            } else {
                minTime = new Date(actualMinimamDate);
                minTime.setMinutes(minTime.getMinutes() + 1);
                maxTime = new Date(newSelectedDate);
                maxTime.setHours(23, 59, 59, 999);
            }
        }

        return { minTime, maxTime };
    }
}
