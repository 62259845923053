import React, { Component } from 'react';
import { QuestionContext } from '../../QuestionContext';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import './BooleanAnswer.scss';
import { SlideHeader } from 'src/pages/Course/Stages/Lessons/Cards/Components';
import { isNil } from 'lodash';

interface IRouteProps {}
export type TProps = RouteComponentProps<IRouteProps>;

class BooleanAnswer extends Component<TProps> {
    static readonly contextType = QuestionContext;
    context!: React.ContextType<typeof QuestionContext>;

    getSelectedOptionIndex = (questionIndex: number) => {
        if (this.context.userAnswers[questionIndex]) {
            return this.context.userAnswers[questionIndex][0];
        }
    };

    render() {
        const { heading, subHeading } = this.context;
        return (
            <>
                <SlideHeader heading={heading} subHeading={subHeading} />
                <div className='boolean-answers'>
                    {this.context.content && (
                        <div className='content' dangerouslySetInnerHTML={{ __html: this.context.content }} />
                    )}
                    <div className='questions'>
                        {this.context.questions.map((question: any, questionIndex: number) => {
                            return (
                                <div key={questionIndex} className='question'>
                                    <div
                                        className={`result ${
                                            this.context?.questionResults?.length
                                                ? this.context?.questionResults?.[questionIndex]?.isUserAnswerCorrect
                                                    ? 'correct'
                                                    : 'incorrect'
                                                : ''
                                        }`}
                                    >
                                        {this.context.getResultIcon(
                                            questionIndex,
                                            this.getSelectedOptionIndex(questionIndex),
                                        )}
                                    </div>
                                    <div
                                        className={`content ${
                                            this.context?.questionResults?.length
                                                ? !isNil(
                                                      this.context?.questionResults?.[questionIndex]
                                                          ?.isUserAnswerCorrect,
                                                  )
                                                    ? 'adjust-content-padding'
                                                    : ''
                                                : ''
                                        }`}
                                    >
                                        <span>{question.question}</span>
                                        <div className='options'>
                                            <button
                                                onClick={(e) => {
                                                    this.context.selectAnswer(questionIndex, e, 0);
                                                }}
                                                className={`option ${
                                                    this.context.isOptionSelected(questionIndex, 0) ? 'selected' : ''
                                                }`}
                                            >
                                                {question.options[0].text}
                                            </button>
                                            <button
                                                onClick={(e) => {
                                                    this.context.selectAnswer(questionIndex, e, 1);
                                                }}
                                                className={`option ${
                                                    this.context.isOptionSelected(questionIndex, 1) ? 'selected' : ''
                                                }`}
                                            >
                                                {question.options[1].text}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(BooleanAnswer);
