import React, { Component } from 'react';
import { ICustomCalendarItemComponent } from 'src/components/_New/Navigation/Calendar';
import './ItemComponents.scss';

export default class LockedCard extends Component<ICustomCalendarItemComponent> {
    render() {
        const { originalIndex, rule, item } = this.props;

        return (
            <div className='slide-calendar-item locked-card' onClick={() => rule.onClick(item, originalIndex)}>
                <h3>{originalIndex + 1}</h3>
                <div className='status' />
            </div>
        );
    }
}
