import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EventBus, Utility } from 'src/helpers/new';
import ProfilePopOver from './Menus/ProfilePopOver';
import ProfilePhotoUpdateModal from './Menus/ProfilePhotoUpdateModal';
import NameAvatar from './Menus/NameAvatar';
import ChatPopOver from './Chat/ChatPopOver';
import ChatPurchasePopOver from './Chat/ChatPurchasePopOver';
import '../UserBar/Menus/ProfilePopOver.scss';
import { isNil } from 'lodash';
import { overlayPopupClose } from 'src/helpers/UserBarHelper';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import withRouterAndRedux from 'src/hoc/withRouterAndRedux';

class UserBar extends Component {
    eventSource = null;

    state = {
        active: false,
        inExam: false,
        isShowProfileMenu: false,
        isShowUpload: false,
        isShowChatMenu: false,
        isChatEnable: false,
        course: {},
        menuOverlayId: '',
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.overlayContainerClicked);
        EventBus.on('check-in-exam', this.checkInExam);
        EventBus.on('display-chat-icon', this.handleChatEnabledChange);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.overlayContainerClicked);
        EventBus.remove('check-in-exam', this.checkInExam);
        EventBus.remove('display-chat-icon', this.handleChatEnabledChange);
    }

    checkInExam = (event) => {
        this.setState({ inExam: event.detail.inExam });
    };

    confirmToggle = (isShowProfileMenu, event) => {
        const { inExam } = this.state;
        if (inExam) {
            EventBus.dispatch('confirmation-popup', {
                title: 'Leave Exam',
                body: [
                    <span>
                        Are you sure you want to exit the exam? Please note, the exam timer will continue running in the
                        background!
                        <br />
                    </span>,
                    <span>Your answers will be auto submitted once timer run out !!!</span>,
                ],
                confirm: {
                    text: 'Leave',
                    action: () => {
                        this.setState(
                            {
                                isShowProfileMenu,
                                isShowChatMenu: false,
                                menuOverlayId: event.target.id,
                            },
                            () => {
                                if (this.state.inExam) {
                                    EventBus.dispatch('end-exam-proctoring');
                                    this.props.navigate('/', { replace: true });
                                }
                            },
                        );
                    },
                },
                cancel: {
                    text: 'Cancel',
                    action: () => {},
                },
            });
        } else {
            this.setState({ isShowProfileMenu, isShowChatMenu: false, menuOverlayId: event.target.id });
        }
    };

    toggleMenu = () => {
        if (window.innerWidth > 900) {
            return;
        }

        this.setState({
            active: !this.state.active,
        });
    };

    onSetShowUploadPhoto = (val) => {
        this.setState({ isShowUpload: val });
    };

    getUsersPermissions = (name) => Utility.getUsersPermissions(name);

    toggleChatMenu = (e) => {
        if (e) {
            e.stopPropagation();
        }

        this.props.setCourseChat({
            ...this.props.courseChat,
            unreadCount: 0,
        });
    };

    handleChatEnabledChange = (event) => {
        const { enable, course } = event.detail;
        this.setState({
            isChatEnable: enable,
            course: course,
        });
    };

    showChatButton = (course) => {
        const { chatAccess, chatSettings } = course;
        if (chatAccess) {
            return {
                type: 'chat',
                display: true,
            };
        } else if (
            (chatAccess === false && chatSettings?.enable === false) ||
            (chatAccess === false && course?.isBlocked)
        ) {
            return {
                type: '',
                display: false,
            };
        } else if (chatAccess === false && chatSettings?.enable === true && chatSettings?.price > 0) {
            return {
                type: 'pre-purchase-chat',
                display: true,
            };
        }

        return {
            type: '',
            display: false,
        };
    };

    overlayContainerClicked = (event) => {
        const { course, menuOverlayId, isShowChatMenu, isShowProfileMenu } = this.state;
        const displayChatButton = this.showChatButton(course);
        const selectedMenu = event.target.id ? event.target.id : menuOverlayId;

        if (
            selectedMenu === 'user-bar-chat-button-icon' ||
            selectedMenu === 'user-bar-chat-button' ||
            selectedMenu === 'chat-notification-badge'
        ) {
            overlayPopupClose(isShowChatMenu, this.setChatMenu, {
                outerContainerId:
                    displayChatButton?.type === 'chat' ? '#user-bar-chat-menu' : '#user-chat-purchase-menu',
                innerClickedContainerId: '#user-bar-chat-button',
                event,
            });
        } else if (selectedMenu === 'user-bar-profile-button-photo' || selectedMenu === 'user-bar-profile-button') {
            overlayPopupClose(isShowProfileMenu, this.setProfileMenu, {
                outerContainerId: '#user-bar-profile-menu',
                innerClickedContainerId: '#user-bar-profile-button',
                event,
            });
        }
    };

    setProfileMenu = (isShowProfileMenu, event) => {
        this.confirmToggle(isShowProfileMenu, event);
    };

    setChatMenu = (isShowChatMenu, event) => {
        this.setState({ isShowChatMenu, isShowProfileMenu: false, menuOverlayId: event?.target?.id ?? '' });
    };

    handleChatCloseButton = () => {
        this.setState({ isShowChatMenu: false });
    };

    displayChatPopup = (displayChatButton) => {
        const { isShowChatMenu, course } = this.state;
        if (displayChatButton?.type === 'chat') {
            return (
                <ChatPopOver
                    isShowChatMenu={isShowChatMenu}
                    toggleChatMenu={this.toggleChatMenu}
                    course={course}
                    handleChatCloseButton={this.handleChatCloseButton}
                />
            );
        } else {
            return (
                <ChatPurchasePopOver isShowChatMenu={isShowChatMenu} setChatMenu={this.setChatMenu} course={course} />
            );
        }
    };

    preventParentEvent = (e) => {
        if (e) {
            e.stopPropagation();
        }
    };

    render() {
        if (!this.props.loggedIn.token) {
            return null;
        }
        const { active, isChatEnable, course } = this.state;
        const { notificationCount, isUsingLoginToken, isShowMaintenancePage, courseChat } = this.props;
        const displayChatButton = this.showChatButton(course);

        return (
            <>
                <nav id='user-bar' className={active ? ' active' : ''} onClick={this.toggleMenu}>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='user-bar__tooltip__support' className='abs-tooltip'>
                                Support
                            </Tooltip>
                        }
                    >
                        <Link
                            className={`user-bar__action ${
                                (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                            }`}
                            onClick={(e) => {
                                window.location = 'mailto:support@realestateu.com';
                                e.preventDefault();
                            }}
                            to='/'
                        >
                            <Fa icon={faEnvelope} style={{ height: '23px', width: '23px', paddingRight: '3px' }} />
                        </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='user-bar__tooltip__courses' className='abs-tooltip'>
                                Courses
                            </Tooltip>
                        }
                    >
                        <Link
                            className={`user-bar__action ${
                                (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                            }`}
                            to='/'
                        >
                            <img src={process.env.PUBLIC_URL + '/faGraduationCap.svg'} alt='Gradution cap icon' />
                        </Link>
                    </OverlayTrigger>
                    {displayChatButton.display && isChatEnable && (
                        <OverlayTrigger
                            show={this.state.isShowChatMenu}
                            placement='bottom'
                            trigger='click'
                            overlay={this.displayChatPopup(displayChatButton)}
                            className='abs-tooltip'
                        >
                            <div
                                className={`user-bar__action ${
                                    (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                                }`}
                                onClick={this.toggleChatMenu}
                                id='user-bar-chat-button'
                            >
                                <img
                                    src={process.env.PUBLIC_URL + '/chat.svg'}
                                    alt='Chat icon'
                                    className='bar-icon'
                                    id='user-bar-chat-button-icon'
                                />
                                {!isNil(courseChat?.unreadCount) && courseChat?.unreadCount !== 0 ? (
                                    <div className='user-bar__action__notification' id='chat-notification-badge'>
                                        {courseChat.unreadCount > 90 ? '+' : courseChat.unreadCount}
                                    </div>
                                ) : (
                                    displayChatButton.type === 'pre-purchase-chat' && (
                                        <div className='user-bar__action__notification' id='chat-notification-badge'>
                                            {'+'}
                                        </div>
                                    )
                                )}
                            </div>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        placement='bottom'
                        overlay={
                            <Tooltip id='user-bar__tooltip__notifications' className='abs-tooltip'>
                                {notificationCount === 0
                                    ? 'Notifications'
                                    : notificationCount +
                                      (notificationCount === 1 ? ' unread notification' : ' unread notifications')}
                            </Tooltip>
                        }
                    >
                        <Link
                            className={`user-bar__action ${
                                (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                            }`}
                            to='/notifications'
                        >
                            <img src={process.env.PUBLIC_URL + '/faBell.svg'} alt='Bell icon' className='bar-icon' />
                            {notificationCount !== 0 && (
                                <div className='user-bar__action__notification'>
                                    {notificationCount > 90 ? '+' : notificationCount}
                                </div>
                            )}
                        </Link>
                    </OverlayTrigger>
                    {this.getUsersPermissions('grading/chat')?.includes('enableGrading') &&
                        !!this.props.loggedIn.user.adminLevel && (
                            <OverlayTrigger
                                placement='bottom'
                                overlay={
                                    <Tooltip id='user-bar__tooltip__courses' className='abs-tooltip'>
                                        Grading
                                    </Tooltip>
                                }
                            >
                                <Link
                                    className={`user-bar__action ${
                                        (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                                    }`}
                                    to='/admin/grading'
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + '/highlighter-solid.svg'}
                                        alt='Highlighter icon'
                                        className='bar-icon'
                                    />
                                </Link>
                            </OverlayTrigger>
                        )}
                    {!!this.props.loggedIn.user.adminLevel && (
                        <OverlayTrigger
                            placement='bottom'
                            overlay={
                                <Tooltip id='user-bar__tooltip__notifications' className='abs-tooltip'>
                                    Admin Panel
                                </Tooltip>
                            }
                        >
                            <Link
                                className={`user-bar__action ${
                                    (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                                }`}
                                to='/admin/dashboard'
                            >
                                <img
                                    src={process.env.PUBLIC_URL + '/faSetting.svg'}
                                    alt='Admin icon'
                                    className='bar-icon'
                                />
                            </Link>
                        </OverlayTrigger>
                    )}
                    <OverlayTrigger
                        show={this.state.isShowProfileMenu}
                        placement='bottom'
                        trigger='click'
                        overlay={
                            <ProfilePopOver
                                toggleProfileMenu={this.state.isShowProfileMenu}
                                onSetShowUploadPhoto={this.onSetShowUploadPhoto}
                            />
                        }
                    >
                        <div
                            className={`user-bar__profile__action ${
                                (isUsingLoginToken || isShowMaintenancePage) && 'user-bar__action_disabled'
                            }`}
                            id='user-bar-profile-button'
                            onClick={this.preventParentEvent}
                        >
                            {this.props.loggedIn?.user?.file?.url[0] ? (
                                <img
                                    id='user-bar-profile-button-photo'
                                    className='profile-photo'
                                    src={this.props.loggedIn?.user?.file?.url[0]}
                                    alt='profile photo'
                                />
                            ) : (
                                <NameAvatar
                                    id='user-bar-profile-button-photo'
                                    firstName={this.props.loggedIn?.user?.firstName}
                                    lastName={this.props.loggedIn?.user?.lastName}
                                />
                            )}
                        </div>
                    </OverlayTrigger>
                </nav>
                <ProfilePhotoUpdateModal
                    onSetShowUploadPhoto={this.onSetShowUploadPhoto}
                    isShowUpload={this.state.isShowUpload}
                />
            </>
        );
    }
}

export default withRouterAndRedux(
    UserBar,
    (state) => {
        return { loggedIn: state.loggedIn, courseChat: state.courseChat };
    },
    {
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        setCourseChat: (payload) => ({
            type: 'SET_CONVERSATION_DATA',
            payload,
        }),
    },
);
